import React, { useCallback } from 'react';

interface Props {
  children: React.ReactNode;
  width?: string;
  onClick?: (e: any) => any;
  style?: React.CSSProperties;
}

const Component: React.FC<Props> = (props) => {
  const prepEllipse = (node: HTMLElement) => {
      const parent = node.parentNode as HTMLElement;
      const child =
        (parent.querySelector('.constrainedChild') as HTMLElement) /* Legacy. */ ||
        (node.childNodes[0] as HTMLElement);
      const txtToEllipse =
        (parent.querySelector('.ellipseMe') as HTMLElement) ||
        (parent.querySelector('.constrainedEllipse') as HTMLElement) /* Legacy. */ ||
        child;

      if (child !== null && txtToEllipse !== null) {
        ellipse(
          // Use the smaller width.
          node.offsetWidth > parent.offsetWidth ? parent : node,
          child,
          txtToEllipse
        );
      }
    },
    measuredParent = useCallback(
      (node: HTMLElement | null) => {
        if (node !== null) {
          window.addEventListener('resize', () => {
            prepEllipse(node);
          });
          prepEllipse(node);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.children]
    );

  return (
    <div
      ref={measuredParent}
      onClick={props.onClick}
      style={{
        wordBreak: 'keep-all',
        overflowWrap: 'normal',
        ...(props.width && { width: props.width }),
        ...props.style
      }}
    >
      {props.children}
    </div>
  );
};

const ellipse = (parentNode: HTMLElement, childNode: HTMLElement, txtNode: HTMLElement) => {
  const childWidth = childNode.offsetWidth;
  const containerWidth = parentNode.offsetWidth;
  const txtWidth = txtNode.offsetWidth;
  const targetWidth = childWidth > txtWidth ? childWidth : txtWidth;

  if (targetWidth > containerWidth) {
    const str = txtNode.textContent || '';
    const txtChars = str.length;
    const avgLetterSize = txtWidth / txtChars;
    const canFit = (containerWidth - (targetWidth - txtWidth)) / avgLetterSize;
    const delEachSide = (txtChars - canFit + 5) / 2;
    const endLeft = Math.floor(txtChars / 2 - delEachSide);
    const startRight = Math.ceil(txtChars / 2 + delEachSide);

    txtNode.setAttribute('data-original', str);
    txtNode.textContent = str.substr(0, endLeft) + '...' + str.substr(startRight);
  }
};

export default Component;
