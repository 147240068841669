import dayjs from 'dayjs';
import { WhitelistWithNetworks } from '../features/whitelist/whitelistTypes';

export function shortenString(str = '', maxLength = 20) {
  const ellipsis = '...'; // Ellipsis character(s)

  // Check if the string needs truncation
  if (str.length > maxLength) {
    const midIndex = Math.floor(str.length / 2); // Find the middle index

    // Calculate the lengths of the first and last parts
    const firstPartLength = Math.ceil((maxLength - ellipsis.length) / 2);
    const lastPartLength = Math.floor((maxLength - ellipsis.length) / 2);

    // Extract the first and last parts of the string
    const firstPart = str.slice(0, midIndex - firstPartLength);
    const lastPart = str.slice(midIndex + lastPartLength);

    // Return the resulting string with ellipses in the middle
    return firstPart + ellipsis + lastPart;
  }

  // If the string doesn't need truncation, return it as is
  return str;
}

export function disabledDateAfter(current: any) {
  const today = dayjs().startOf('day');
  return current ? current.isAfter(today) : undefined;
}

export function disabledDateBefore(current: any) {
  const today = dayjs().startOf('day');
  return current ? current.isBefore(today) : undefined;
}

export function decimalFormatter(value = '', prefix = '') {
  return `${prefix}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function dollarFormatter(amount?: number): string {
  if (amount !== undefined) {
    const decimalPoints = 2;
    if (typeof amount === 'string') {
      amount = parseInt(amount);
    }
    const fixedAmount = (amount || 0).toFixed(decimalPoints);
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimalPoints
    });
    return formatter.format(parseFloat(fixedAmount));
  } else {
    return '';
  }
}

export function dollarParser(value = '') {
  if (value) {
    return value.replace(/\$\s?|(,*)/g, '');
  } else {
    return value;
  }
}

export function formatUrl(url: string) {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  } else {
    return `https://${url}`;
  }
}

export function plural(n: number, word: string, plural?: string): string {
  return n === 1 ? `${n} ${word}` : `${n} ${plural ?? word + 's'}`;
}

export function addressCount(whitelist: WhitelistWithNetworks) {
  if (whitelist.sortedAddresses.length > 0) {
    if (whitelist.networks.length === 1) {
      return (
        plural(whitelist.sortedAddresses.length, 'address', 'addresses') +
        ` on ${whitelist.networks[0].name}`
      );
    } else {
      const networkDetails = [];

      for (const network of whitelist.networks) {
        networkDetails.push(`${network.count} on ${network.name}`);
      }

      return (
        plural(whitelist.sortedAddresses.length, 'address', 'addresses') +
        (networkDetails.length > 0 ? ': ' + networkDetails.join(', ') : '')
      );
    }
  } else {
    return '0 addresses';
  }
}
