import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { GroupAccount, GroupBalanceHistory, GroupsState } from './groupsTypes';
import { groupsApi } from './groupsService';

export const initialState: GroupsState = {
  selectedGroup: undefined
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setSelectedGroup: (state, action: PayloadAction<GroupAccount | undefined>) => {
      state.selectedGroup = action.payload;
    }
  },
  extraReducers: (builder) => {
    // auto-select first group
    builder.addMatcher(
      groupsApi.endpoints.getGroups.matchFulfilled,
      (state, action: PayloadAction<GroupAccount[]>) => {
        const groups = action.payload || [];
        if (!state.selectedGroup) {
          state.selectedGroup = groups[0];
        } else {
          state.selectedGroup = groups.find((c) => {
            return c.id === state.selectedGroup?.id;
          });
        }
      }
    );
    builder.addMatcher(
      groupsApi.endpoints.getAccountBalances.matchFulfilled,
      (state, action: PayloadAction<GroupBalanceHistory[]>) => {
        if (state.selectedGroup) {
          state.selectedGroup.lastKnownBalance =
            action.payload[action.payload.length - 1]?.usd_value || 0;
        }
      }
    );
  }
});

export const { setSelectedGroup } = groupsSlice.actions;

export const selectSelectedGroup = (state: RootState) => state.groups.selectedGroup;

export default groupsSlice.reducer;
