import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Input, Modal, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ArmorOwnerListItem from '../ArmorOwnerListItem';
import {
  StyledItalic,
  addressValidationConfig,
  antdFormConfig
} from '../../../../../styles/shared';
import { ArmorOwner } from '../../../../../features/order/orderTypes';
import { useAddress, useConnectionStatus } from '@thirdweb-dev/react';

interface AddOwnersFormProps {
  data: ArmorOwner[];
  addOwner: (values: ArmorOwner) => void;
  editOwner: (oldOwner: ArmorOwner, newOwner: ArmorOwner) => void;
  deleteOwner: (values: ArmorOwner) => void;
  tradingKeyAddress: string;
}

interface ContextObject {
  editing: boolean;
  previousValue?: ArmorOwner;
}

const AddOwnersForm: React.FC<AddOwnersFormProps> = ({
  data = [],
  addOwner,
  deleteOwner,
  editOwner
}) => {
  const address = useAddress();
  const [modalForm] = Form.useForm();
  const [context, setContext] = useState<ContextObject | null>(null);
  const connectionStatus = useConnectionStatus();
  const buttonText = context?.editing ? 'Edit Owner' : 'Add Owner';

  useEffect(() => {
    modalForm.setFieldValue('address', address);
  }, [modalForm, address]);

  function onCancel() {
    setContext(null);
    setTimeout(modalForm.resetFields, 500);
  }

  function editLocalOwner(values: ArmorOwner) {
    setContext({
      editing: true,
      previousValue: values
    });
    modalForm.setFieldsValue(values);
  }

  function handleFinish() {
    modalForm
      .validateFields()
      .then(async (values) => {
        const existingOwnerForAddress = data.find(
          (datum) => datum.address?.toLowerCase() === values?.address?.toLowerCase()
        );
        if (!context?.editing && existingOwnerForAddress) {
          modalForm.setFields([
            {
              name: 'address',
              errors: ['An owner already exists for this address.']
            }
          ]);
        } else {
          if (context?.editing && context?.previousValue) {
            if (context?.previousValue?.address !== values?.address && existingOwnerForAddress) {
              modalForm.setFields([
                {
                  name: 'address',
                  errors: ['An owner already exists for this address.']
                }
              ]);
            } else {
              editOwner(context?.previousValue, values);
              setContext(null);
              setTimeout(modalForm.resetFields, 500);
            }
          } else {
            addOwner(values);
            setContext(null);
            setTimeout(modalForm.resetFields, 500);
          }
        }
      })
      .catch(console.warn);
  }

  if (connectionStatus !== 'connected') {
    return <Alert type="warning" banner message="Please connect a wallet before enabling." />;
  } else {
    return (
      <>
        {data.map((datum) => {
          return (
            <ArmorOwnerListItem
              key={`owner_${datum.address}`}
              data={datum}
              editOwner={editLocalOwner}
              deleteOwner={deleteOwner}
            />
          );
        })}
        {/* {tradingKeyAddress === address?.toLowerCase?.() ? (
          <Popconfirm
            title="Authorize this address as owner?"
            description={
              <div style={{ maxWidth: 300 }}>
                Generally it is ill-advised to have your armor agent&apos;s trading key address
                equal to that of the safe owner&apos;s address. You can think of the safe owners as
                the withdrawal keys. In an ideal world, these would be separate functions. Please
                consider changing your connect wallet to authorize a different user.
              </div>
            }
            okText="Continue"
            cancelText="Cancel"
            onConfirm={() => {
              setContext({
                editing: false
              });
            }}
          >
            <Button type="dashed" icon={<PlusOutlined />} style={{ width: '100%' }}>
              Add Owner
            </Button>
          </Popconfirm>
        ) : ( */}
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          style={{ width: '100%' }}
          onClick={() => {
            setContext({
              editing: false
            });
          }}
        >
          Add Owner
        </Button>
        {/* )} */}
        <Modal
          open={!!context}
          title={buttonText}
          okText={context?.editing ? 'Save' : 'Add'}
          cancelText="Cancel"
          onCancel={onCancel}
          onOk={handleFinish}
          okButtonProps={{
            htmlType: 'submit'
          }}
        >
          <Form
            form={modalForm}
            initialValues={{ address }}
            layout="vertical"
            name="Owners"
            requiredMark={antdFormConfig.requiredMark}
            scrollToFirstError
            onFinish={handleFinish}
            size={antdFormConfig.size}
          >
            {/* <Form.Item name="label" label="Label" labelCol={antdFormConfig.labelCol}>
              <Input placeholder="Add owner label..." />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              labelCol={antdFormConfig.labelCol}
              rules={[
                {
                  type: 'email',
                  message: 'Please provide a valid email for this owner.'
                }
              ]}
            >
              <Input placeholder="Add an owner email..." />
            </Form.Item> */}
            <StyledItalic style={{ marginTop: 0 }}>
              The pre-filled address below is from the currently connected wallet. You can edit this
              to be any owner address you&apos;d like, or switch to another wallet.
            </StyledItalic>
            <Form.Item
              name="address"
              label="Address"
              labelCol={antdFormConfig.labelCol}
              style={{ marginBottom: 35 }}
              rules={[
                {
                  required: true,
                  message: 'Please provide an address for this owner.'
                },
                addressValidationConfig
              ]}
            >
              <Input style={{ fontFamily: 'monospace' }} placeholder="Add address..." />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
};

export default AddOwnersForm;
