import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import { H1, H2, Text } from '../styles/shared';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { selectGroupsData, useLazyGetGroupsQuery } from '../features/groups/groupsService';
import { Button, Col, Row, Select, List, ConfigProvider, Spin } from 'antd';
import { dollarFormatter } from '../utils/data';
import { selectSelectedGroup, setSelectedGroup } from '../features/groups/groupsSlice';
import { selectUser } from '../features/auth/authSlice';
import styled from 'styled-components';
import EmptyState from '../components/EmptyState/EmptyState';
import { LoadingOutlined } from '@ant-design/icons';
import EulithChart from '../components/EulithChart';
import CreatePortfolioForm from '../components/CreatePortfolioForm';

function getUserGreeting(username: string): string {
  const currentHour = new Date().getHours();
  if (currentHour < 12) {
    return 'Good morning, ' + username + '!';
  } else if (currentHour < 18) {
    return 'Good afternoon, ' + username + '!';
  } else {
    return 'Good evening, ' + username + '!';
  }
}

const GroupsHomePage: React.FC = () => {
  const [getGroups, { isLoading: loadingGroups }] = useLazyGetGroupsQuery();
  const user = useAppSelector(selectUser);
  const groups = useAppSelector(selectGroupsData);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const selectedGroup = useAppSelector(selectSelectedGroup);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user?.sub) {
      getGroups(user.sub).unwrap().catch(console.warn);
    }
  }, [getGroups, user?.sub]);

  const openAddModal = useCallback(() => {
    setAddModalOpen(true);
  }, []);

  const closeAddModal = useCallback(() => {
    setAddModalOpen(false);
  }, []);

  const groupOptions = useMemo(() => {
    return groups.map((group) => {
      return {
        label: group.name,
        value: group.id
      };
    });
  }, [groups]);

  const handleSelectedGroupChange = useCallback(
    (groupId: string) => {
      const group = groups.find((group) => {
        return group.id === groupId;
      });
      if (group) {
        dispatch(setSelectedGroup(group));
      } else {
        console.warn('Could not find group for id', groupId);
      }
    },
    [dispatch, groups]
  );

  return (
    <PageContainer>
      <EulithHelmetConsumer>Portfolios</EulithHelmetConsumer>
      {groupOptions?.length ? (
        <div>
          <H1 style={{ marginBottom: 50, marginTop: 20, textAlign: 'center' }}>
            {user?.sub ? getUserGreeting(user.sub) : 'Welcome'}
          </H1>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={12}>
              <Row align="middle">
                <H2>Portfolios</H2>
                <Select
                  style={{ width: 200, marginLeft: 15 }}
                  options={groupOptions}
                  value={selectedGroup?.id}
                  popupMatchSelectWidth={false}
                  onChange={handleSelectedGroupChange}
                  placeholder="Select account..."
                />
              </Row>
            </Col>
            <Col span={12}>
              <Button onClick={openAddModal} type="primary" size="large" style={{ float: 'right' }}>
                Create Portfolio
              </Button>
            </Col>
          </Row>
        </div>
      ) : loadingGroups ? (
        <Row align="middle" justify="center" style={{ height: '100%' }}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Text style={{ paddingBottom: 20, textAlign: 'center' }}>Fetching accounts...</Text>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>
        </Row>
      ) : (
        <Row align="middle" justify="center" style={{ height: '100%' }}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <H2 style={{ paddingBottom: 20, textAlign: 'center' }}>
              Create your first Eulith account.
            </H2>
            <Text style={{ paddingBottom: 20, textAlign: 'center' }}>
              You have not created any accounts yet.
            </Text>
            <Button onClick={openAddModal} type="primary" size="large">
              Create Portfolio
            </Button>
          </div>
        </Row>
      )}
      <Row justify="space-between" gutter={[16, 16]} style={{ marginTop: 30 }}>
        {selectedGroup ? (
          <Col span={24}>
            <Row justify="space-between" style={{ marginBottom: 20 }}>
              <H2>
                <span style={{ fontWeight: '300' }}>Balance:</span>{' '}
                {dollarFormatter(selectedGroup.lastKnownBalance)}
              </H2>
              {selectedGroup?.denomination ? (
                <Button type="default" style={{ marginRight: 20, pointerEvents: 'none' }}>
                  {selectedGroup.denomination}
                </Button>
              ) : null}
            </Row>
            <div style={{ marginRight: 20 }}>
              <EulithChart />
            </div>
          </Col>
        ) : null}
      </Row>
      {selectedGroup ? (
        <ConfigProvider
          renderEmpty={() => <EmptyState description="No history for this account" />}
        >
          <List
            bordered
            style={{ marginTop: 20 }}
            header={<H2>History</H2>}
            dataSource={selectedGroup.history}
            renderItem={(item) => {
              return (
                <List.Item
                  actions={
                    item.timestamp
                      ? [
                          <div key="timestamp">
                            {new Date(item.timestamp).toDateString()}{' '}
                            {new Date(item.timestamp).toLocaleTimeString()}
                          </div>
                        ]
                      : undefined
                  }
                >
                  <List.Item.Meta title={item.label} description={item.value} />
                </List.Item>
              );
            }}
            pagination={{
              pageSize: 5
            }}
          />
        </ConfigProvider>
      ) : null}
      <CreatePortfolioForm open={!!addModalOpen} close={closeAddModal} />
    </PageContainer>
  );
};

const PageContainer = styled.div`
  height: 100%;
`;

export default GroupsHomePage;
