import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CopyOutlined,
  EditOutlined,
  EllipsisOutlined,
  LoadingOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  message,
  notification
} from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import styled from 'styled-components';
import EulithCard from '../components/EulithCard';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import * as Eulith from 'eulith-web3js';
import eulithSingleton from '../features/eulith/EulithSingleton';
import { selectAddress, selectWalletInitialized } from '../features/wallet/walletSlice';
import { DecoratedContract } from '../features/eulith/eulithTypes';
import { selectEulithClientInitialized } from '../features/eulith/eulithSlice';
import MonoSpaceSpan from '../components/MonoSpaceSans';
import {
  selectPendingWhitelistActivationsData,
  selectWhitelistsData,
  useLazyGetPendingActivationsQuery,
  useAppendWhitelistDraftMutation,
  useDeleteWhitelistAddressMutation,
  useLazyGetWhitelistsQuery,
  useDeleteWhitelistDraftMutation,
  usePublishWhitelistMutation,
  useSubmitWhitelistSignatureMutation,
  useStartWhitelistActivationMutation,
  useLazyGetOptInWhitelistsQuery,
  selectOptInWhitelistsData,
  useEditWhitelistMutation,
  useArchiveWhitelistMutation,
  useUnarchiveWhitelistMutation
} from '../features/whitelist/whitelistService';
import { H2, H3, StyledItalic, Text, antdFormConfig, colors } from '../styles/shared';
import { calculateChainIconUrl, chainIdToNetworkLabel, getAllChains } from '../utils/networks';
import LeftColumnContainer from '../components/LeftColumnContainer';
import { selectIsDarkMode } from '../features/settings/settingsSlice';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Link, Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import StepsTitleInputContainer from '../components/StepsTitleInputContainer';
import StepsContainer from '../components/StepsContainer';
import FundsSectionContainer from '../components/FundsSectionContainer';
import EmptyState from '../components/EmptyState/EmptyState';
import EulithTable from '../components/EulithTable';
import { ColumnsType } from 'antd/es/table';
import MiddleEllipses from '../components/MiddleEllipses';
import CreateWhitelistDraft from '../components/CreateWhitelistDraft';
import { selectContractsData, useLazyGetContractsQuery } from '../features/eulith/eulithService';
import {
  selectSelectedWhitelistContract,
  setSelectedWhitelistContract
} from '../features/order/orderSlice';
import { ConnectWallet, useChainId, useConnectionStatus } from '@thirdweb-dev/react';
import BugsnagManager from '../BugsnagManager';
import AddWhitelistAddressForm from '../components/CreateWhitelistDraft/components/AddWhitelistAddressForm';
import EulithChart from '../components/EulithChart';
import { GroupBalanceHistory } from '../features/groups/groupsTypes';

type CombinedWhitelist = Eulith.WhitelistsV2.Whitelist | Eulith.WhitelistsV2.OptInList;

function isWhitelist(obj: CombinedWhitelist): obj is Eulith.WhitelistsV2.Whitelist {
  if (obj) {
    return 'isDraft' in obj;
  } else {
    return false;
  }
}

function generateChartData(): GroupBalanceHistory[] {
  const data: GroupBalanceHistory[] = [];
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();

  const lastHourIndex = currentMinute < 30 ? currentHour - 1 : currentHour;
  const lastHour = (lastHourIndex + 24) % 24;

  let currentPrice = 67800; // Starting price
  const maxFluctuation = 600;

  for (let i = 0; i <= lastHour; i++) {
    // Simulate smooth price changes
    const fluctuation = Math.random() * maxFluctuation;
    currentPrice += fluctuation;
    data.push({
      timestamp: Date.now() - i * 3600000,
      usd_value: Math.round(currentPrice)
    });
  }

  return data;
}

const mockData = generateChartData();

const ROUTES = {
  OVERVIEW: '/',
  EDIT: '/:id/edit',
  CREATE: '/create'
};

const sections = [
  {
    title: 'Select Whitelist',
    path: ROUTES.OVERVIEW
  },
  {
    title: 'Edit Whitelist',
    path: ROUTES.EDIT
  },
  {
    title: 'Create New Whitelist',
    path: ROUTES.CREATE
  }
];

const TradingWhitelistPage: React.FC = () => {
  const whitelistDraftRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const [startActivationModalVisible, setStartActivationModalVisible] = useState(false);
  const [editSelectedWhitelistModalVisible, setEditSelectedWhitelistModalVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Eulith.WhitelistsV2.AddressOnChain | null>(
    null
  );
  const [getContracts] = useLazyGetContractsQuery();
  const [getWhitelists, { isLoading: getWhitelistsFetching }] = useLazyGetWhitelistsQuery();
  const [getOptInWhitelists, { isLoading: getOptInWhitelistsFetching }] =
    useLazyGetOptInWhitelistsQuery();
  const [getPendingActivations, { isLoading: getPendingActivationsFetching }] =
    useLazyGetPendingActivationsQuery();
  const [appendWhitelistDraft] = useAppendWhitelistDraftMutation();
  const [deleteWhitelistAddress] = useDeleteWhitelistAddressMutation();
  const isLoading =
    getWhitelistsFetching || getPendingActivationsFetching || getOptInWhitelistsFetching;
  const contracts = useAppSelector(selectContractsData);
  const eulithClientInitialized = useAppSelector(selectEulithClientInitialized);
  const walletIsInitialized = useAppSelector(selectWalletInitialized);
  const connectionStatus = useConnectionStatus();
  const selectedWhitelistContract = useAppSelector(selectSelectedWhitelistContract);
  const isDarkMode = useAppSelector(selectIsDarkMode);
  const userWhitelists = useAppSelector(selectWhitelistsData);
  const optInWhitelists = useAppSelector(selectOptInWhitelistsData);
  const pendingActivations = useAppSelector(selectPendingWhitelistActivationsData);
  const [showArchived, setShowArchived] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteDraft] = useDeleteWhitelistDraftMutation();
  const [publishWhitelist] = usePublishWhitelistMutation();
  const address = useAppSelector(selectAddress);
  const [startWhitelistActivation] = useStartWhitelistActivationMutation();
  const [submitWhitelistSignature] = useSubmitWhitelistSignatureMutation();
  const [archiveWhitelist] = useArchiveWhitelistMutation();
  const [unarchiveWhitelist] = useUnarchiveWhitelistMutation();
  const chainId = useChainId();
  const [editWhitelist] = useEditWhitelistMutation();
  const [editSelectedWhitelistForm] = Form.useForm();
  const [editSelectedAddressForm] = Form.useForm();

  const whitelists: CombinedWhitelist[] = useMemo(() => {
    if (showArchived) {
      return [...userWhitelists, ...optInWhitelists];
    } else {
      const filteredUserWhitelists = userWhitelists.filter((whitelist) => !whitelist.isArchived);
      return [...filteredUserWhitelists, ...optInWhitelists];
    }
  }, [userWhitelists, optInWhitelists, showArchived]);

  const contractsWhitelistIdMap = useMemo(() => {
    const map: { [key: string]: DecoratedContract } = {};
    contracts.forEach((contract) => {
      map[contract.whitelistId] = contract;
    });
    return map;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts, pendingActivations]);

  const pendingActivationsIdMap = useMemo(() => {
    const map: { [key: string]: Eulith.WhitelistsV2.PendingActivation } = {};
    pendingActivations.forEach((pendingActivation) => {
      map[pendingActivation.whitelist.listId] = pendingActivation;
    });
    return map;
  }, [pendingActivations]);

  const match = useMatch(`/armor/whitelists${ROUTES.EDIT}`);
  const selectedWhitelist: CombinedWhitelist | null = useMemo(() => {
    return (
      whitelists.find((whitelist) => {
        return `${whitelist.listId}` === `${match?.params?.id}`;
      }) || null
    );
  }, [whitelists, match?.params?.id]);

  const selectedWhitelistStatus = selectedWhitelist
    ? returnStatusForWhitelist(selectedWhitelist)
    : '';

  const associatedContracts = useMemo(() => {
    return selectedWhitelist
      ? contracts.filter((c) => c.whitelistId === selectedWhitelist.listId)
      : [];
  }, [contracts, selectedWhitelist]);

  const isFixed = match?.params?.id === 'eulith';

  function navigateToOverview() {
    navigate('/armor/whitelists');
  }

  function navigateToCreateWhitelist(fromWhitelist?: CombinedWhitelist) {
    navigate(`/armor/whitelists${ROUTES.CREATE}`, {
      replace: true,
      state: {
        fromWhitelist
      }
    });
  }

  function navigateToEditWhitelist(id: number | 'eulith') {
    navigate(`/armor/whitelists/${id}/edit`);
  }

  function toggleArchived() {
    navigate('/armor/whitelists');
    setShowArchived((prev) => !prev);
  }

  function toggleStartActivationModal() {
    setStartActivationModalVisible((prevValue) => !prevValue);
  }

  function toggleEditSelectedWhitelistMetadataModal() {
    if (selectedWhitelist && isWhitelist(selectedWhitelist)) {
      if (!editSelectedWhitelistModalVisible) {
        editSelectedWhitelistForm.setFieldsValue({
          displayName: selectedWhitelist?.displayName || '',
          description: selectedWhitelist?.description || ''
        });
      }
      setEditSelectedWhitelistModalVisible((prevValue) => !prevValue);
    }
  }

  function closeEditSelectedAddressModal() {
    if (selectedWhitelist && isWhitelist(selectedWhitelist)) {
      setSelectedAddress(null);
    }
  }

  function handleSelectedContractChange(contractAddress: string) {
    const contract = contracts?.find?.((contract) => contract.contractAddress === contractAddress);
    if (contract) {
      dispatch(setSelectedWhitelistContract(contract));
    } else {
      console.warn('Could not find contract with address', contractAddress);
    }
  }

  useEffect(() => {
    if (eulithClientInitialized) {
      getWhitelists();
      getOptInWhitelists();
      getPendingActivations();
    }
  }, [getWhitelists, getPendingActivations, getOptInWhitelists, eulithClientInitialized]);

  async function handleEditSelectedAddressNotes() {
    if (selectedWhitelist && isWhitelist(selectedWhitelist) && selectedAddress) {
      const values = editSelectedAddressForm.getFieldsValue();
      try {
        await editWhitelist({
          displayName: selectedWhitelist?.displayName || '',
          description: selectedWhitelist?.description || '',
          listId: selectedWhitelist?.listId,
          notesUpdates: [
            {
              ...selectedAddress,
              notes: values.notes || ''
            }
          ]
        }).unwrap();
        message.success('Address successfully updated!');
        closeEditSelectedAddressModal();
        setTimeout(editSelectedAddressForm.resetFields, 500);
      } catch (error) {
        message.error('Unable to edit whitelist notes. Please try again later.');
      }
    }
  }

  async function handleEditSelectedWhitelist() {
    if (selectedWhitelist && isWhitelist(selectedWhitelist)) {
      const values = editSelectedWhitelistForm.getFieldsValue();
      try {
        await editWhitelist({
          displayName: values.displayName,
          description: values.description,
          listId: selectedWhitelist?.listId
        }).unwrap();
        message.success('Whitelist successfully updated!');
        toggleEditSelectedWhitelistMetadataModal();
      } catch (error) {
        message.error('Unable to edit whitelist. Please try again later.');
      }
    }
  }

  async function handleRemoveAddress(addressOnChain: Eulith.WhitelistsV2.AddressOnChain) {
    if (!eulithSingleton.provider) {
      message.warning(
        'Unable to append address because Eulith services have not been initialized.'
      );
    } else if (!selectedWhitelist) {
      message.warning('You must select a whitelist before deleting addresses for this whitelist.');
    } else if (!chainId) {
      message.warning(
        'Please connect a wallet before deleting addresses lists for this whitelist.'
      );
    } else {
      try {
        await deleteWhitelistAddress({
          addresses: [addressOnChain],
          listId: selectedWhitelist.listId
        }).unwrap();
        message.success('Whitelist draft successfully updated!');
      } catch (error: any) {
        notification.open({
          message: 'An error occurred when deleting addresses list from this whitelist.',
          description: error?.message || '',
          placement: 'top',
          type: 'error',
          duration: null
        });
      }
    }
  }

  async function handleArchiveWhitelist(record: Eulith.WhitelistsV2.Whitelist, archiving: boolean) {
    if (!eulithSingleton.provider) {
      message.warning(
        'Unable to change archive status because Eulith services have not been initialized.'
      );
    } else if (!record) {
      message.warning('You must select a whitelist before changing archive status.');
    } else if (!chainId) {
      message.warning('Please connect a wallet before changing archive status for this whitelist.');
    } else {
      if (archiving) {
        try {
          await archiveWhitelist({
            listId: record.listId
          }).unwrap();
          navigate('/armor/whitelists');
          message.success('Whitelist successfully archived!');
        } catch (error: any) {
          notification.open({
            message: 'An error occurred when archiving whitelist.',
            description: error?.message || '',
            placement: 'top',
            type: 'error',
            duration: null
          });
        }
      } else {
        try {
          await unarchiveWhitelist({
            listId: record.listId
          }).unwrap();
          message.success('Whitelist successfully unarchived!');
        } catch (error: any) {
          notification.open({
            message: 'An error occurred when unarchiving whitelist.',
            description: error?.message || '',
            placement: 'top',
            type: 'error',
            duration: null
          });
        }
      }
    }
  }

  function handleDuplicateWhitelist(record: CombinedWhitelist) {
    navigateToCreateWhitelist(record);
  }

  function returnStatusForWhitelist(whitelist: CombinedWhitelist) {
    if (isWhitelist(whitelist)) {
      if (whitelist.isDraft) {
        return 'draft';
      } else if (pendingActivationsIdMap[whitelist.listId]) {
        // activation process has been initiated but not completed
        return 'awaiting_owner_signatures';
      } else {
        if (contractsWhitelistIdMap[whitelist.listId]) {
          return 'immutable';
        } else {
          return 'pending_activation';
        }
      }
    } else {
      return 'opt_in';
    }
  }

  function returnTagForWhitelist(whitelist: CombinedWhitelist, showAssignedAccountTag = true) {
    const status = returnStatusForWhitelist(whitelist);
    const assignedContracts = contracts.filter((c) => c.whitelistId === whitelist.listId);
    switch (status) {
      case 'immutable':
        return (
          <Tag color="green">
            {assignedContracts?.length
              ? `Assigned to ${assignedContracts.length} Account${
                  assignedContracts.length === 1 ? '' : 's'
                }`
              : 'Live'}
          </Tag>
        );
      case 'awaiting_owner_signatures':
        return assignedContracts?.length && showAssignedAccountTag ? (
          <Space direction="vertical">
            <div>
              <Tag color="green">
                {`Assigned to ${assignedContracts.length} Account${
                  assignedContracts.length === 1 ? '' : 's'
                }`}
              </Tag>
            </div>
            <div>
              <Tag color="orange">Awaiting Signatures</Tag>
            </div>
          </Space>
        ) : (
          <Tag color="orange">Awaiting Signatures</Tag>
        );
      case 'pending_activation':
        return assignedContracts?.length && showAssignedAccountTag ? (
          <Space direction="vertical">
            <div>
              <Tag color="green">
                {`Assigned to ${assignedContracts.length} Account${
                  assignedContracts.length === 1 ? '' : 's'
                }`}
              </Tag>
            </div>
            <div>
              <Tag color="yellow">Assignment In-Progress</Tag>
            </div>
          </Space>
        ) : (
          <Tag color="yellow">Assignment In-Progress</Tag>
        );
      case 'opt_in':
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tag color="cyan">Eulith</Tag>
            <Tag color="blue">Primitive</Tag>
          </div>
        );
      default:
        return <Tag>Draft</Tag>;
    }
  }

  function returnDraftHeader() {
    return (
      <Space>
        <Popconfirm
          title="Delete this whitelist?"
          description="You will not be able to recover the whitelist after deletion."
          okText="Delete"
          okButtonProps={{
            danger: true,
            ghost: true
          }}
          onCancel={(e) => {
            e?.stopPropagation?.();
          }}
          onConfirm={(e) => {
            e?.preventDefault?.();
            e?.stopPropagation?.();
            deleteWhitelist(e);
          }}
        >
          <Button
            danger
            onClick={(e: any) => {
              e?.preventDefault?.();
              e?.stopPropagation?.();
            }}
          >
            Delete
          </Button>
        </Popconfirm>
        <Popconfirm
          title="Publish this whitelist?"
          description="You will not be able to edit the whitelist after publication."
          okText="Publish"
          onCancel={(e) => {
            e?.stopPropagation?.();
          }}
          onConfirm={(e) => {
            e?.preventDefault?.();
            e?.stopPropagation?.();
            handlePublishWhitelist();
          }}
        >
          <Button
            type="primary"
            onClick={(e: any) => {
              e?.preventDefault?.();
              e?.stopPropagation?.();
            }}
          >
            Finalize
          </Button>
        </Popconfirm>
      </Space>
    );
  }

  async function handlePublishWhitelist() {
    if (!eulithSingleton.provider) {
      message.error('Eulith instance not yet initialized.');
      return;
    }
    if (!selectedWhitelist) {
      message.error('You must select a whitelist first.');
      return;
    }
    try {
      await publishWhitelist({ listId: selectedWhitelist.listId }).unwrap();
      message.success('Whitelist was published!');
      navigateToEditWhitelist(selectedWhitelist.listId);
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to start whitelist activation',
        metadata: {
          selectedWhitelist,
          address
        }
      });
      notification.open({
        message: 'An error occurred when publishing this whitelist draft.',
        description: error?.message || '',
        placement: 'top',
        type: 'error',
        duration: null
      });
      console.warn(error);
    }
  }

  async function submitSignature() {
    if (!eulithSingleton.provider) {
      message.warning(
        'Unable to submit signature because Eulith services have not been initialized.'
      );
    } else if (!address) {
      message.warning('A trading key address was not provided when authorizing whitelist.');
    } else if (!selectedWhitelist) {
      message.warning('You must select a whitelist before authorizing this whitelist.');
    } else {
      const activation = pendingActivations.find((activation) => {
        return activation.whitelist.listId === selectedWhitelist.listId;
      });
      if (activation) {
        try {
          const request = {
            activationId: activation.activationId,
            hashInput: activation.hashInput,
            hash: activation.hash
          };
          const response = await submitWhitelistSignature({ address, request }).unwrap();
          await getContracts().unwrap();
          await getPendingActivations().unwrap();
          if (response.signatureCount >= response.signatureThreshold) {
            message.success(
              'Signature successfully submitted, and whitelist is now enabled on this account!'
            );
          } else {
            message.success(
              `Signature successfully submitted! ${
                response.signatureThreshold - response.signatureCount
              } more ${
                response.signatureThreshold - response.signatureCount === 1
                  ? 'signature'
                  : 'signature'
              } required to enable this whitelist for this account.`
            );
          }
        } catch (error: any) {
          notification.open({
            message: 'An error occurred when submitting whitelist signature.',
            description: error?.message || '',
            placement: 'top',
            type: 'error',
            duration: null
          });
          console.warn(error);
        }
      } else {
        notification.open({
          message: 'Unable to activate.',
          description: 'Could not find an activation signature for this whitelist.',
          placement: 'top',
          type: 'error',
          duration: null
        });
      }
    }
  }

  async function startActivation() {
    toggleStartActivationModal();
    if (!eulithSingleton.provider) {
      message.warning(
        'Unable to assign this account a whitelist because Eulith services have not been initialized.'
      );
    } else if (!address) {
      message.warning(
        'A trading key address was not provided when assigning a whitelist to an account.'
      );
    } else if (!selectedWhitelistContract) {
      message.warning('You must select an account before assigning a whitelist.');
    } else if (!selectedWhitelist) {
      console.warn('Cannot assign to account without a selected whitelist.');
      return;
    } else {
      try {
        await startWhitelistActivation({
          listId: selectedWhitelist.listId,
          tradingKeyAddress: selectedWhitelistContract.tradingKeyAddress,
          safeAddress: selectedWhitelistContract.safeAddress,
          chainId: selectedWhitelistContract.chainId
        }).unwrap();
        message.success(
          'Whitelist has been assigned! You now need to sign the activation request with a threshold of account owners.'
        );
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to assign a whitelist signature',
          metadata: {
            address,
            listId: selectedWhitelist.listId,
            tradingKeyAddress: selectedWhitelistContract.tradingKeyAddress,
            safeAddress: selectedWhitelistContract.safeAddress,
            chainId: selectedWhitelistContract.chainId
          }
        });
        notification.open({
          message: 'An error occurred when assigning this whitelist to your account.',
          description: error?.message || '',
          placement: 'top',
          type: 'error',
          duration: null
        });
        console.warn(error);
      }
    }
  }

  async function deleteWhitelist(e: any) {
    e?.domEvent?.stopPropagation?.();
    e?.domEvent?.preventDefault?.();
    if (!eulithSingleton.provider) {
      message.error('Eulith instance not yet initialized.');
      return;
    }
    if (!selectedWhitelist) {
      message.error('You must select a whitelist first.');
      return;
    }
    try {
      await deleteDraft({ listId: selectedWhitelist?.listId }).unwrap();
      message.success('Draft deleted!');
      navigateToOverview();
    } catch (error: any) {
      notification.open({
        message: 'An error occurred when deleting this whitelist draft.',
        description: error?.message || '',
        placement: 'top',
        type: 'error',
        duration: null
      });
      console.warn(error);
    }
  }

  function handleSubmitDraft() {
    if (whitelistDraftRef.current) {
      whitelistDraftRef.current.save();
    }
  }

  function handleDiscardDraft() {
    if (whitelistDraftRef.current) {
      whitelistDraftRef.current.discard();
    }
  }

  async function handleAddOptInListToWhitelist(optInList: Eulith.WhitelistsV2.OptInList) {
    if (!eulithSingleton.provider) {
      message.warning(
        'Unable to submit signature because Eulith services have not been initialized.'
      );
    } else if (!selectedWhitelist) {
      message.warning(
        'You must select a whitelist before adding a predefined list for this whitelist.'
      );
    } else if (!chainId) {
      message.warning(
        'Please connect a wallet before adding a predefined list for this whitelist.'
      );
    } else {
      if (isWhitelist(selectedWhitelist)) {
        try {
          await appendWhitelistDraft({
            listId: selectedWhitelist.listId,
            addresses: [],
            sublists: [optInList.listId]
          }).unwrap();
          message.success('Whitelist draft successfully updated!');
        } catch (error: any) {
          notification.open({
            message: 'An error occurred when adding predefined list to this whitelist.',
            description: error?.message || '',
            placement: 'top',
            type: 'error',
            duration: null
          });
        }
      }
    }
  }

  async function handleRemoveOptInListToWhitelist(optInList: Eulith.WhitelistsV2.OptInList) {
    if (!eulithSingleton.provider) {
      message.warning(
        'Unable to append address because Eulith services have not been initialized.'
      );
    } else if (!selectedWhitelist) {
      message.warning(
        'You must select a whitelist before deleting predefined lists for this whitelist.'
      );
    } else if (!chainId) {
      message.warning(
        'Please connect a wallet before deleting predefined lists for this whitelist.'
      );
    } else {
      try {
        await deleteWhitelistAddress({
          addresses: [],
          sublists: [optInList.listId],
          listId: selectedWhitelist.listId
        }).unwrap();
        message.success('Whitelist draft successfully updated!');
      } catch (error: any) {
        notification.open({
          message: 'An error occurred when deleting predefined list from this whitelist.',
          description: error?.message || '',
          placement: 'top',
          type: 'error',
          duration: null
        });
      }
    }
  }

  async function handleAppendAddressesToWhitelist(address: Eulith.WhitelistsV2.AddressOnChain) {
    if (!eulithSingleton.provider) {
      message.warning(
        'Unable to append address because Eulith services have not been initialized.'
      );
    } else if (!selectedWhitelist) {
      message.warning(
        'You must select a whitelist before submitting addresses for this whitelist.'
      );
    } else if (!chainId) {
      message.warning('Please connect a wallet before submitting addresses for this whitelist.');
    } else {
      try {
        await appendWhitelistDraft({
          addresses: [address],
          listId: selectedWhitelist.listId
        }).unwrap();
        message.success('Whitelist draft successfully updated!');
      } catch (error: any) {
        notification.open({
          message: 'An error occurred when appending addresses to this whitelist.',
          description: error?.message || '',
          placement: 'top',
          type: 'error',
          duration: null
        });
      }
    }
  }

  function returnTopCopyForEditSection() {
    if (!selectedWhitelist) {
      return null;
    }
    const activation = pendingActivationsIdMap[selectedWhitelist.listId];
    const activationAccount = contracts.find(
      (c) => c.safeAddress === activation?.hashInput.safeAddress
    );
    switch (selectedWhitelistStatus) {
      case 'pending_activation':
        if (!selectedWhitelistContract) {
          return null;
        }
        return (
          <Alert
            type="info"
            showIcon
            message="Pending Account Assignment"
            description={
              <div>
                This whitelist can be assigned to a particular account. Once assigned, this
                whitelist will require signatures from the threshold of account owners to become
                enabled. Click{' '}
                <strong>
                  {contractsWhitelistIdMap[selectedWhitelist.listId] && associatedContracts?.length
                    ? 'Assign to Another Account'
                    : 'Assign to Account'}
                </strong>{' '}
                to select an account and begin the process.
                <br />
                <br />
                <strong>Note:</strong> A single whitelist can be assigned to more than one account.
              </div>
            }
            style={{ marginBottom: 20 }}
          />
        );
      case 'awaiting_owner_signatures':
        if (!selectedWhitelistContract) {
          return null;
        }
        return (
          <Alert
            type="info"
            message="Awaiting Owner Signatures"
            showIcon
            style={{ marginBottom: 20 }}
            description={
              <div>
                This whitelist is assigned to account{' '}
                <span style={{ fontWeight: 'bold' }}>{activationAccount?.name}</span> on{' '}
                <strong>{chainIdToNetworkLabel(activation.hashInput.chainId)}</strong>, but has not
                yet received enough signatures from account owners to become enabled.
              </div>
            }
          />
        );
      case 'immutable':
        if (!selectedWhitelistContract) {
          return null;
        }
        if (activation) {
          return (
            <Alert
              description={
                <div>
                  This whitelist is assigned to{' '}
                  <span style={{ fontWeight: 'bold' }}>{activationAccount?.name}</span> on{' '}
                  <strong>{chainIdToNetworkLabel(activation.hashInput.chainId)}</strong> but has not
                  yet received any signatures from account owners.
                </div>
              }
            />
          );
        } else {
          return null;
        }
      default:
        return (
          <Alert
            showIcon
            type="info"
            message="Pending Publication"
            style={{ marginBottom: 20 }}
            description={
              <div>
                This draft whitelist must be published before it can be assigned to an account. Once
                published, it cannot be changed. If you want to make changes in the future, you can
                duplicate this whitelist into a new draft and edit the copied draft. We enforce
                whitelist immutability for your security.
              </div>
            }
          />
        );
    }
  }

  function returnEditScreenHeaderRight() {
    if (!selectedWhitelist) {
      return null;
    }
    if (isWhitelist(selectedWhitelist)) {
      switch (selectedWhitelistStatus) {
        case 'draft':
          return returnDraftHeader();
        case 'immutable':
        case 'awaiting_owner_signatures':
        case 'pending_activation':
          if (!selectedWhitelistContract) {
            return null;
          }
          return (
            <Space>
              {contractsWhitelistIdMap[
                selectedWhitelist.listId
              ] ? null : selectedWhitelist?.isArchived ? (
                <Button
                  onClick={(e: any) => {
                    e?.preventDefault?.();
                    e?.stopPropagation?.();
                    handleArchiveWhitelist(selectedWhitelist, false);
                  }}
                >
                  Unarchive
                </Button>
              ) : (
                <Popconfirm
                  title="Archive this whitelist?"
                  description="You will not be able to edit this whitelist after archiving."
                  okText="Archive"
                  okButtonProps={{
                    danger: true,
                    ghost: true
                  }}
                  onCancel={(e) => {
                    e?.stopPropagation?.();
                  }}
                  onConfirm={(e) => {
                    e?.preventDefault?.();
                    e?.stopPropagation?.();
                    handleArchiveWhitelist(selectedWhitelist, true);
                  }}
                >
                  <Button
                    danger
                    onClick={(e: any) => {
                      e?.preventDefault?.();
                      e?.stopPropagation?.();
                    }}
                  >
                    Archive
                  </Button>
                </Popconfirm>
              )}
              {pendingActivationsIdMap[selectedWhitelist.listId] ? null : (
                <Button type="primary" onClick={toggleStartActivationModal}>
                  {contractsWhitelistIdMap[selectedWhitelist.listId] && associatedContracts?.length
                    ? 'Assign to Another Account'
                    : 'Assign to Account'}
                </Button>
              )}
            </Space>
          );
        default:
          return null;
      }
    } else {
      return returnDraftHeader();
    }
  }

  function copyAddress(address: string) {
    if (address) {
      navigator.clipboard.writeText(address);
      message.success('Copied to clipboard!');
    }
  }

  const networkColumns: ColumnsType<any> = [
    {
      title: 'Network',
      dataIndex: 'chainId',
      width: 100,
      render: (id?: number) => {
        let logo = null;
        if (id) {
          const chain = getAllChains().find((chain) => chain.chainId === id);
          logo = calculateChainIconUrl(chain?.icon?.url || '', id);
        }
        return logo ? (
          <Image src={logo} width={30} height={30} preview={false} />
        ) : (
          <QuestionCircleOutlined style={{ fontSize: 30, color: 'rgba(255,255,255,0.3)' }} />
        );
      }
    },
    {
      title: 'Network Name',
      dataIndex: 'chainId',
      render: (id: number) => chainIdToNetworkLabel(id)
    },
    {
      title: 'Address Count',
      dataIndex: 'addressCount'
    }
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Address',
      dataIndex: 'address',
      width: 300,
      render: (value: any, record: any) => {
        let logo = null;
        if (record?.chainId) {
          const chain = getAllChains().find((chain) => chain.chainId === record?.chainId);
          logo = calculateChainIconUrl(chain?.icon?.url || '', record?.chainId);
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 15 }}>
              {logo ? (
                <Image src={logo} width={30} height={30} preview={false} />
              ) : (
                <QuestionCircleOutlined style={{ fontSize: 30, color: 'rgba(255,255,255,0.3)' }} />
              )}
            </div>
            <div style={{ width: 200 }}>
              <MiddleEllipses>
                <MonoSpaceSpan>{value}</MonoSpaceSpan>
              </MiddleEllipses>
            </div>
            <Button
              onClick={() => copyAddress(value)}
              ghost
              type="text"
              shape="circle"
              style={{ color: colors.placeholder, marginLeft: 5 }}
            >
              <CopyOutlined style={{ color: colors.placeholder }} />
            </Button>
          </div>
        );
      }
    },
    {
      title: 'Network',
      dataIndex: 'chainId',
      width: '20%',
      render: (value: number, record: any) => {
        const networkLabel = chainIdToNetworkLabel(value);
        if (!networkLabel) {
          console.warn('Could not find a network label for record', record);
        }
        const chainName = networkLabel || '?';
        return chainName
          .split(' ')
          .filter((x) => x)
          .map((word) => {
            return (
              <div
                key={`split_whitelist_network_name_${record.address}_${word}`}
                style={{ fontSize: 13 }}
              >
                {word}
              </div>
            );
          });
      }
    },
    {
      title: 'Alerts',
      width: 100,
      dataIndex: 'alerts',
      render: () => <CheckCircleOutlined style={{ color: colors.success }} />
    },
    {
      title: 'Label & Notes',
      dataIndex: 'notes',
      render: (text: string, record: Eulith.WhitelistsV2.AddressOnChain) => {
        function setAddress() {
          editSelectedAddressForm.setFieldsValue({
            notes: record.notes
          });
          setSelectedAddress(record);
        }

        return (
          <div onClick={setAddress} className="edit-address-cell">
            {text || '-'}
          </div>
        );
      }
    }
  ];

  if (selectedWhitelistStatus === 'draft') {
    columns.push({
      title: 'Actions',
      render: (action: string, record: Eulith.WhitelistsV2.AddressOnChain) => {
        if (!selectedWhitelist) {
          return null;
        }
        return (
          <Popconfirm
            title="Remove this address?"
            description="You will need to re-authorize this address if you wish to add it back later."
            okText="Delete"
            okButtonProps={{
              danger: true,
              ghost: true
            }}
            onCancel={(e) => {
              e?.stopPropagation?.();
            }}
            onConfirm={(e) => {
              e?.preventDefault?.();
              e?.stopPropagation?.();
              handleRemoveAddress(record as Eulith.WhitelistsV2.AddressOnChain);
            }}
          >
            <Button danger type="link" style={{ padding: 0 }}>
              Remove
            </Button>
          </Popconfirm>
        );
      }
    });
  }

  if (isLoading) {
    return (
      <div style={{ height: '100%', position: 'relative' }}>
        <Row
          gutter={[16, 16]}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Text style={{ paddingBottom: 20, textAlign: 'center' }}>Loading...</Text>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </Row>
      </div>
    );
  }
  if (!(walletIsInitialized && connectionStatus !== 'disconnected')) {
    return (
      <Row
        gutter={[16, 16]}
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Text>
          {connectionStatus !== 'disconnected' ? 'Initializing...' : 'Please connect a wallet'}
        </Text>
        {connectionStatus !== 'disconnected' ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : (
          <ConnectWallet theme="dark" />
        )}
      </Row>
    );
  }
  return (
    <>
      <EulithHelmetConsumer>Trading Whitelists</EulithHelmetConsumer>
      <Container>
        <Row
          gutter={[16, 16]}
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Col xs={8} md={10} lg={10} xl={8} xxl={6} style={{ height: '100%' }}>
            <LeftColumnContainer theme={isDarkMode ? 'dark' : 'light'}>
              <StepsTitleInputContainer onClick={navigateToOverview}>
                <H2>Whitelist Library</H2>
              </StepsTitleInputContainer>
              <StepsContainer id="steps-scrollable-container">
                {whitelists.map((whitelist, index) => {
                  const associatedContract = contractsWhitelistIdMap[whitelist.listId];
                  return (
                    <StyledWhitelistTile
                      key={`whitelist_tile_${whitelist.listId}_${index}`}
                      selected={selectedWhitelist?.listId === whitelist.listId}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToEditWhitelist(whitelist.listId);
                      }}
                    >
                      <div className="text-overflow-ellipses" style={{ paddingRight: 7 }}>
                        {returnTagForWhitelist(whitelist)}
                        <H3 style={{ marginTop: 5 }} className="text-overflow-ellipses">
                          {isWhitelist(whitelist)
                            ? whitelist.displayName || 'Untitled Whitelist'
                            : whitelist.label || 'Untitled Whitelist'}
                        </H3>
                      </div>
                      {isWhitelist(whitelist) ? (
                        <Dropdown
                          placement="bottomRight"
                          trigger={['click']}
                          menu={{
                            items: [
                              {
                                key: 'duplicate_whitelist',
                                onClick: (e: any) => {
                                  e.domEvent.stopPropagation();
                                  handleDuplicateWhitelist(whitelist);
                                },
                                label: 'Duplicate'
                              },
                              associatedContract || !isWhitelist(whitelist)
                                ? null
                                : {
                                    key: 'archive_whitelist',
                                    danger: !whitelist.isArchived,
                                    label: whitelist.isDraft ? (
                                      <Popconfirm
                                        title="Delete this draft?"
                                        description="You will not be able to recover this draft."
                                        okText="Delete"
                                        okButtonProps={{
                                          danger: true,
                                          ghost: true
                                        }}
                                        onCancel={(e) => {
                                          e?.stopPropagation?.();
                                        }}
                                        onConfirm={(e) => {
                                          e?.preventDefault?.();
                                          e?.stopPropagation?.();
                                          deleteDraft(whitelist);
                                          navigateToOverview();
                                        }}
                                      >
                                        <a onClick={(e: any) => e?.stopPropagation?.()}>Delete</a>
                                      </Popconfirm>
                                    ) : whitelist.isArchived ? (
                                      <a
                                        onClick={(e: any) => {
                                          e?.stopPropagation?.();
                                          handleArchiveWhitelist(whitelist, false);
                                        }}
                                      >
                                        Unarchive
                                      </a>
                                    ) : (
                                      <Popconfirm
                                        title="Archive this whitelist?"
                                        description="You will not be able to edit this whitelist after archiving."
                                        okText="Archive"
                                        okButtonProps={{
                                          danger: true,
                                          ghost: true
                                        }}
                                        onCancel={(e) => {
                                          e?.stopPropagation?.();
                                        }}
                                        onConfirm={(e) => {
                                          e?.preventDefault?.();
                                          e?.stopPropagation?.();
                                          handleArchiveWhitelist(whitelist, true);
                                        }}
                                      >
                                        <a onClick={(e: any) => e?.stopPropagation?.()}>Archive</a>
                                      </Popconfirm>
                                    )
                                  }
                            ].filter((x) => x)
                          }}
                        >
                          <Button
                            style={{ backgroundColor: 'transparent', minWidth: 32 }}
                            icon={<EllipsisOutlined />}
                            onClick={(e: any) => {
                              e?.stopPropagation?.();
                              e?.preventDefault?.();
                            }}
                          />
                        </Dropdown>
                      ) : null}
                    </StyledWhitelistTile>
                  );
                })}
                <StyledWhitelistTile
                  //@ts-ignore
                  selected={selectedWhitelist?.listId === 'eulith'}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigateToEditWhitelist('eulith');
                  }}
                >
                  <div className="text-overflow-ellipses" style={{ paddingRight: 7 }}>
                    <Tag color="cyan">Eulith</Tag>
                    <Tag color="green">Live</Tag>
                    <H3 style={{ marginTop: 5 }} className="text-overflow-ellipses">
                      Default Fixed Whitelist
                    </H3>
                  </div>
                </StyledWhitelistTile>
              </StepsContainer>
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                {whitelists.length ? (
                  <Checkbox
                    onChange={toggleArchived}
                    style={{ margin: '10px 0', userSelect: 'none' }}
                  >
                    <H3>Show Archived</H3>
                  </Checkbox>
                ) : null}
                <StyledCreateNewButton onClick={() => navigateToCreateWhitelist()}>
                  <H3>Create New Whitelist</H3>
                </StyledCreateNewButton>
              </div>
            </LeftColumnContainer>
          </Col>
          <Col xs={16} md={14} lg={14} xl={16} xxl={18} style={{ height: '100%' }}>
            <SwitchTransition>
              <CSSTransition key={location.pathname} classNames="fade" timeout={300} unmountOnExit>
                <Routes location={location}>
                  {sections.map((section) => {
                    const activation = selectedWhitelist
                      ? pendingActivationsIdMap[selectedWhitelist.listId]
                      : null;
                    switch (section.path) {
                      case ROUTES.OVERVIEW:
                        return (
                          <Route
                            key={section.title}
                            path={section.path}
                            element={
                              <EulithCard
                                fullHeight
                                bodyStyle={{
                                  height: 'calc(100% - 80px)',
                                  padding: 0,
                                  overflow: 'auto'
                                }}
                                style={{ height: '100%', overflow: 'hidden' }}
                              >
                                <Row
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <EmptyState
                                    description={
                                      whitelists.length
                                        ? 'Please select a whitelist.'
                                        : 'No whitelists found.'
                                    }
                                  />
                                </Row>
                              </EulithCard>
                            }
                          />
                        );
                      case ROUTES.EDIT:
                        return (
                          <Route
                            key={section.title}
                            path={section.path}
                            element={
                              selectedWhitelist && isWhitelist(selectedWhitelist) ? (
                                <EulithCard
                                  fullHeight
                                  bodyStyle={{
                                    height: 'calc(100% - 100px)',
                                    padding: 0,
                                    overflow: 'auto'
                                  }}
                                  style={{ height: '100%', overflow: 'hidden', marginBottom: 20 }}
                                  title={
                                    selectedWhitelist ? (
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        {returnTagForWhitelist(selectedWhitelist, false)}
                                        <Row>
                                          <H3 className="text-overflow-ellipses">
                                            {selectedWhitelist.displayName || 'Untitled Whitelist'}
                                          </H3>
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <EditOutlined
                                              style={{
                                                fill: '#fff',
                                                marginLeft: 5,
                                                cursor: 'pointer'
                                              }}
                                              onClick={toggleEditSelectedWhitelistMetadataModal}
                                            />
                                            {isWhitelist(selectedWhitelist) &&
                                            selectedWhitelist.createdAt ? (
                                              <span
                                                style={{
                                                  marginLeft: 10,
                                                  opacity: 0.4,
                                                  fontWeight: 'normal',
                                                  fontStyle: 'italic'
                                                }}
                                              >
                                                Created{' '}
                                                {new Date(
                                                  selectedWhitelist.createdAt
                                                ).toLocaleDateString()}
                                              </span>
                                            ) : null}
                                          </div>
                                        </Row>
                                      </div>
                                    ) : null
                                  }
                                  extra={returnEditScreenHeaderRight()}
                                >
                                  <FundsSectionContainer>
                                    {returnTopCopyForEditSection()}
                                    <div style={{ marginBottom: 15 }}>
                                      {contractsWhitelistIdMap[selectedWhitelist.listId] &&
                                      associatedContracts?.length ? (
                                        <div style={{ marginBottom: 15 }}>
                                          <StyledLabel title="Accounts using this whitelist">
                                            Accounts using this whitelist
                                          </StyledLabel>
                                          <ul>
                                            {associatedContracts.map((c) => {
                                              return <li key={c.safeAddress}>{c.name}</li>;
                                            })}
                                          </ul>
                                        </div>
                                      ) : null}
                                      <Row>
                                        <StyledLabel title="Description">Description</StyledLabel>
                                        <EditOutlined
                                          style={{ fill: '#fff', marginLeft: 5, cursor: 'pointer' }}
                                          onClick={toggleEditSelectedWhitelistMetadataModal}
                                        />
                                      </Row>
                                      <div>
                                        {selectedWhitelist?.description ? (
                                          <div style={{ marginTop: 10, marginBottom: 20 }}>
                                            {selectedWhitelist?.description}
                                          </div>
                                        ) : (
                                          <StyledItalic>No description added</StyledItalic>
                                        )}
                                      </div>
                                    </div>
                                    {isWhitelist(selectedWhitelist) && activation ? (
                                      <div style={{ marginBottom: 15 }}>
                                        <div style={{ marginBottom: 15 }}>
                                          <StyledLabel title="Account Authorizations">
                                            Account Authorizations
                                          </StyledLabel>
                                          <StyledItalic>
                                            {activation.ownersAlreadySigned.length}/
                                            {activation.safeOwners.length} contract Owner(s) have
                                            authorized this whitelist, out of a threshold of{' '}
                                            {activation.signatureThreshold}.
                                          </StyledItalic>
                                        </div>
                                        <Row gutter={[16, 16]} style={{ marginBottom: 15 }}>
                                          <Col span={12} style={{ textAlign: 'center' }}>
                                            <EulithCard title="Pending Signatures">
                                              {activation.safeOwners.map((owner, index) => {
                                                const walletConnected =
                                                  owner.toLowerCase() === address?.toLowerCase?.();
                                                const isLast =
                                                  index === activation.safeOwners.length - 1;
                                                const hasSigned =
                                                  activation.ownersAlreadySigned.find((address) => {
                                                    return (
                                                      address.toLowerCase() === owner.toLowerCase()
                                                    );
                                                  });
                                                if (hasSigned) {
                                                  return null;
                                                }
                                                return (
                                                  <Space.Compact
                                                    block
                                                    style={{
                                                      width: '100%',
                                                      justifyContent: 'center',
                                                      marginBottom: isLast ? 0 : 15
                                                    }}
                                                    key={`pending_whitelist_signatures_${owner}`}
                                                  >
                                                    <Button
                                                      type="default"
                                                      style={{
                                                        width: '100%',

                                                        pointerEvents: 'none',
                                                        backgroundColor: 'transparent'
                                                      }}
                                                    >
                                                      <MiddleEllipses>
                                                        <MonoSpaceSpan>{owner}</MonoSpaceSpan>
                                                      </MiddleEllipses>
                                                    </Button>
                                                    {walletConnected ? (
                                                      <Popconfirm
                                                        title="Authorize this whitelist?"
                                                        description={
                                                          <div style={{ maxWidth: 300 }}>
                                                            This action is permanent, and you will
                                                            not be able to revoke your authorization
                                                            once submitted.
                                                            {activation.ownersAlreadySigned
                                                              .length === 0 ? null : activation
                                                                .ownersAlreadySigned.length ===
                                                              activation.signatureThreshold - 1 ? (
                                                              <strong>
                                                                {' '}
                                                                You will be the final signature
                                                                required to enable this whitelist
                                                                for this account.
                                                              </strong>
                                                            ) : (
                                                              ''
                                                            )}
                                                          </div>
                                                        }
                                                        okText="Authorize"
                                                        onCancel={(e) => {
                                                          e?.stopPropagation?.();
                                                        }}
                                                        onConfirm={(e) => {
                                                          e?.preventDefault?.();
                                                          e?.stopPropagation?.();
                                                          submitSignature();
                                                        }}
                                                      >
                                                        <Button
                                                          type="primary"
                                                          icon={<EditOutlined />}
                                                        />
                                                      </Popconfirm>
                                                    ) : (
                                                      <Popover content="Please connect to this wallet address to submit a signature.">
                                                        <Button
                                                          type="primary"
                                                          icon={<EditOutlined />}
                                                          disabled
                                                        />
                                                      </Popover>
                                                    )}
                                                  </Space.Compact>
                                                );
                                              })}
                                            </EulithCard>
                                          </Col>
                                          <Col span={12} style={{ textAlign: 'center' }}>
                                            <EulithCard
                                              title={`${activation.ownersAlreadySigned.length} of ${activation.signatureThreshold} Signed`}
                                            >
                                              {activation.ownersAlreadySigned?.length ? (
                                                activation.ownersAlreadySigned.map(
                                                  (owner, index) => {
                                                    const isLast =
                                                      index ===
                                                      activation.ownersAlreadySigned.length - 1;
                                                    return (
                                                      <Space.Compact
                                                        block
                                                        style={{
                                                          width: '100%',
                                                          justifyContent: 'center',
                                                          marginBottom: isLast ? 0 : 15
                                                        }}
                                                        key={`authorized_whitelist_signatures_${owner}`}
                                                      >
                                                        <Button
                                                          type="default"
                                                          style={{
                                                            width: '100%',
                                                            pointerEvents: 'none',
                                                            backgroundColor: 'transparent'
                                                          }}
                                                        >
                                                          <Row>
                                                            <Col span={2}>
                                                              <CheckCircleFilled
                                                                style={{
                                                                  color: colors.success,
                                                                  marginRight: 7
                                                                }}
                                                              />
                                                            </Col>
                                                            <Col span={22}>
                                                              <MiddleEllipses>
                                                                <MonoSpaceSpan>
                                                                  {owner}
                                                                </MonoSpaceSpan>
                                                              </MiddleEllipses>
                                                            </Col>
                                                          </Row>
                                                        </Button>
                                                      </Space.Compact>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <StyledItalic>No Owners have Signed</StyledItalic>
                                              )}
                                            </EulithCard>
                                          </Col>
                                        </Row>
                                      </div>
                                    ) : null}
                                    <div>
                                      <StyledLabel title="Address(es)">Address(es)</StyledLabel>
                                      <EulithTable
                                        scroll={{ x: 600, y: 600 }}
                                        dataSource={selectedWhitelist?.sortedAddresses || []}
                                        columns={columns}
                                        pagination={false}
                                        rowKey={(record) => record.address}
                                        style={{ marginBottom: 20 }}
                                      />
                                    </div>
                                    {selectedWhitelistStatus === 'draft' ? (
                                      <div style={{ margin: '10px 0' }}>
                                        <AddWhitelistAddressForm
                                          data={selectedWhitelist?.sortedAddresses || []}
                                          selectedOptInLists={
                                            selectedWhitelist?.sortedSublists || []
                                          }
                                          optInLists={optInWhitelists || []}
                                          addAddress={handleAppendAddressesToWhitelist}
                                          addOptInList={handleAddOptInListToWhitelist}
                                          removeOptInList={handleRemoveOptInListToWhitelist}
                                        />
                                      </div>
                                    ) : null}
                                    {selectedWhitelist &&
                                    isWhitelist(selectedWhitelist) &&
                                    !selectedWhitelist?.isDraft &&
                                    selectedWhitelist?.sortedSublists?.length ? (
                                      <div style={{ marginBottom: 20 }}>
                                        <div style={{ marginTop: 5 }}>
                                          <StyledLabel title="Included Predefined Whitelist(s)">
                                            <H3>Included Predefined Whitelist(s)</H3>
                                          </StyledLabel>
                                        </div>
                                        <ul>
                                          {selectedWhitelist?.sortedSublists.map((optInList) => {
                                            return (
                                              <li key={optInList.listId}>
                                                <Link
                                                  to={`/armor/whitelists/${optInList.listId}/edit`}
                                                >
                                                  {optInList.name}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    ) : null}
                                  </FundsSectionContainer>
                                </EulithCard>
                              ) : (
                                <EulithCard
                                  fullHeight
                                  bodyStyle={{
                                    height: 'calc(100% - 100px)',
                                    padding: 0,
                                    overflow: 'auto'
                                  }}
                                  style={{ height: '100%', overflow: 'hidden' }}
                                  title={
                                    selectedWhitelist ? (
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {returnTagForWhitelist(selectedWhitelist, false)}
                                        <H3 className="text-overflow-ellipses">
                                          {selectedWhitelist.name}
                                        </H3>
                                      </div>
                                    ) : isFixed ? (
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Tag color="cyan">Eulith</Tag>
                                        <Tag color="green">Live</Tag>
                                        <H3 className="text-overflow-ellipses">
                                          Default Fixed Whitelist
                                        </H3>
                                      </div>
                                    ) : null
                                  }
                                >
                                  <FundsSectionContainer>
                                    {isFixed ? (
                                      <Alert
                                        type="info"
                                        showIcon
                                        message="Fixed Whitelist"
                                        style={{ marginBottom: 20 }}
                                        description="This whitelist is provided to you from Eulith, based on our due diligence of the addresses and associated contracts."
                                      />
                                    ) : (
                                      <Alert
                                        type="info"
                                        showIcon
                                        message="Primitive Whitelist"
                                        style={{ marginBottom: 20 }}
                                        description="This whitelist is a policy primitive, it cannot be directly assigned to an account. If you would like to use it, you need to create a draft whitelist and select in one of the check boxes below when creating a new whitelist."
                                      />
                                    )}
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                      <div>
                                        <StyledLabel title="Description">Description</StyledLabel>
                                        <div>
                                          {isFixed ? (
                                            <div style={{ marginTop: 10 }}>
                                              This whitelist is created by Eulith. It is currently
                                              assigned to every account automatically. It enables a
                                              key to trade on the main protocols. Currently, this
                                              whitelist cannot be removed from an account. Ask us
                                              for more details if needed.
                                            </div>
                                          ) : selectedWhitelist?.description ? (
                                            <div style={{ marginTop: 10, marginBottom: 20 }}>
                                              {selectedWhitelist?.description}
                                            </div>
                                          ) : (
                                            <StyledItalic>No description added</StyledItalic>
                                          )}
                                        </div>
                                        {isFixed ? null : (
                                          <div>
                                            <StyledLabel title="Network(s)">Network(s)</StyledLabel>
                                            <EulithTable
                                              scroll={{ x: 600, y: 600 }}
                                              dataSource={selectedWhitelist?.networks || []}
                                              columns={networkColumns}
                                              pagination={false}
                                              rowKey={(record) =>
                                                `opt-in-whitelist-${record.listId}`
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {isFixed ? null : (
                                        <div style={{ width: 250, paddingRight: 50 }}>
                                          <StyledLabel title="Champion">Champion</StyledLabel>
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginTop: 20
                                            }}
                                          >
                                            <StyledImage
                                              src={require('../assets/images/emblem_white.png')}
                                            />
                                          </div>
                                          <div style={{ marginTop: 20 }}>
                                            <StyledLabel title="Tested By">Tested By</StyledLabel>
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: 20
                                              }}
                                            >
                                              <StyledImage
                                                src={require('../assets/images/zellic.png')}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div style={{ marginTop: 20 }}>
                                      <Row gutter={[16, 16]}>
                                        <Col span={18}>
                                          <StyledLabel title="TVL">TVL</StyledLabel>
                                          <div style={{ marginTop: 20 }}>
                                            <EulithChart data={mockData} height={250} />
                                          </div>
                                        </Col>
                                        <Col span={6} style={{ marginTop: 45 }}>
                                          <Button type="primary" block style={{ marginBottom: 10 }}>
                                            Invest
                                          </Button>
                                          <div>
                                            <StyledLabel title="Invested">Invested: </StyledLabel>
                                            $1,000,000
                                          </div>
                                          <div>
                                            <StyledLabel title="Price">Price: </StyledLabel>
                                            $1/share
                                          </div>
                                          <div>
                                            <StyledLabel title="Raily Rev">Raily Rev: </StyledLabel>
                                            $5/share
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </FundsSectionContainer>
                                </EulithCard>
                              )
                            }
                          />
                        );
                      case ROUTES.CREATE:
                        return (
                          <Route
                            key={section.title}
                            path={section.path}
                            element={
                              <div>
                                <EulithCard
                                  fullHeight
                                  bodyStyle={{
                                    height: 'calc(100% - 80px)',
                                    padding: 0,
                                    overflow: 'auto'
                                  }}
                                  style={{ height: '100%', overflow: 'hidden' }}
                                  title={section.title}
                                  extra={
                                    <Space>
                                      <Button danger ghost onClick={handleDiscardDraft}>
                                        Discard
                                      </Button>
                                      <Button type="primary" onClick={handleSubmitDraft}>
                                        Save
                                      </Button>
                                    </Space>
                                  }
                                >
                                  <FundsSectionContainer>
                                    <CreateWhitelistDraft
                                      ref={whitelistDraftRef}
                                      optInWhitelists={optInWhitelists}
                                      onDiscard={navigateToOverview}
                                      onFinish={navigateToEditWhitelist}
                                    />
                                  </FundsSectionContainer>
                                </EulithCard>
                              </div>
                            }
                          />
                        );
                      default:
                        return null;
                    }
                  })}
                </Routes>
              </CSSTransition>
            </SwitchTransition>
          </Col>
        </Row>
      </Container>
      <Modal
        title={
          selectedWhitelist &&
          contractsWhitelistIdMap[selectedWhitelist.listId] &&
          associatedContracts?.length
            ? 'Assign to Another Account?'
            : 'Assign to Account?'
        }
        okText="Start"
        cancelText="Cancel"
        open={startActivationModalVisible}
        onCancel={toggleStartActivationModal}
        onOk={startActivation}
      >
        {selectedWhitelistContract ? (
          <div>
            <div style={{ marginBottom: 10 }}>
              <StyledLabel title="Selected Account">Selected Account</StyledLabel>
            </div>
            <div>
              <Select
                style={{ marginBottom: 10, minWidth: '100%' }}
                value={selectedWhitelistContract.contractAddress}
                onChange={handleSelectedContractChange}
                options={contracts.map((contract) => {
                  return {
                    label: `${contract.name} (${chainIdToNetworkLabel(contract.chainId)})`,
                    value: contract.contractAddress
                  };
                })}
              />
            </div>
            <div>
              The whitelist will be assigned to the selected account. A threshold of account owners
              must then submit signatures in order to enable the whitelist for the given account.
            </div>
          </div>
        ) : null}
      </Modal>
      {selectedWhitelist && isWhitelist(selectedWhitelist) ? (
        <Modal
          title="Edit Whitelist"
          okText="Save"
          cancelText="Cancel"
          open={editSelectedWhitelistModalVisible}
          onCancel={toggleEditSelectedWhitelistMetadataModal}
          onOk={handleEditSelectedWhitelist}
        >
          <Form
            form={editSelectedWhitelistForm}
            layout="vertical"
            requiredMark={antdFormConfig.requiredMark}
            size={antdFormConfig.size}
            style={{ width: '100%' }}
          >
            <Form.Item
              label="Name"
              name="displayName"
              rules={[
                {
                  required: true,
                  message: 'Please add a name for this whitelist.'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please add a description for this whitelist.'
                }
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Form>
        </Modal>
      ) : null}
      {selectedWhitelist && isWhitelist(selectedWhitelist) ? (
        <Modal
          title="Edit Address"
          okText="Save"
          cancelText="Cancel"
          open={!!selectedAddress}
          onCancel={closeEditSelectedAddressModal}
          onOk={handleEditSelectedAddressNotes}
        >
          <Form
            form={editSelectedAddressForm}
            layout="vertical"
            requiredMark={antdFormConfig.requiredMark}
            size={antdFormConfig.size}
            style={{ width: '100%' }}
            initialValues={{
              notes: selectedAddress?.notes || ''
            }}
          >
            <Form.Item label="Label & Notes" name="notes">
              <Input.TextArea />
            </Form.Item>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledWhitelistTile = styled.div<{ selected?: boolean }>`
  width: 100%;
  padding: 20px;
  border: 1px solid #ffffff4d;
  border-radius: 8px;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  background-color: ${({ selected }) => {
    if (selected) {
      return colors.whiteTrans;
    } else {
      return 'transparent';
    }
  }};

  :hover {
    border: 1px solid #ffffffe0;
  }
`;

const StyledCreateNewButton = styled.div`
  background-color: #45888a;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
`;

const StyledLabel = styled.label`
  font-weight: bold;
`;

const StyledImage = styled.img`
  width: 60px;
  height: 50px;
  object-fit: contain;
  margin-right: 20px;
`;

export default TradingWhitelistPage;
