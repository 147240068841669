import React, { useMemo } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import { ConfigProvider, List } from 'antd';
import EmptyState from '../components/EmptyState/EmptyState';
import { H2 } from '../styles/shared';
import { useAppSelector } from '../hooks/redux';
import { selectGroupsData } from '../features/groups/groupsService';
import { GroupAccountHistory } from '../features/groups/groupsTypes';

const LogsPage: React.FC = () => {
  const groups = useAppSelector(selectGroupsData);
  const logs = useMemo(() => {
    let result: GroupAccountHistory[] = [];
    groups.forEach((group) => {
      result = [...result, ...(group?.history || [])];
    });
    return result;
  }, [groups]);
  return (
    <>
      <EulithHelmetConsumer>Logs</EulithHelmetConsumer>
      <ConfigProvider renderEmpty={() => <EmptyState description="No logs found" />}>
        <List
          bordered
          header={<H2>Logs</H2>}
          dataSource={logs}
          renderItem={(item) => {
            return (
              <List.Item
                actions={
                  item.timestamp
                    ? [
                        <div key="timestamp">
                          {new Date(item.timestamp).toDateString()}{' '}
                          {new Date(item.timestamp).toLocaleTimeString()}
                        </div>
                      ]
                    : undefined
                }
              >
                <List.Item.Meta title={item.label} description={item.value} />
              </List.Item>
            );
          }}
          pagination={{
            pageSize: 5
          }}
        />
      </ConfigProvider>
    </>
  );
};

export default LogsPage;
