import React, { useCallback, useEffect, useState } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import { H2, Text } from '../styles/shared';
import { useAppSelector } from '../hooks/redux';
import { Button, Row, ConfigProvider, Spin } from 'antd';
import { selectUser } from '../features/auth/authSlice';
import styled from 'styled-components';
import EmptyState from '../components/EmptyState/EmptyState';
import AddAddressBookEntryForm from '../components/AddAddressBookEntryForm';
import { LoadingOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';
import {
  selectAddressBookData,
  useLazyGetAddressBookQuery
} from '../features/addressBook/addressBookService';
import EulithCard from '../components/EulithCard';
import {
  AddressBookEntry,
  AddressTotalBalanceChainList
} from '../features/addressBook/addressBookTypes';
import AddressBookLineItem from '../components/AddressBookLineItem';
import EulithTable from '../components/EulithTable';
import { ColumnsType } from 'antd/es/table';
import AddressDetailModal from '../components/AddressDetailModal';
import { chainIdToNetworkShortName } from '../utils/networks';

interface ContextObject {
  editing: boolean;
  previousValue?: AddressBookEntry;
}

const AddressBook: React.FC = () => {
  const [getAddresses, { isLoading: loading }] = useLazyGetAddressBookQuery();
  const user = useAppSelector(selectUser);
  const addresses = useAppSelector(selectAddressBookData);
  const [context, setContext] = useState<ContextObject | null>(null);
  const [modalVisible, setModalVisible] = useState<AddressBookEntry | null>(null);
  const closeModal = useCallback((e: any) => {
    e?.stopPropagation?.();
    setModalVisible(null);
  }, []);
  const addAddress = useCallback(() => {
    setModalVisible(null);
    setContext({
      editing: false
    });
  }, []);
  const editAddress = useCallback((address: AddressBookEntry) => {
    setModalVisible(null);
    setContext({
      editing: true,
      previousValue: address
    });
  }, []);
  useEffect(() => {
    if (user?.sub) {
      getAddresses(user.sub).unwrap().catch(console.warn);
    }
  }, [getAddresses, user?.sub]);

  const columns: ColumnsType<any> = [
    {
      title: 'Address',
      dataIndex: 'address',
      render: (address: string, record: AddressBookEntry) => {
        return <AddressBookLineItem address={record.address} allowClick={false} />;
      }
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 125,
      render: (type: string) => {
        return (
          <StyledTypeContainer>
            <StyledIcon>{type === 'EOA' ? <UserOutlined /> : <WalletOutlined />}</StyledIcon>
            {type}
          </StyledTypeContainer>
        );
      }
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: 125
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: 175,
      render: (text: number) => {
        const date = new Date(text);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        };
        return date.toLocaleDateString('en-US', options);
      }
    },
    {
      title: 'Balances',
      dataIndex: 'balances',
      width: 175,
      render: (balances: AddressTotalBalanceChainList[], record: AddressBookEntry) => {
        return (
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {(balances || []).map((balance) => {
              return (
                <li key={`balance-${record.address}-${balance.id}`}>{`${balance.usd_value.toFixed(
                  5
                )} ${chainIdToNetworkShortName[balance.community_id].toUpperCase()}`}</li>
              );
            })}
          </ul>
        );
      }
    },
    {
      title: 'Actions',
      width: 100,
      render: (address: any, record: AddressBookEntry) => {
        return (
          <Button
            type="text"
            onClick={(e) => {
              e?.stopPropagation();
              handleEditAddress(record);
            }}
            key="edit-address"
          >
            Edit
          </Button>
        );
      }
    }
  ];

  const openAddModal = useCallback(() => {
    setContext({
      editing: false
    });
  }, []);

  const closeAddModal = useCallback(() => {
    setContext(null);
  }, []);

  const handleEditAddress = useCallback((address: AddressBookEntry) => {
    setContext({
      editing: true,
      previousValue: address
    });
  }, []);

  const handleViewAddress = useCallback((address: AddressBookEntry) => {
    setModalVisible(address);
  }, []);

  const onRow = useCallback(
    (record: any) => {
      return {
        className: 'eulith-table-row',
        onClick: () => {
          handleViewAddress(record);
        }
      };
    },
    [handleViewAddress]
  );

  return (
    <PageContainer>
      <EulithHelmetConsumer>Address Book</EulithHelmetConsumer>
      {loading ? (
        <Row align="middle" justify="center" style={{ height: '100%' }}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Text style={{ paddingBottom: 20, textAlign: 'center' }}>Fetching addresses...</Text>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>
        </Row>
      ) : addresses?.length ? null : (
        <Row align="middle" justify="center" style={{ height: '100%' }}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <H2 style={{ paddingBottom: 20, textAlign: 'center' }}>Create Your Address Book</H2>
            <Text style={{ paddingBottom: 20, textAlign: 'center' }}>
              You have not saves any addresses yet. You can start by saving your first address
              below.
            </Text>
            <Button onClick={openAddModal} type="primary" size="large">
              Create Address
            </Button>
          </div>
        </Row>
      )}
      <EulithCard
        title="Address Book"
        extra={
          <Button type="primary" onClick={openAddModal}>
            Create Address
          </Button>
        }
      >
        <ConfigProvider renderEmpty={() => <EmptyState description="No addresses saved." />}>
          <EulithTable
            dataSource={addresses}
            columns={columns}
            onRow={onRow}
            rowClassName="cursor-pointer"
            rowKey="address"
            scroll={{ x: 1000, y: 600 }}
            pagination={{
              pageSize: 20
            }}
          />
        </ConfigProvider>
      </EulithCard>
      <AddressDetailModal
        modalVisible={!!modalVisible}
        data={modalVisible}
        closeModal={closeModal}
        addAddress={addAddress}
        editAddress={editAddress}
      />
      <AddAddressBookEntryForm
        open={!!context}
        editing={!!context?.editing}
        previousValue={context?.previousValue}
        onCancel={closeAddModal}
      />
    </PageContainer>
  );
};

const PageContainer = styled.div`
  height: 100%;
`;

const StyledIcon = styled.div`
  margin-right: 7px;
`;

const StyledTypeContainer = styled.div`
  padding: 5px 10px;
  display: flex;
  width: fit-content;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
`;

export default AddressBook;
