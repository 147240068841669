import React, { useCallback, useEffect, useState } from 'react';
import { Input, Modal, Form, message, Select } from 'antd';
import { AddressBookEntry } from '../../features/addressBook/addressBookTypes';
import { useAppSelector } from '../../hooks/redux';
import { selectUser } from '../../features/auth/authSlice';
import {
  useAddAddressBookEntryMutation,
  useEditAddressBookEntryMutation,
  useLazyGetAddressBookQuery
} from '../../features/addressBook/addressBookService';
import { addressValidationConfig, antdFormConfig } from '../../styles/shared';
import { networkOptions } from '../../utils/networks';

const { Option } = Select;

const typeOptions = [
  {
    label: 'EOA',
    value: 'EOA'
  },
  {
    label: 'Contract',
    value: 'Contract'
  }
];

const roleOptions = [
  {
    label: 'Owner',
    value: 'Owner'
  },
  {
    label: 'Trader',
    value: 'Trader'
  },
  {
    label: 'Safe Account',
    value: 'Safe Account'
  },
  {
    label: 'Internal',
    value: 'Internal'
  },
  {
    label: 'External',
    value: 'External'
  }
];

const AddressBook: React.FC<{
  editing: boolean;
  open: boolean;
  previousValue?: AddressBookEntry;
  onCancel: () => any;
}> = ({ editing, open, previousValue, onCancel }) => {
  const [form] = Form.useForm();
  const user = useAppSelector(selectUser);
  const [getAddresses] = useLazyGetAddressBookQuery();
  const [addAddress] = useAddAddressBookEntryMutation();
  const [editAddress] = useEditAddressBookEntryMutation();
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(previousValue);
  }, [form, previousValue]);

  const handleCancel = useCallback(
    (e?: any) => {
      e?.stopPropagation?.();
      onCancel();
      form.resetFields();
    },
    [form, onCancel]
  );

  const handleFinish = useCallback(() => {
    const fxn = editing ? editAddress : addAddress;
    form
      .validateFields()
      .then(async (values) => {
        setFormLoading(true);
        const data = editing
          ? {
              payload: values,
              id: previousValue?.id
            }
          : {
              ...values,
              userId: user?.sub || ''
            };
        fxn(data)
          .unwrap()
          .then(() => {
            message.success(`Address successfully ${editing ? 'updated' : 'created'}!`);
            getAddresses(user?.sub || '')
              .unwrap()
              .finally(() => {
                setFormLoading(false);
                handleCancel();
              });
          })
          .catch((error: any) => {
            console.warn(error);
            message.error('Unable to save address.');
            setFormLoading(false);
          });
      })
      .catch(console.warn);
  }, [
    editing,
    editAddress,
    getAddresses,
    addAddress,
    handleCancel,
    form,
    previousValue?.id,
    user?.sub
  ]);

  function modalRender(node: React.ReactNode) {
    return <div onClick={(e) => e.stopPropagation()}>{node}</div>;
  }

  return (
    <Modal
      open={open}
      title={
        <div onClick={(e) => e.stopPropagation()}>{editing ? 'Edit Address' : 'Add Address'}</div>
      }
      modalRender={modalRender}
      okText={editing ? 'Save' : 'Add'}
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleFinish}
      okButtonProps={{
        loading: formLoading,
        htmlType: 'submit'
      }}
      cancelButtonProps={{
        loading: formLoading
      }}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <Form
          form={form}
          layout="vertical"
          name="Address"
          initialValues={previousValue}
          requiredMark={antdFormConfig.requiredMark}
          scrollToFirstError
          onFinish={handleFinish}
          size={antdFormConfig.size}
        >
          <Form.Item
            name="name"
            label="Name"
            labelCol={antdFormConfig.labelCol}
            rules={[{ required: true, message: 'Please enter a name.' }]}
          >
            <Input placeholder="Add address name..." />
          </Form.Item>
          <Form.Item name="description" label="Description" labelCol={antdFormConfig.labelCol}>
            <Input.TextArea placeholder="Add description..." />
          </Form.Item>
          <Form.Item
            name="address"
            label="Address"
            labelCol={antdFormConfig.labelCol}
            style={{ marginBottom: 35 }}
            rules={[
              {
                required: true,
                message: 'Please provide an address for this entry.'
              },
              addressValidationConfig
            ]}
          >
            <Input style={{ fontFamily: 'monospace' }} placeholder="Add address..." />
          </Form.Item>
          <Form.Item
            name="type"
            label="Type"
            labelCol={antdFormConfig.labelCol}
            rules={[
              {
                required: true,
                message: 'Please select a type.'
              }
            ]}
          >
            <Select options={typeOptions} placeholder="Select type..." />
          </Form.Item>
          <Form.Item
            name="chainIds"
            label="Networks"
            labelCol={antdFormConfig.labelCol}
            rules={[
              {
                required: true,
                message: 'Please provide at least one network.'
              }
            ]}
          >
            <Select mode="multiple" placeholder="Select network(s)...">
              {networkOptions.map((option) => {
                return (
                  <Option key={`network-multi-select-${option.value}`} value={option.value}>
                    {option.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="role"
            label="Role"
            labelCol={antdFormConfig.labelCol}
            rules={[
              {
                required: true,
                message: 'Please select a role.'
              }
            ]}
          >
            <Select options={roleOptions} placeholder="Select role..." />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            labelCol={antdFormConfig.labelCol}
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email'
              }
            ]}
          >
            <Input placeholder="Add associated email..." />
          </Form.Item>
          <Form.Item
            name="ens"
            label="ENS"
            labelCol={antdFormConfig.labelCol}
            style={{ marginBottom: 35 }}
          >
            <Input placeholder="Add ENS username..." />
          </Form.Item>
          <Form.Item
            name="profile"
            label="Profile (URL)"
            labelCol={antdFormConfig.labelCol}
            style={{ marginBottom: 35 }}
          >
            <Input placeholder="Add profile url..." />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddressBook;
