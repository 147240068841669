import { CheckCircleFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm, Popover, Space } from 'antd';
import { ArmorOwner } from '../../../../../features/order/orderTypes';
import { colors } from '../../../../../styles/shared';
import styled from 'styled-components';
import AddressBookLineItem from '../../../../AddressBookLineItem';

interface ArmorOwnerListItemProps {
  data: ArmorOwner;
  editOwner?: (owner: ArmorOwner) => void;
  deleteOwner?: (owner: ArmorOwner) => void;
}

function ArmorOwnerListItem({ data, editOwner, deleteOwner }: ArmorOwnerListItemProps) {
  function stopPropagation(e: any) {
    e?.stopPropagation?.();
  }

  return (
    <StyledPanel>
      <AddressBookLineItem address={data.address} width="95%" />
      <Space>
        {!data.authorized && editOwner ? (
          <EditOutlined
            onClick={(e: any) => {
              e?.stopPropagation?.();
              editOwner(data);
            }}
          />
        ) : null}
        {!data.authorized && deleteOwner ? (
          <Popconfirm
            title="Delete this owner?"
            description="Owner will be removed from this Armor instance."
            onConfirm={(e: any) => {
              e?.stopPropagation?.();
              deleteOwner(data);
            }}
            okText="Delete"
            okButtonProps={{
              danger: true
            }}
          >
            <DeleteOutlined style={{ color: '#DC4546' }} onClick={stopPropagation} />
          </Popconfirm>
        ) : null}
        {data.authorized ? (
          <Popover title="This owner has already authorized.">
            <CheckCircleFilled style={{ color: colors.success }} />
          </Popover>
        ) : null}
      </Space>
    </StyledPanel>
  );
}

const StyledPanel = styled.div`
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid #424242;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  line-height: 1.5714285714285714;
  padding: 8px 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export default ArmorOwnerListItem;
