import React from 'react';
import { StyledItalic } from '../../styles/shared';
import MonoSpaceSpan from '../MonoSpaceSans';
import { chainIdToNetworkLabel, chainIdToScannerUrl } from '../../utils/networks';
import { Modal, Space, Button, message, Dropdown, MenuProps } from 'antd';
import { AddressBookEntry } from '../../features/addressBook/addressBookTypes';
import {
  UserOutlined,
  WalletOutlined,
  CopyOutlined,
  ExportOutlined,
  PlusCircleOutlined,
  DownOutlined
} from '@ant-design/icons';
import styled from 'styled-components';

function getScannerInfo(chainId?: number, address?: string) {
  let name = '';
  let url = '';
  if (!chainId || !address) {
    return null;
  }
  if (chainId && chainIdToScannerUrl[chainId]) {
    url = `${chainIdToScannerUrl[chainId].url}/tx/${address}`;
    name = chainIdToScannerUrl[chainId].name;
  } else {
    url = `${chainIdToScannerUrl[1].url}/tx/${address}`;
    name = chainIdToScannerUrl[1].name;
  }
  return {
    url,
    name
  };
}

function AddressDetailModal({
  modalVisible = false,
  closeModal,
  data,
  addAddress,
  editAddress
}: {
  modalVisible?: boolean;
  closeModal?: (e: any) => void;
  data: AddressBookEntry | null;
  addAddress?: () => void;
  editAddress?: (address: AddressBookEntry) => void;
}) {
  function handleEditAddress() {
    if (data && editAddress) {
      editAddress(data);
    }
  }

  function copyAddress(e: any) {
    e.stopPropagation();
    if (data?.address) {
      navigator.clipboard.writeText(data.address);
      message.success('Copied to clipboard!');
    }
  }

  const NOT_PROVIDED_TEXT = data?.name ? 'Not Provided' : 'Unknown';
  const lastUpdatedDate =
    data?.updatedAt || data?.createdAt ? new Date(data.updatedAt || data.createdAt) : null;
  const scanners = (data?.chainIds || []).map((chain) => getScannerInfo(chain, data?.address));

  function handleMenuClick(e: any) {
    if (e?.key) {
      window.open(e.key, '_blank');
    }
  }

  const items: MenuProps['items'] = scanners.map((scanner) => {
    return {
      label: scanner?.name || '',
      key: scanner?.url || ''
    };
  });

  const menuProps = {
    items,
    onClick: handleMenuClick
  };

  function modalRender(node: React.ReactNode) {
    return <div onClick={(e) => e.stopPropagation()}>{node}</div>;
  }

  return (
    <Modal
      open={modalVisible}
      modalRender={modalRender}
      title={<div onClick={(e) => e.stopPropagation()}>Address Details</div>}
      footer={null}
      onCancel={closeModal}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <StyledRow>
          <StyledLabel>Name</StyledLabel>
          {data?.name ? (
            <div>
              <StyledValue>{data?.name}</StyledValue>
              <StyledLastUpdatedText>
                Edited by: {data?.userId}
                {lastUpdatedDate
                  ? ` at ${lastUpdatedDate.toLocaleDateString()} ${lastUpdatedDate.toLocaleTimeString()}`
                  : ''}
              </StyledLastUpdatedText>
            </div>
          ) : (
            <StyledItalic style={{ padding: 0, margin: 0 }}>{NOT_PROVIDED_TEXT}</StyledItalic>
          )}
        </StyledRow>
        <StyledRow>
          <StyledLabel>Description</StyledLabel>
          {data?.description ? (
            <StyledValue>{data.description}</StyledValue>
          ) : (
            <StyledItalic style={{ padding: 0, margin: 0 }}>{NOT_PROVIDED_TEXT}</StyledItalic>
          )}
        </StyledRow>
        <StyledRow>
          <StyledLabel>Address</StyledLabel>
          <StyledValue>
            <MonoSpaceSpan>{data?.address}</MonoSpaceSpan>
          </StyledValue>
        </StyledRow>
        <StyledRow>
          <StyledLabel>Network(s)</StyledLabel>
          {data?.chainIds?.length ? (
            <Space wrap>
              {data.chainIds.map((chain) => {
                return (
                  <StyledTypeContainer key={`${data?.address}-networks-${chain}`}>
                    <strong>{chainIdToNetworkLabel(chain)}</strong>
                  </StyledTypeContainer>
                );
              })}
            </Space>
          ) : (
            <StyledItalic style={{ padding: 0, margin: 0 }}>{NOT_PROVIDED_TEXT}</StyledItalic>
          )}
        </StyledRow>
        <StyledRow>
          <StyledLabel>Email</StyledLabel>
          {data?.email ? (
            <StyledValue>
              <a href={`mailto:${data.email}`}>{data.email}</a>
            </StyledValue>
          ) : (
            <StyledItalic style={{ padding: 0, margin: 0 }}>{NOT_PROVIDED_TEXT}</StyledItalic>
          )}
        </StyledRow>
        <StyledRow>
          <StyledLabel>Type</StyledLabel>
          {data?.type ? (
            <StyledTypeContainer>
              <StyledIcon>
                {data?.type === 'EOA' ? <UserOutlined /> : <WalletOutlined />}
              </StyledIcon>
              <StyledValue>{data?.type}</StyledValue>
            </StyledTypeContainer>
          ) : (
            <StyledItalic style={{ padding: 0, margin: 0 }}>{NOT_PROVIDED_TEXT}</StyledItalic>
          )}
        </StyledRow>
        <StyledRow>
          <StyledLabel>ENS</StyledLabel>
          {data?.ens ? (
            <StyledValue>{data.ens}</StyledValue>
          ) : (
            <StyledItalic style={{ padding: 0, margin: 0 }}>{NOT_PROVIDED_TEXT}</StyledItalic>
          )}
        </StyledRow>
        <StyledRow>
          <StyledLabel>Role</StyledLabel>
          {data?.role ? (
            <StyledValue>{data?.role}</StyledValue>
          ) : (
            <StyledItalic style={{ padding: 0, margin: 0 }}>{NOT_PROVIDED_TEXT}</StyledItalic>
          )}
        </StyledRow>
        <StyledRow>
          <StyledLabel>Profile (URL)</StyledLabel>
          {data?.profile ? (
            <StyledValue>{data?.profile}</StyledValue>
          ) : (
            <StyledItalic style={{ padding: 0, margin: 0 }}>{NOT_PROVIDED_TEXT}</StyledItalic>
          )}
        </StyledRow>
        <Space direction="vertical" size="middle" style={{ marginTop: 20, width: '100%' }}>
          {scanners?.length ? (
            <Button block ghost type="primary" onClick={handleEditAddress}>
              Edit Address
            </Button>
          ) : null}
          <Button block type="primary" onClick={copyAddress}>
            Copy Address
            <CopyOutlined />
          </Button>
          {scanners?.length ? (
            scanners?.length === 1 ? (
              <Button block type="primary" href={scanners[0]?.url} target="_blank">
                View on {scanners[0]?.name}
                <ExportOutlined />
              </Button>
            ) : (
              <Dropdown menu={menuProps}>
                <Button type="primary" block>
                  <Space>
                    View On
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            )
          ) : (
            <Button block type="primary" onClick={addAddress}>
              Add to Address Book
              <PlusCircleOutlined />
            </Button>
          )}
        </Space>
      </div>
    </Modal>
  );
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`;

const StyledLabel = styled.label`
  margin-right: 10px;
  min-width: 85px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledLastUpdatedText = styled.div`
  opacity: 0.7;
`;

const StyledValue = styled.span`
  font-weight: bold;
`;

const StyledTypeContainer = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
`;

const StyledIcon = styled.div`
  margin-right: 7px;
`;

export default AddressDetailModal;
