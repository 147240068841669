import { Button, Form, Input, Modal, Select, Space, message } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { antdFormConfig } from '../../styles/shared';
import { useAppSelector } from '../../hooks/redux';
import { selectUser } from '../../features/auth/authSlice';
import { useAddGroupMutation, useEditGroupMutation } from '../../features/groups/groupsService';
import { selectContractsData } from '../../features/eulith/eulithService';
import { GroupAccount } from '../../features/groups/groupsTypes';
import { triggerGetBalanceHistoryJob } from '../../features/firebase';

function CreatePortfolioForm({
  open,
  close,
  initialValues
}: {
  open: boolean;
  close: () => any;
  initialValues?: GroupAccount | null;
}) {
  const [form] = Form.useForm();
  const user = useAppSelector(selectUser);
  const contracts = useAppSelector(selectContractsData);
  const [loading, setLoading] = useState(false);
  const [addGroup] = useAddGroupMutation();
  const [editGroup] = useEditGroupMutation();
  const handleCancel = useCallback(() => {
    form.resetFields();
    close();
  }, [form, close]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  function onFinish() {
    const fxn = initialValues ? editGroup : addGroup;
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        const group = initialValues
          ? {
              payload: values,
              id: initialValues?.id,
              updatedAt: Date.now()
            }
          : {
              ...values,
              userId: user?.sub || '',
              createdAt: Date.now(),
              updatedAt: Date.now()
            };
        fxn(group)
          .unwrap()
          .then(() => {
            triggerGetBalanceHistoryJob();
            message.success(`Portfolio successfully ${initialValues ? 'updated' : 'created'}!`);
            handleCancel();
          })
          .catch((error) => {
            console.warn(error);
            message.error('Unable to create portfolio.');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(console.warn);
  }

  const contractOptions = useMemo(() => {
    return contracts.map((contract) => {
      return {
        label: contract.name,
        value: contract.safeAddress
      };
    });
  }, [contracts]);

  return (
    <Modal
      open={!!open}
      title={initialValues ? 'Edit Portfolio' : 'Create Portfolio'}
      okText={initialValues ? 'Save' : 'Create'}
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={onFinish}
      okButtonProps={{
        loading,
        htmlType: 'submit'
      }}
      cancelButtonProps={{
        loading
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="Portfolio"
        initialValues={{
          denomination: 'USD'
        }}
        requiredMark={antdFormConfig.requiredMark}
        scrollToFirstError
        onFinish={onFinish}
        size={antdFormConfig.size}
      >
        <Form.Item
          name="name"
          label="Name"
          labelCol={antdFormConfig.labelCol}
          rules={[{ required: true, message: 'Please enter a name.' }]}
        >
          <Input placeholder="Add portfolio name..." />
        </Form.Item>
        <Form.Item
          name="accounts"
          label="Accounts"
          labelCol={antdFormConfig.labelCol}
          rules={[
            {
              required: true,
              message:
                'Please select at least one account. Ensure your wallet is connected to view all available accounts.'
            }
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            options={contractOptions}
            placeholder="Select accounts..."
          />
        </Form.Item>
        <Form.Item name="denomination" hidden>
          <Input />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldValue }) => {
            const currentValue = getFieldValue('denomination') || 'USD';
            return (
              <div>
                <div style={{ marginBottom: 15 }}>
                  <label title="Denomination">Denomination</label>
                </div>
                <Space.Compact>
                  <Button
                    type={currentValue === 'USD' ? 'primary' : 'default'}
                    onClick={() => {
                      setFieldValue('denomination', 'USD');
                    }}
                  >
                    USD
                  </Button>
                  <Button
                    type={currentValue === 'ETH' ? 'primary' : 'default'}
                    onClick={() => {
                      setFieldValue('denomination', 'ETH');
                    }}
                  >
                    ETH
                  </Button>
                  <Button
                    type={currentValue === 'BTC' ? 'primary' : 'default'}
                    onClick={() => {
                      setFieldValue('denomination', 'BTC');
                    }}
                  >
                    BTC
                  </Button>
                </Space.Compact>
              </div>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreatePortfolioForm;
