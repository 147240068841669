import { Col, ColProps, Row, Space } from 'antd';
import React from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import styled from 'styled-components';
import EulithCard from '../components/EulithCard';
import { H1, colors } from '../styles/shared';
import { useConnectionStatus, useSetIsWalletModalOpen } from '@thirdweb-dev/react';
import { CheckCircleFilled, MailFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux';
import {
  selectCanViewAllFunds,
  selectHasArmorAccess,
  selectHasDocsAccess,
  selectHasTradingAccess,
  selectHasWalletAccess,
  selectUser
} from '../features/auth/authSlice';
import openImage from '../assets/images/open.svg';
import metamaskLogo from '../assets/images/metamask-logo.png';
import { selectContractsData } from '../features/eulith/eulithService';
import BugsnagManager from '../BugsnagManager';
import { store } from '../store';
import { ARMOR_TABS } from '../components/Layouts/shared';

function getUserGreeting(username: string): string {
  const currentHour = new Date().getHours();
  if (currentHour < 12) {
    return 'Good morning, ' + username + '!';
  } else if (currentHour < 18) {
    return 'Good afternoon, ' + username + '!';
  } else {
    return 'Good evening, ' + username + '!';
  }
}

const LegacyHomePage: React.FC = () => {
  const user = useAppSelector(selectUser);
  const contracts = useAppSelector(selectContractsData);
  const connectionStatus = useConnectionStatus();
  const navigate = useNavigate();
  const setIsWalletModalOpen = useSetIsWalletModalOpen();
  const canManageFunds = useAppSelector(selectCanViewAllFunds);
  const canUseArmor = useAppSelector(selectHasArmorAccess);
  const canViewDocs = useAppSelector(selectHasDocsAccess);
  const canTrade = useAppSelector(selectHasTradingAccess);
  const canUseWallet = useAppSelector(selectHasWalletAccess);

  // function openIntercom() {
  //   if (document) {
  //     const element = document.querySelector('.intercom-lightweight-app-launcher') as HTMLElement;
  //     if (element?.click) {
  //       element.click();
  //     }
  //   }
  // }

  const CARDS = [
    {
      title: 'Connect Wallet',
      completed: connectionStatus === 'connected',
      showIcon: true,
      onClick: () => setIsWalletModalOpen(true),
      description:
        'Connect your wallet of choice such as MetaMask, Coinbase Wallet, or WalletConnect.'
    },
    {
      title: 'MetaMask Snaps',
      showIcon: true,
      icon: <StyledMetaMaskImage src={metamaskLogo} />,
      onClick: () => navigate('/metamask'),
      description: 'Customize your Eulith experience by integrating with MetaMask Snap.'
    },
    {
      title: 'How To Guide',
      showIcon: true,
      icon: <StyledOpenImage src={openImage} />,
      onClick: () => window.open('/resources/docs/how-to/', '_blank'),
      description:
        'View our guide on popular use cases of the Eulith platform and various services.'
    },
    canUseArmor
      ? {
          title: contracts?.length ? 'Manage Armor' : 'Deploy Armor',
          showIcon: !contracts?.length,
          completed: false,
          onClick: () => navigate(contracts?.length ? '/armor/accounts' : ARMOR_TABS.OVERVIEW),
          description: 'Armor instances require a co-signature for all of your Eulith transactions.'
        }
      : null,
    canUseWallet
      ? {
          title: 'Use Eulith Wallet',
          showIcon: false,
          completed: false,
          onClick: () => navigate('/wallet'),
          description: 'Securely execute transactions to any dapp using the Eulith wallet.'
        }
      : null,
    canManageFunds
      ? {
          title: 'Manage Funds',
          showIcon: false,
          completed: false,
          onClick: () => navigate('/funds/manage'),
          description: 'Funds created on our platform are made available for sharing and trading.'
        }
      : null,
    canViewDocs
      ? {
          title: 'View Client Docs',
          icon: <StyledOpenImage src={openImage} />,
          showIcon: true,
          onClick: () => window.open('/resources/', '_blank'),
          description: 'Learn how your own application can interface with Eulith services.'
        }
      : null,
    canTrade
      ? {
          title: 'View Trading Platform',
          showIcon: false,
          completed: false,
          onClick: () => navigate('/trade'),
          description: 'Safely execute trades through your armor instance.'
        }
      : null,
    {
      title: 'View Examples',
      icon: <StyledOpenImage src={openImage} />,
      showIcon: true,
      onClick: () =>
        window.open(
          'https://github.com/Eulith/eulith-feature-test-scripts/tree/master/examples',
          '_blank'
        ),
      description:
        'Our GitHub repository has examples of Eulith integrations in a variety of contexts.'
    },
    {
      title: 'Contact Us',
      showIcon: true,
      onClick: () => {
        window.open('https://t.me/luca590', '_blank');
        BugsnagManager.notify(new Error('User Error Report'), {
          context: 'User Error Report',
          metadata: {
            userstate: JSON.stringify(store.getState())
          }
        });
      },
      icon: <MailFilled />,
      description: "We're always here if you need any help with the platform or integrations."
    }
  ].filter((x) => x);

  return (
    <>
      <EulithHelmetConsumer>Home</EulithHelmetConsumer>
      <PageContainer>
        <StyledImage src={require('../assets/images/logo_solid_white.png')} />
        <H1 style={{ fontSize: 26, marginBottom: 20, textAlign: 'center' }}>
          {user?.sub ? getUserGreeting(user.sub) : 'Welcome'}
        </H1>
        <Space size="large">
          <Row gutter={[16, 16]} align="top" justify="start">
            {CARDS.map((card) => {
              if (!card) {
                return null;
              }
              return (
                <StyledCol
                  onClick={card.completed ? undefined : card.onClick}
                  xs={12}
                  md={8}
                  disabled={!!card.completed}
                  key={card.title}
                >
                  <EulithCard
                    isDisabled={!!card.completed}
                    fullHeight
                    smallHeader
                    title={card.title}
                    style={{ height: '100%', minHeight: 165 }}
                    bodyStyle={{ height: '100%', padding: 20 }}
                    extra={
                      card.showIcon
                        ? card.icon ||
                          (card.completed ? (
                            <CheckCircleFilled style={{ color: colors.success }} />
                          ) : null)
                        : null
                    }
                  >
                    {card.description}
                  </EulithCard>
                </StyledCol>
              );
            })}
          </Row>
        </Space>
      </PageContainer>
    </>
  );
};

const PageContainer = styled.div`
  margin: auto;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledCol = styled(Col)<ColProps & { disabled?: boolean }>`
  ${({ disabled }) => {
    if (disabled) {
      return ``;
    } else {
      return `
        cursor: pointer;
        transition: all 0.3s ease;
        :hover {
          transform: scale(106%);
        }
      `;
    }
  }}
  // height: 100px;
  user-select: none;
  scale(100%);
`;

const StyledOpenImage = styled.img`
  transform: translateY(2px);
  margin-left: 5px;
`;

const StyledImage = styled.img`
  width: 300px;
  height: 150px;
  object-fit: contain;
`;

const StyledMetaMaskImage = styled.img`
  width: 16px;
  height: 16px;
`;

export default LegacyHomePage;
