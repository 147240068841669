import MonoSpaceSpan from '../MonoSpaceSans';
import MiddleEllipses from '../MiddleEllipses';
import { useCallback, useMemo, useState } from 'react';
import { Button, message } from 'antd';
import styled from 'styled-components';
import { CopyOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';
import { colors } from '../../styles/shared';
import { useAppSelector } from '../../hooks/redux';
import AddAddressBookEntryForm from '../AddAddressBookEntryForm';
import { selectAddressBookData } from '../../features/addressBook/addressBookService';
import { AddressBookEntry } from '../../features/addressBook/addressBookTypes';
import AddressDetailModal from '../AddressDetailModal';

interface ContextObject {
  editing: boolean;
  previousValue?: AddressBookEntry;
}

function AddressBookLineItem({
  address,
  width,
  showAddress = false,
  showAddressSubtitle = false,
  allowCopy = true,
  allowClick = true
}: {
  address: string;
  width?: string;
  showAddress?: boolean;
  showAddressSubtitle?: boolean;
  allowCopy?: boolean;
  allowClick?: boolean;
}) {
  const addresses = useAppSelector(selectAddressBookData);
  const [context, setContext] = useState<ContextObject | null>(null);
  const data = useMemo(() => {
    const result = addresses.find((a) => {
      return a.address === address;
    });
    return (result || { address }) as AddressBookEntry;
  }, [addresses, address]);
  const closeAddAddressModalForm = useCallback(() => {
    setContext(null);
  }, []);
  const [modalVisible, setModalVisible] = useState(false);
  const openModal = useCallback((e: any) => {
    e?.stopPropagation?.();
    setModalVisible(true);
  }, []);
  const closeModal = useCallback((e: any) => {
    e?.stopPropagation?.();
    setModalVisible(false);
  }, []);
  const addAddress = useCallback(() => {
    setModalVisible(false);
    setContext({
      editing: false
    });
  }, []);
  const editAddress = useCallback(() => {
    setModalVisible(false);
    setContext({
      editing: true
    });
  }, []);

  function copyAddress(e: any) {
    e.stopPropagation();
    if (data?.address) {
      navigator.clipboard.writeText(data.address);
      message.success('Copied address to clipboard!');
    }
  }

  return (
    <>
      <MiddleEllipses
        onClick={allowClick ? openModal : undefined}
        width={width}
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {data?.name && !showAddress ? (
          <div>
            <StyledNameContainer>
              <StyledIcon>
                {data?.type === 'EOA' ? <UserOutlined /> : <WalletOutlined />}
              </StyledIcon>
              <StyledName>
                {data?.name}
                {allowCopy ? (
                  <StyledCopyButton
                    onClick={copyAddress}
                    shape="circle"
                    type="text"
                    style={{
                      backgroundColor: 'transparent',
                      color: colors.placeholder,
                      marginLeft: 5
                    }}
                  >
                    <CopyOutlined style={{ color: colors.grey3 }} />
                  </StyledCopyButton>
                ) : null}
              </StyledName>
            </StyledNameContainer>
            {showAddressSubtitle ? (
              <MonoSpaceSpan style={{ cursor: 'pointer', color: colors.grey4, fontSize: 14 }}>
                {data?.address}
              </MonoSpaceSpan>
            ) : null}
          </div>
        ) : (
          <MonoSpaceSpan style={{ cursor: 'pointer' }}>{data?.address}</MonoSpaceSpan>
        )}
        {data?.name ? null : data?.address ? (
          allowCopy ? (
            <StyledCopyButton
              onClick={copyAddress}
              shape="circle"
              type="text"
              style={{ backgroundColor: 'transparent', color: colors.placeholder, marginLeft: 5 }}
            >
              <CopyOutlined style={{ color: colors.grey3 }} />
            </StyledCopyButton>
          ) : null
        ) : null}
      </MiddleEllipses>
      <AddressDetailModal
        modalVisible={modalVisible}
        data={data}
        closeModal={closeModal}
        addAddress={addAddress}
        editAddress={editAddress}
      />
      <AddAddressBookEntryForm
        open={!!context}
        editing={!!context?.editing}
        previousValue={data}
        onCancel={closeAddAddressModalForm}
      />
    </>
  );
}

const StyledName = styled.div`
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
`;

const StyledCopyButton = styled(Button)`
  > * {
    > * {
      transition: all 0.4s ease;
    }
  }

  :hover {
    > * {
      > * {
        color: ${colors.primary};
      }
    }
  }
`;

const StyledNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.div`
  margin-right: 7px;
`;

export default AddressBookLineItem;
