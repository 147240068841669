import {
  BaseQueryFn,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import {
  AddressBookEntry,
  UpdateAddressBookEntryRequest,
  ADDRESS_BOOK_DB_NAME,
  AddressTotalBalanceChainList
} from './addressBookTypes';
import BugsnagManager from '../../BugsnagManager';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
import { db } from '../firebase';
import axios from 'axios';

export const getAddressBookQuery: BaseQueryFn<
  string,
  AddressBookEntry[],
  FetchBaseQueryError
> = async (userId) => {
  try {
    const results: AddressBookEntry[] = [];
    const q = query(collection(db, ADDRESS_BOOK_DB_NAME), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data() as AddressBookEntry;
      results.push({
        ...data,
        id: doc.id
      });
    });
    const promises = results.map(async (address) => {
      const url = `https://pro-openapi.debank.com/v1/user/total_balance?id=${address.address}`;
      return await axios.get(url, {
        headers: {
          Accept: 'application/json',
          AccessKey: '2ac63a3e2e144b05278fc48bf246d74cb9a0ca6f'
        }
      });
    });
    const balanceResults = await Promise.all(promises);
    balanceResults.forEach((balanceResult, index) => {
      if (results[index]) {
        results[index].balances = (balanceResult.data?.chain_list || []).filter(
          (chain: AddressTotalBalanceChainList) => {
            return (results[index].chainIds || []).includes(chain.community_id);
          }
        );
      }
    });
    return { data: results };
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to fetch address book in query'
    });
    console.warn('Unable to fetch address book', error);
    return { error: error as FetchBaseQueryError };
  }
};

export const addAddressBookEntryQuery: BaseQueryFn<
  AddressBookEntry,
  AddressBookEntry,
  FetchBaseQueryError
> = async (payload) => {
  try {
    const address = JSON.parse(
      JSON.stringify({
        ...payload,
        createdAt: Date.now()
      })
    );
    const docRef = await addDoc(collection(db, ADDRESS_BOOK_DB_NAME), address);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const newEntry: AddressBookEntry = { id: docRef.id, ...docSnap.data() } as AddressBookEntry;
      return { data: newEntry };
    } else {
      throw new Error('Address book entry does not exist after being created');
    }
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to add address book entry in query'
    });
    console.warn('Unable to add address book entry', error);
    return { error: error as FetchBaseQueryError };
  }
};

export const editAddressBookEntryQuery: BaseQueryFn<
  UpdateAddressBookEntryRequest,
  AddressBookEntry,
  FetchBaseQueryError
> = async ({ id, payload }) => {
  try {
    if (id) {
      const docRef = doc(db, ADDRESS_BOOK_DB_NAME, id);
      const originalDocRef = await getDoc(docRef);
      const originalData = originalDocRef.data();
      if (originalData) {
        await updateDoc(
          docRef,
          JSON.parse(
            JSON.stringify({
              ...originalData,
              ...payload,
              updatedAt: Date.now()
            })
          )
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const newAddressBookEntry: AddressBookEntry = {
            id: docRef.id,
            ...docSnap.data()
          } as AddressBookEntry;
          return { data: newAddressBookEntry };
        } else {
          throw new Error('Address book entry does not exist after being updated');
        }
      } else {
        throw new Error('Address book entry does not exist before being updated');
      }
    } else {
      throw new Error('ID not supplied to edit address book entry query');
    }
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to edit address book entry in query'
    });
    console.warn('Unable to edit address book entry', error);
    return { error: error as FetchBaseQueryError };
  }
};

export const addressBookApi = createApi({
  reducerPath: 'addressBookApi',
  tagTypes: ['AddressBook'],
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    getAddressBook: builder.query<AddressBookEntry[], string>({
      queryFn: getAddressBookQuery,
      providesTags: ['AddressBook']
    }),
    addAddressBookEntry: builder.mutation<AddressBookEntry, AddressBookEntry>({
      queryFn: addAddressBookEntryQuery,
      invalidatesTags: ['AddressBook']
    }),
    editAddressBookEntry: builder.mutation<AddressBookEntry, UpdateAddressBookEntryRequest>({
      queryFn: editAddressBookEntryQuery,
      invalidatesTags: ['AddressBook']
    })
  })
});

export const {
  useAddAddressBookEntryMutation,
  useEditAddressBookEntryMutation,
  useGetAddressBookQuery,
  useLazyGetAddressBookQuery
} = addressBookApi;

export const selectAddressBookData = createSelector(
  (state: RootState) => {
    const getAddressBookResponse = addressBookApi.endpoints.getAddressBook.select(
      state.auth.user?.sub || ''
    )(state);
    return (getAddressBookResponse.data || []) as AddressBookEntry[];
  },
  (result) => result
);
