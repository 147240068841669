import { ExclamationCircleFilled, ExportOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Alert, Form, Image, Input, Modal, Popover, Row, message } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import EulithCard from '../components/EulithCard';
import EulithTable from '../components/EulithTable';
import {
  selectContractsData,
  selectPingData,
  useEditContractMutation
} from '../features/eulith/eulithService';
import { useAppSelector } from '../hooks/redux';
import { antdFormConfig, colors } from '../styles/shared';
import EmptyState from '../components/EmptyState/EmptyState';
import { AccountTableContract, DecoratedContract } from '../features/eulith/eulithTypes';
import { calculateChainIconUrl, getAllChains } from '../utils/networks';
import { ARMOR_TABS } from '../components/Layouts/shared';
import BugsnagManager from '../BugsnagManager';
import * as Eulith from 'eulith-web3js';
import TradingAccountRow from '../components/TradingAccountRow';
import { useConnectionStatus } from '@thirdweb-dev/react';
import { dollarFormatter } from '../utils/data';
import AddressBookLineItem from '../components/AddressBookLineItem';
import { groupBy } from 'lodash';
import { chainIdToTokenSymbol } from '../utils/tokens';

function combineAccountsBySafeAddress(accounts: AccountTableContract[]) {
  const results: AccountTableContract[] = [];
  const groups = groupBy(accounts, 'safeAddress');
  for (const key in groups) {
    const group = groups[key];
    results.push({
      ...group[0],
      subContracts: group
    });
  }
  return results;
}

const columns = [
  {
    title: 'Safe',
    dataIndex: 'safeAddress',
    render: (text: string, account: any) => {
      return (
        <StyledAccountNameContainer>
          <AddressBookLineItem address={text} showAddressSubtitle />
          {account?.isEnabled ? null : (
            <Popover
              content={
                <div>
                  <div>This account is not yet enabled.</div>
                  <div>Click to finish deployment.</div>
                </div>
              }
            >
              <ExclamationCircleFilled style={{ fontSize: 14, marginLeft: 5, color: '#F39292' }} />
            </Popover>
          )}
        </StyledAccountNameContainer>
      );
    }
  },
  {
    title: 'Network',
    dataIndex: 'chainId',
    width: '15%',
    render: (chainId: number, record: any) => {
      let logo = null;
      let chainWords: any = '?';
      const chain = getAllChains().find((chain) => chain.chainId === chainId);
      if (!chain) {
        console.warn('Could not find a chain for record', record);
      }
      if (chain) {
        logo = calculateChainIconUrl(chain.icon?.url || '', chainId);
        chainWords = chain.name
          .split(' ')
          .filter((x) => x)
          .map((word) => {
            return (
              <div
                key={`split_network_name_${record.contractAddress}_${word}`}
                style={{ fontSize: 13 }}
              >
                {word}
              </div>
            );
          });
      }
      return (
        <Row align="middle" style={{ minWidth: 150 }}>
          {logo ? (
            <Image src={logo} width={30} height={30} preview={false} />
          ) : (
            <QuestionCircleOutlined style={{ fontSize: 30, color: 'rgba(255,255,255,0.3)' }} />
          )}
          <div style={{ marginLeft: 10 }}>{chainWords || chainId}</div>
        </Row>
      );
    }
  },
  {
    title: 'Balance',
    width: '10%',
    render: (t: string, record: any) =>
      record?.isEnabled && record?.safeAddress ? (
        <a
          style={{ color: colors.white }}
          onClick={(e) => {
            e.stopPropagation();
            window.open(`https://debank.com/profile/${record.safeAddress}`, '_blank');
          }}
        >
          <div style={{ minWidth: 150 }}>
            {record?.balance ? dollarFormatter(record.balance) : '$0.00'}
            <ExportOutlined
              style={{ marginLeft: 10, fontSize: 14, cursor: 'pointer', color: colors.grey1 }}
            />
          </div>
        </a>
      ) : (
        '-'
      )
  }
];

const TradingAccountsPage: React.FC = () => {
  const navigate = useNavigate();
  const pingData = useAppSelector(selectPingData);
  const contracts = useAppSelector(selectContractsData);
  const [editForm] = Form.useForm();
  const [editingContract, setEditingContract] = useState<DecoratedContract | null>(null);
  const [editContract, { isLoading }] = useEditContractMutation();
  const connectionStatus = useConnectionStatus();

  const dataSource: AccountTableContract[] = combineAccountsBySafeAddress(
    contracts
      .filter((x) => x)
      .sort(function (a, b) {
        const textA = a.name?.toUpperCase();
        const textB = b.name?.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map((contract: DecoratedContract, index: number) => {
        const contractPingData = pingData[contract.tradingKeyAddress?.toLowerCase?.() || ''];
        return {
          key: contract.contractAddress,
          safeAddress: contract.safeAddress,
          deployedContractId: contract.deployedContractId,
          name: contract.name || `Untitled Account ${index + 1}`,
          description: contract.description,
          tradingKeyAddress: contract.tradingKeyAddress,
          contractAddress: contract.contractAddress,
          hasAce: contract.hasAce,
          isEnabled: contract.isEnabled,
          chainId: contract.chainId,
          tradingKeyAddressBalance: contract.isEnabled
            ? `${contract.tradingKeyAddressBalance || '0'} ${
                chainIdToTokenSymbol[contract.chainId] || ''
              }`
            : '',
          balance: contract.isEnabled ? (contract.balance || 0).toFixed(2) : 0,
          wallet: contractPingData?.ui_address || '',
          aceStatus: contract.isEnabled ? !!contractPingData : null,
          whitelistStatus: contract.isEnabled ? (contract.whitelist ? 'Active' : 'Inactive') : null,
          whitelistId: contract.whitelistId
        };
      })
  );

  const handleEditContract = useCallback(
    (contractAddress: string) => {
      const contract = contracts.find((contract) => {
        return contract.contractAddress === contractAddress;
      });
      if (contract) {
        editForm.setFieldsValue({
          name: contract.name,
          description: contract.description
        });
        setEditingContract(contract);
      }
    },
    [contracts, editForm]
  );

  const onRow = useCallback(
    (record: any) => {
      return {
        className: 'eulith-table-row',
        onClick: () => {
          if (!record?.isEnabled) {
            navigate(
              `${ARMOR_TABS.ADD_OWNERS}?id=${Eulith.Web3.utils.toChecksumAddress(
                record.contractAddress
              )}`
            );
          }
        }
      };
    },
    [navigate]
  );

  const handleContractEdit = useCallback(
    (contract: DecoratedContract) => {
      if (editingContract) {
        editContract({
          deployedContractId: editingContract.deployedContractId,
          name: contract.name,
          description: contract.description
        })
          .then(() => {
            editForm.resetFields();
            setEditingContract(null);
            message.success('Account successfully edited!');
          })
          .catch((error: any) => {
            BugsnagManager.notify(error, {
              context: 'Unable to edit account in trading accounts page',
              metadata: {
                contract,
                editingContract
              }
            });
            message.error(error?.message || 'Unable to edit account.');
          });
      }
    },
    [editContract, editingContract, editForm]
  );

  return useMemo(
    () => (
      <>
        <EulithHelmetConsumer>Trading Accounts</EulithHelmetConsumer>
        <EulithCard
          title="Trading Accounts"
          fullHeight
          bodyStyle={{ height: 'calc(100% - 80px)', padding: 10, overflow: 'auto' }}
          style={{ height: '100%', overflow: 'hidden' }}
        >
          {connectionStatus === 'connected' ? (
            dataSource?.length ? (
              <EulithTable
                rowKey={(record: any) => {
                  return `main-${record.safeAddress}`;
                }}
                scroll={{ x: 600 }}
                dataSource={dataSource}
                columns={columns}
                onRow={onRow}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (
                    record: any,
                    index: number,
                    indent: number,
                    expanded: boolean
                  ) => {
                    return (
                      <TradingAccountRow
                        data={record}
                        expanded={expanded}
                        handleEditContract={handleEditContract}
                      />
                    );
                  },
                  rowExpandable: (record: any) => record.isEnabled
                }}
              />
            ) : (
              <EmptyState description="No accounts found" />
            )
          ) : (
            <Alert
              type="warning"
              banner
              message="Please connect a wallet first."
              style={{ maxWidth: 250, margin: '25px auto' }}
            />
          )}
        </EulithCard>
        <Modal
          title="Edit Account Details"
          open={!!editingContract}
          onCancel={() => setEditingContract(null)}
          onOk={() => editForm.submit()}
          okButtonProps={{
            loading: isLoading
          }}
          cancelButtonProps={{
            loading: isLoading
          }}
        >
          <Form
            form={editForm}
            layout="vertical"
            initialValues={{
              name: editingContract?.name,
              description: editingContract?.description
            }}
            requiredMark={antdFormConfig.requiredMark}
            scrollToFirstError
            size={antdFormConfig.size}
            onFinish={handleContractEdit}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please provide a name.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please provide a description.' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </>
    ),
    [
      dataSource,
      onRow,
      editForm,
      editingContract,
      isLoading,
      handleEditContract,
      handleContractEdit,
      connectionStatus
    ]
  );
};

const StyledAccountNameContainer = styled.div`
  min-width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default TradingAccountsPage;
