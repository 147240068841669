import {
  AppstoreAddOutlined,
  BarsOutlined,
  BookOutlined,
  CloudServerOutlined,
  CodeOutlined,
  ControlOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  FundOutlined,
  FundViewOutlined,
  GroupOutlined,
  HomeOutlined,
  KeyOutlined,
  LineChartOutlined,
  LogoutOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
  SafetyCertificateOutlined,
  ScheduleOutlined,
  SlidersOutlined,
  UnorderedListOutlined,
  WalletOutlined
} from '@ant-design/icons';
import { Layout, Menu, MenuProps } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styled from 'styled-components';
import openImage from '../../assets/images/open.svg';
import metamaskLogo from '../../assets/images/metamask-logo.png';
import {
  selectAuthenticated,
  selectCanCreateFunds,
  selectCanGenerateTokens,
  selectCanViewAllFunds,
  selectHasArmorAccess,
  selectHasDocsAccess,
  selectHasMetamaskSnapAccess,
  selectHasTradingAccess,
  selectHasWalletAccess,
  selectUser
} from '../../features/auth/authSlice';
import { selectInlineCollapsed, selectIsDarkMode } from '../../features/settings/settingsSlice';
import { useAppSelector } from '../../hooks/redux';
import usePageView from '../../hooks/usePageView';
import ApiPage from '../../pages/ApiPage';
import HomePage from '../../pages/HomePage';
import YieldPage from '../../pages/YieldPage';
import AddressBook from '../../pages/AddressBook';
import NotFoundPage from '../../pages/NotFoundPage';
import StatusPage from '../../pages/StatusPage';
import { colors } from '../../styles/shared';
import LoggedInHeader from '../Header/LoggedInHeader';
import FundsLayout from './FundsLayout';
import TradingLayout from './TradingLayout';
import WalletPage from '../../pages/WalletPage';
import MetaMaskPage from '../../pages/MetaMaskPage';
import LogsPage from '../../pages/LogsPage';
import ArmorDeployLayout from './ArmorDeployLayout';
import TradingAccountsPage from '../../pages/TradingAccountsPage';
import TradingWhitelistPage from '../../pages/TradingWhitelistPage';
import TradingGroupsPage from '../../pages/TradingGroupsPage';
import { ARMOR_TABS } from './shared';

const LoggedInLayout: React.FC = () => {
  usePageView();
  const isDarkMode = useAppSelector(selectIsDarkMode);
  const inlineCollapsed = useAppSelector(selectInlineCollapsed);
  const navigate = useNavigate();
  const location = useLocation();
  const authenticated = useAppSelector(selectAuthenticated);
  // const contracts = useAppSelector(selectContractsData);
  const user = useAppSelector(selectUser);
  const canViewAllFunds = useAppSelector(selectCanViewAllFunds);
  const hasArmorAccess = useAppSelector(selectHasArmorAccess);
  const hasMetamaskSnapAccess = useAppSelector(selectHasMetamaskSnapAccess);
  const canCreateFunds = useAppSelector(selectCanCreateFunds);
  const canTrade = useAppSelector(selectHasTradingAccess);
  const canViewDocs = useAppSelector(selectHasDocsAccess);
  const canUseWallet = useAppSelector(selectHasWalletAccess);
  const canGenerateTokens = useAppSelector(selectCanGenerateTokens);

  useEffect(() => {
    if (!authenticated) {
      const pathname = window.location.pathname;
      if (!pathname || pathname === '/' || pathname === '/home') {
        navigate('/', { replace: true });
      } else {
        window.location.replace(`/?redirect=${pathname}`);
      }
    } else if (!hasArmorAccess) {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items: MenuProps['items'] = [
    {
      label: 'Home',
      key: '/home',
      icon: <HomeOutlined />,
      onClick: () => navigate('home')
    },
    {
      label: 'Yield',
      key: '/yield',
      icon: <LineChartOutlined />,
      onClick: () => navigate('yield')
    },
    // user && hasArmorAccess && contracts?.length
    //   ? {
    //       label: 'Financials',
    //       key: '/accounts',
    //       icon: <BankOutlined />,
    //       children: contracts.map((contract, index) => {
    //         return {
    //           label: contract.name || `Untitled Account ${index + 1}`,
    //           key: `/armor/accounts/${contract.contractAddress}`
    //         };
    //       })
    //     }
    //   : null,
    user && canTrade
      ? {
          label: 'Trade',
          key: '/trade',
          icon: <SlidersOutlined />,
          onClick: () => navigate('/trade')
        }
      : null,
    !canViewAllFunds && !canCreateFunds
      ? null
      : {
          label: 'Profiles',
          key: 'funds',
          icon: <FundOutlined />,
          children: [
            canCreateFunds
              ? {
                  label: 'Manage Funds',
                  key: '/funds/manage',
                  icon: <FundViewOutlined />,
                  onClick: () => navigate('/funds/manage')
                }
              : null,
            canViewAllFunds
              ? {
                  label: 'View Funds',
                  key: '/funds/list',
                  icon: <BarsOutlined />,
                  onClick: () => navigate('/funds/list')
                }
              : null,
            canCreateFunds
              ? {
                  label: 'Create Fund',
                  key: '/funds/create',
                  icon: <PlusSquareOutlined />,
                  onClick: () => navigate('/funds/create')
                }
              : null
          ].filter((x) => x)
        },
    user && hasMetamaskSnapAccess
      ? {
          label: 'MetaMask Snaps',
          key: 'metamask-snap',
          icon: <StyledMetaMaskImage src={metamaskLogo} />,
          onClick: () => navigate('/metamask')
        }
      : null,
    user && (hasArmorAccess || hasMetamaskSnapAccess)
      ? {
          label: 'Accounts and Whitelists',
          key: 'accounts_and_whitelists',
          icon: <SafetyCertificateOutlined />,
          children: [
            {
              label: 'Portfolios',
              key: '/armor/portfolios',
              icon: <GroupOutlined />,
              onClick: () => navigate('/armor/portfolios')
            },
            hasArmorAccess
              ? {
                  label: 'Account Creation',
                  key: ARMOR_TABS.OVERVIEW,
                  icon: <AppstoreAddOutlined />,
                  onClick: () => navigate(ARMOR_TABS.OVERVIEW)
                }
              : null,
            {
              label: 'Account Management',
              key: '/armor/accounts',
              icon: <ScheduleOutlined />,
              onClick: () => navigate('/armor/accounts')
            },
            {
              label: 'Whitelist Library',
              key: '/armor/whitelists',
              icon: <UnorderedListOutlined />,
              onClick: () => navigate('/armor/whitelists')
            }
            // {
            //   label: 'Account Configuration',
            //   key: '/armor/accounts/configuration',
            //   icon: <ScheduleOutlined />
            // },
            // {
            //   label: 'Pending Signatures',
            //   key: '/armor/accounts/pending',
            //   icon: <EditOutlined />
            // },
            // {
            //   label: 'Owner Operations',
            //   key: '/armor/accounts/operations',
            //   icon: <AuditOutlined />
            // }
          ].filter((x) => x)
        }
      : null,
    user && canGenerateTokens
      ? {
          label: 'Programmatic Trading',
          key: '/tokens',
          icon: <ControlOutlined />,
          children: [
            {
              icon: <KeyOutlined />,
              label: 'API Tokens',
              key: '/tokens/generate',
              onClick: () => navigate('tokens')
            },
            {
              label: 'Status',
              key: '/status',
              icon: <CloudServerOutlined />,
              onClick: () => navigate('status')
            },
            canViewDocs
              ? {
                  label: 'Docs',
                  key: 'docs',
                  icon: <FileTextOutlined />,
                  onClick: () => window.open('/resources/', '_blank')
                }
              : null,
            {
              label: 'Examples',
              key: 'examples',
              icon: <CodeOutlined />,
              onClick: () =>
                window.open(
                  'https://github.com/Eulith/eulith-feature-test-scripts/tree/master/examples',
                  '_blank'
                )
            }
          ].filter((x) => x)
        }
      : null,
    {
      label: 'Address Book',
      key: '/addresses',
      icon: <BookOutlined />,
      onClick: () => navigate('/addresses')
    },
    canUseWallet
      ? {
          label: 'Wallet',
          key: '/wallet',
          icon: <WalletOutlined />,
          onClick: () => navigate('/wallet')
        }
      : null,
    {
      label: 'Logs',
      key: '/logs',
      icon: <DatabaseOutlined />,
      onClick: () => navigate('/logs')
    },
    // {
    //   label: 'Preferences',
    //   key: 'preferences',
    //   icon: <SettingOutlined />,
    //   children: [
    //     {
    //       label: 'Calculation Assumptions',
    //       key: 'Calculation Assumptions',
    //       icon: <CalculatorOutlined />
    //     }
    //   ]
    // },
    {
      label: (
        <div>
          <span>How To Guide</span>
          <StyledOpenImage src={openImage} />
        </div>
      ),
      key: 'how-to',
      icon: <QuestionCircleOutlined />,
      onClick: () => window.open('/resources/docs/how-to/', '_blank')
    },
    {
      label: 'Logout',
      key: 'logout',
      danger: true,
      icon: <LogoutOutlined />,
      onClick: () => {
        localStorage.clear();
        window.location.replace('/');
      }
    }
  ].filter((x) => x);

  function determineActiveRouteKey() {
    const key = location.pathname;
    if (location.pathname.includes('/funds')) {
      return '/funds';
    } else if (location.pathname.includes('/armor/whitelists')) {
      return '/armor/whitelists';
    } else if (location.pathname.includes('/armor/deploy')) {
      return '/armor/deploy';
    }
    return key;
  }

  const key = determineActiveRouteKey();

  // function returnDefaultOpenKey() {
  //   const currentParentItem = items?.find?.((item) => {
  //     // @ts-ignore
  //     return !!item.children?.find?.((childItem: MenuProps['items']) => {
  //       // @ts-ignore
  //       return childItem.key.includes(paths[0]);
  //     });
  //   });
  //   return [(currentParentItem?.key as string) || ''];
  // }

  return (
    <Layout style={{ height: '100%' }}>
      <LoggedInHeader />
      <Layout hasSider>
        <Sider collapsible defaultCollapsed breakpoint="lg" collapsedWidth="50" width={275}>
          <Menu
            mode="inline"
            inlineCollapsed={inlineCollapsed}
            defaultSelectedKeys={[location.pathname]}
            // defaultOpenKeys={returnDefaultOpenKey()}
            style={{ height: '100%', borderRight: 0, backgroundColor: colors.blue2 }}
            items={items}
          />
        </Sider>
        <Content
          style={{
            zIndex: 0,
            padding: 20,
            overflow: 'auto',
            backgroundColor: isDarkMode ? colors.background : colors.white
          }}
        >
          <SwitchTransition>
            <CSSTransition key={key} classNames="fade" timeout={300} unmountOnExit>
              <Routes location={location}>
                <Route path="/home" element={<HomePage />} />
                <Route path="/yield" element={<YieldPage />} />
                <Route path="/addresses" element={<AddressBook />} />
                <Route path="/wallet" element={<WalletPage />} />
                <Route path="/tokens" element={<ApiPage />} />
                <Route path="/status" element={<StatusPage />} />
                <Route path="/armor/portfolios" element={<TradingGroupsPage />} />
                <Route path="/armor/whitelists/*" element={<TradingWhitelistPage />} />
                <Route path="/armor/accounts/:id" element={<TradingWhitelistPage />} />
                <Route path="/armor/accounts" element={<TradingAccountsPage />} />
                <Route path="/armor/deploy/*" element={<ArmorDeployLayout />} />
                <Route path="/funds/*" element={<FundsLayout />} />
                <Route path="/trade/*" element={<TradingLayout />} />
                <Route path="/metamask" element={<MetaMaskPage />} />
                <Route path="/logs" element={<LogsPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </CSSTransition>
          </SwitchTransition>
        </Content>
      </Layout>
    </Layout>
  );
};

const StyledOpenImage = styled.img`
  transform: translateY(2px);
  margin-left: 5px;
`;

const StyledMetaMaskImage = styled.img`
  width: 16px;
  height: 16px;
`;

export default LoggedInLayout;
