import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import { H1, H2, Text, colors } from '../styles/shared';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import {
  selectGroupsData,
  useEditGroupMutation,
  useLazyGetGroupsQuery
} from '../features/groups/groupsService';
import {
  Button,
  Col,
  Row,
  Select,
  message,
  Slider,
  SliderSingleProps,
  List,
  ConfigProvider,
  Spin,
  Popover
} from 'antd';
import { dollarFormatter } from '../utils/data';
import { selectSelectedGroup, setSelectedGroup } from '../features/groups/groupsSlice';
import { selectUser } from '../features/auth/authSlice';
import styled from 'styled-components';
import EmptyState from '../components/EmptyState/EmptyState';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import CreatePortfolioForm from '../components/CreatePortfolioForm';
import EulithChart from '../components/EulithChart';

const marks: SliderSingleProps['marks'] = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5
};

const YieldPage: React.FC = () => {
  const [getGroups, { isLoading: loadingGroups }] = useLazyGetGroupsQuery();
  const user = useAppSelector(selectUser);
  const groups = useAppSelector(selectGroupsData);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const selectedGroup = useAppSelector(selectSelectedGroup);
  const dispatch = useAppDispatch();
  const [editGroup] = useEditGroupMutation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user?.sub) {
      getGroups(user.sub).unwrap().catch(console.warn);
    }
  }, [getGroups, user?.sub]);

  const openAddModal = useCallback(() => {
    setAddModalOpen(true);
  }, []);

  const closeAddModal = useCallback(() => {
    setAddModalOpen(false);
  }, []);

  const groupOptions = useMemo(() => {
    return groups.map((group) => {
      return {
        label: group.name,
        value: group.id
      };
    });
  }, [groups]);

  const handleSelectedGroupChange = useCallback(
    (groupId: string) => {
      const group = groups.find((group) => {
        return group.id === groupId;
      });
      if (group) {
        dispatch(setSelectedGroup(group));
      } else {
        console.warn('Could not find group for id', groupId);
      }
    },
    [dispatch, groups]
  );

  const setRisk = useCallback(
    (risk: number) => {
      if (selectedGroup?.id) {
        editGroup({ id: selectedGroup?.id, payload: { risk } })
          .unwrap()
          .then(() => {
            message.success('Risk level successfully updated!');
          })
          .catch((error) => {
            console.warn(error);
            message.error('Unable to set account risk level.');
          });
      } else {
        console.warn('No accounts selected.');
      }
    },
    [editGroup, selectedGroup?.id]
  );

  const handleStart = useCallback(() => {
    if (selectedGroup?.id) {
      setLoading(true);
      editGroup({ id: selectedGroup?.id, payload: { started: true } })
        .unwrap()
        .then(() => {
          message.success('Account successfully activated!');
        })
        .catch((error) => {
          console.warn(error);
          message.error('Unable to activate account.');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.warn('No accounts selected.');
    }
  }, [editGroup, selectedGroup?.id]);

  const handleStop = useCallback(() => {
    if (selectedGroup?.id) {
      setLoading(true);
      editGroup({ id: selectedGroup?.id, payload: { started: false } })
        .unwrap()
        .then(() => {
          message.success('Account successfully deactivated!');
        })
        .catch((error) => {
          console.warn(error);
          message.error('Unable to deactivate account.');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.warn('No accounts selected.');
    }
  }, [editGroup, selectedGroup?.id]);

  const handleDeposit = useCallback(() => {
    if (selectedGroup?.id) {
      setLoading(true);
      editGroup({ id: selectedGroup?.id, payload: { deposited: true } })
        .unwrap()
        .then(() => {
          message.success('Account deposit initiated!');
        })
        .catch((error) => {
          console.warn(error);
          message.error('Unable to initiate account deposit.');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.warn('No accounts selected.');
    }
  }, [editGroup, selectedGroup?.id]);

  return (
    <PageContainer>
      <EulithHelmetConsumer>Yield</EulithHelmetConsumer>
      {groupOptions?.length ? (
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={12}>
            <Row align="middle">
              <H2>Portfolios</H2>
              <Select
                style={{ width: 200, marginLeft: 15 }}
                options={groupOptions}
                value={selectedGroup?.id}
                popupMatchSelectWidth={false}
                onChange={handleSelectedGroupChange}
                placeholder="Select account..."
              />
            </Row>
          </Col>
          <Col span={12}>
            <Button onClick={openAddModal} type="primary" size="large" style={{ float: 'right' }}>
              Create Portfolio
            </Button>
          </Col>
        </Row>
      ) : loadingGroups ? (
        <Row align="middle" justify="center" style={{ height: '100%' }}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Text style={{ paddingBottom: 20, textAlign: 'center' }}>Fetching accounts...</Text>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>
        </Row>
      ) : (
        <Row align="middle" justify="center" style={{ height: '100%' }}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <H2 style={{ paddingBottom: 20, textAlign: 'center' }}>
              Create your first Eulith account.
            </H2>
            <Text style={{ paddingBottom: 20, textAlign: 'center' }}>
              You have not created any accounts yet.
            </Text>
            <Button onClick={openAddModal} type="primary" size="large">
              Create Portfolio
            </Button>
          </div>
        </Row>
      )}
      <Row justify="space-between" gutter={[16, 16]} style={{ marginTop: 30 }}>
        {selectedGroup ? (
          <Col xs={24} md={17}>
            <Row justify="space-between" style={{ marginBottom: 20 }}>
              <H2>
                <span style={{ fontWeight: '200' }}>Balance:</span>{' '}
                {dollarFormatter(selectedGroup.lastKnownBalance)}
              </H2>
              <div>
                <Button
                  type="primary"
                  style={{ marginRight: 10 }}
                  loading={loading}
                  disabled={selectedGroup?.started}
                  onClick={handleDeposit}
                >
                  Withdraw
                </Button>
                {selectedGroup?.denomination ? (
                  <Button type="default" style={{ marginRight: 20, pointerEvents: 'none' }}>
                    {selectedGroup.denomination}
                  </Button>
                ) : null}
              </div>
            </Row>
            <EulithChart />
          </Col>
        ) : null}
        {selectedGroup ? (
          <Col xs={24} md={7}>
            <StyledRiskContainer>
              <H2>Risk</H2>
              <StyledRiskAdjustmentContainer>
                <div>
                  E. CAGR (APY)
                  <Popover content="Expected annual return based on historical data.">
                    <InfoCircleOutlined style={{ cursor: 'pointer', marginLeft: 10 }} />
                  </Popover>
                </div>
                {selectedGroup.risk ? <H1>{`${selectedGroup.risk * 10}%`}</H1> : null}
              </StyledRiskAdjustmentContainer>
            </StyledRiskContainer>
            <Slider
              onChangeComplete={setRisk}
              min={1}
              max={5}
              marks={marks}
              defaultValue={selectedGroup.risk || 1}
            />
            <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
              <Col span={12}>
                <Button
                  size="large"
                  block
                  loading={loading}
                  type="default"
                  style={{ height: 60 }}
                  disabled={selectedGroup?.started}
                  onClick={handleStart}
                >
                  Start
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  block
                  disabled={!selectedGroup?.started}
                  loading={loading}
                  style={{ height: 60 }}
                  onClick={handleStop}
                >
                  Stop
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <StyledButton
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  disabled={selectedGroup?.started}
                  onClick={handleDeposit}
                >
                  Deposit
                </StyledButton>
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
      {selectedGroup ? (
        <ConfigProvider
          renderEmpty={() => <EmptyState description="No history for this account" />}
        >
          <List
            bordered
            style={{ marginTop: 20 }}
            header={<H2>History</H2>}
            dataSource={selectedGroup.history}
            renderItem={(item) => {
              return (
                <List.Item
                  actions={
                    item.timestamp
                      ? [
                          <div key="timestamp">
                            {new Date(item.timestamp).toDateString()}{' '}
                            {new Date(item.timestamp).toLocaleTimeString()}
                          </div>
                        ]
                      : undefined
                  }
                >
                  <List.Item.Meta title={item.label} description={item.value} />
                </List.Item>
              );
            }}
            pagination={{
              pageSize: 5
            }}
          />
        </ConfigProvider>
      ) : null}
      <CreatePortfolioForm open={!!addModalOpen} close={closeAddModal} />
    </PageContainer>
  );
};

const StyledRiskContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledRiskAdjustmentContainer = styled.div`
  border-radius: 8px;
  padding: 10px 20px;
  border: 2px solid ${colors.blueTrans};
  text-align: center;
`;

const PageContainer = styled.div`
  height: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  height: 100px;
`;

export default YieldPage;
