import { Row, Spin, message } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ERROR_MESSAGE = 'Could not load daily returns. Please try again later.';

const FundReturnsPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [htmlContent, setHtmlContent] = useState('');

  const url = location.state?.url;

  const handleError = useCallback(
    (error: any) => {
      console.warn(error);
      message.error(ERROR_MESSAGE);
      navigate(-1);
    },
    [navigate]
  );

  useEffect(() => {
    if (url) {
      fetch(url)
        .then((response) => response.text())
        .then((html) => setHtmlContent(html))
        .catch((error) => {
          handleError(error);
        });
    } else {
      handleError(new Error('Url was not supplied'));
    }
  }, [navigate, handleError, url]);

  if (htmlContent) {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  } else {
    return (
      <Row
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Spin />
      </Row>
    );
  }
};

export default FundReturnsPage;
