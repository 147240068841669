export interface GroupAccountHistory {
  label: string;
  value: number | string;
  timestamp: string;
}

export interface GroupBalanceHistory {
  usd_value: number;
  timestamp: number;
}
export interface GroupAccount {
  id?: string;
  name: string;
  userId: string;
  denomination: string;
  safeAddress: string;
  lastKnownBalance?: number;
  risk?: number;
  createdAt: number;
  updatedAt: number;
  started?: boolean;
  deposited: boolean;
  history: GroupAccountHistory[];
  accounts: string[];
}

export interface UpdateGroupRequest {
  id: string;
  payload: Partial<GroupAccount>;
}

export const GROUPS_DB_NAME = 'groups';
export const ACCOUNTS_DB_NAME = 'accounts';
export const BALANCE_HISTORY_COLLECTION_NAME = 'balanceHistory';

export interface GroupsState {
  selectedGroup?: GroupAccount;
}
