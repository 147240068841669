import { selectHasArmorAccess } from '../features/auth/authSlice';
import { useAppSelector } from '../hooks/redux';
import GroupsHomePage from './GroupsHomePage';
import LegacyHomePage from './LegacyHome';

const HomePage: React.FC = () => {
  const hasArmorAccess = useAppSelector(selectHasArmorAccess);
  if (hasArmorAccess) {
    return <GroupsHomePage />;
  } else {
    return <LegacyHomePage />;
  }
};

export default HomePage;
