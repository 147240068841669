import {
  AppstoreAddOutlined,
  CheckCircleFilled,
  DeploymentUnitOutlined,
  EditOutlined,
  FileProtectOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SafetyCertificateOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { useAddress, useChainId, useConnectionStatus } from '@thirdweb-dev/react';
import eulithSingleton from '../../../features/eulith/EulithSingleton';
import {
  Alert,
  Button,
  Form,
  Image,
  Input,
  Popover,
  Modal,
  Row,
  Spin,
  Space,
  Steps,
  message,
  Col,
  StepProps,
  Checkbox,
  notification
} from 'antd';
import * as Eulith from 'eulith-web3js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Confetti from 'react-confetti';
import EulithHelmetConsumer from '../../EulithHelmetConsumer';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styled from 'styled-components';
import DEFAULT_ICON from '../../../assets/images/generic-cryptocurrency-icon.png';
import EulithCard from '../../EulithCard';
import {
  selectContractsData,
  useLazyGetContractsQuery
} from '../../../features/eulith/eulithService';
import { selectEulithClientInitialized } from '../../../features/eulith/eulithSlice';
import { useAppSelector } from '../../../hooks/redux';
import {
  H1,
  H2,
  H3,
  StyledItalic,
  StyledLi,
  Text,
  addressValidationConfig,
  antdFormConfig,
  colors
} from '../../../styles/shared';
import { shortenString } from '../../../utils/data';
import { useArmor } from '../../../hooks/useArmor';
import { DecoratedContract } from '../../../features/eulith/eulithTypes';
import { ARMOR_TABS as TABS } from '../shared';
import './styles.css';
import MonoSpaceSpan from '../../MonoSpaceSans';
import AddOwnersForm from './components/AddOwnersForm';
import { ArmorOwner } from '../../../features/order/orderTypes';
import { usePrevious } from '../../../hooks/usePrevious';
import MiddleEllipses from '../../MiddleEllipses';
import {
  calculateChainIconUrl,
  chainIdToNetworkLabel,
  getAllChains
} from '../../../utils/networks';
import BugsnagManager from '../../../BugsnagManager';

const DEFAULT_OWNER_LABEL = 'Untitled Owner';
const DEFAULT_OWNER_EMAIL = 'Not Provided';

interface DeployPreviewProps {
  title: string;
  subtitle: string;
  image?: string;
  icon: React.ReactElement;
  style?: React.CSSProperties;
}

function removeBaseRoute(path: string) {
  return path.replace('/armor/deploy', '');
}

function DeployPreview({ title, subtitle, image, icon, style }: DeployPreviewProps) {
  return (
    <DeployPreviewContainer style={style}>
      <div>
        <Text style={{ color: '#141414', fontWeight: 'bold' }}>{title}</Text>
        <MonoSpaceSpan style={{ color: '#141414' }}>{subtitle}</MonoSpaceSpan>
      </div>
      <div style={{ display: 'flex', flexGrow: 1, padding: '0 25px', alignItems: 'center' }}>
        <div style={{ flexGrow: 1, borderBottom: '2px solid #141414' }} />
        {icon}
        <div style={{ flexGrow: 1, borderBottom: '2px solid #141414' }} />
      </div>
      <ChainLogoContainer>
        <Image
          preview={false}
          src={image ? image : DEFAULT_ICON}
          width={40}
          height={40}
          style={{ minWidth: 40, minHeight: 40 }}
        />
      </ChainLogoContainer>
    </DeployPreviewContainer>
  );
}

interface CircularIconProps {
  icon: React.ReactElement;
  style?: React.CSSProperties;
}

function CircularIcon({ icon, style }: CircularIconProps) {
  return <CircularIconContainer style={style}>{icon}</CircularIconContainer>;
}

const ArmorDeployLayout: React.FC = () => {
  const chainId = useChainId();
  const eulithClientInitialized = useAppSelector(selectEulithClientInitialized);
  const connectionStatus = useConnectionStatus();
  const location = useLocation();
  const navigate = useNavigate();
  const uiWalletAddress = useAddress();
  const [showConfetti, setShowConfetti] = useState(false);
  const [safeLoading, setSafeLoading] = useState(false);
  const [threshold, setThreshold] = useState(1);
  const [form] = Form.useForm();
  const [deployForm] = Form.useForm();
  const [localOwners, setLocalOwners] = useState<ArmorOwner[]>([]);
  const [getContracts, { isLoading, isFetching }] = useLazyGetContractsQuery();
  const contracts = useAppSelector(selectContractsData);
  const id = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('id') || '';
  }, []);
  const [loading, setLoading] = useState(!!id);

  const [armorInstance, setArmorInstance] = useState<DecoratedContract | null>(
    contracts.find((contract) => contract.contractAddress === id) || null
  );
  const prevArmorInstance: DecoratedContract | null = usePrevious(armorInstance);
  const prevLocation: string = usePrevious(location.pathname);
  const [deployingModalVisible, setDeployingModalVisible] = useState(false);
  const [existingSafeAddress, setExistingSafeAddress] = useState<string | undefined>();

  const { createArmor, authorizeArmorForOwner, enableArmor, armorLoading, setArmorLoading } =
    useArmor();

  const getContractsLoading = isLoading || isFetching;
  const hasArmorInstance = !!armorInstance;

  useEffect(() => {
    if (!hasArmorInstance && contracts.length && id) {
      if (!window.location.pathname.includes(TABS.OVERVIEW)) {
        setArmorInstance(
          contracts.find(
            (contract) => contract.contractAddress.toLowerCase() === id.toLowerCase()
          ) || null
        );
      }
    }
  }, [hasArmorInstance, contracts, id]);

  function resetArmor() {
    setThreshold(1);
    setLocalOwners([]);
    setShowConfetti(false);
    setSafeLoading(false);
    setArmorLoading(false);
    setArmorInstance(null);
    setDeployingModalVisible(false);
    setExistingSafeAddress('');
    form.resetFields();
    deployForm.resetFields();
  }

  useEffect(() => {
    if (prevLocation !== TABS.OVERVIEW && location.pathname === TABS.OVERVIEW) {
      resetArmor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevLocation, location.pathname]);

  useEffect(() => {
    return function cleanup() {
      resetArmor();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoUrl = useMemo(() => {
    const chain = getAllChains().find((chain) => chain.chainId === chainId);
    return calculateChainIconUrl(chain?.icon?.url || '', chainId);
  }, [chainId]);

  const navigateToConfigure = useCallback(() => {
    navigate(TABS.CONFIGURE);
  }, [navigate]);

  const navigateToSuccess = useCallback(() => {
    navigate(TABS.SUCCESS);
  }, [navigate]);

  const navigateToAddOwners = useCallback(
    (address: string, replace = false) => {
      navigate(`${TABS.ADD_OWNERS}?id=${address}`, { replace });
    },
    [navigate]
  );

  const navigateToAuthorizeOwners = useCallback(
    (address: string, replace = false) => {
      navigate(`${TABS.AUTHORIZE_OWNERS}?id=${address}`, { replace });
    },
    [navigate]
  );

  const navigateToWhitelist = useCallback(() => {
    navigate('/armor/whitelists');
  }, [navigate]);

  const setExistingSafe = useCallback(
    async (address: string) => {
      if (eulithSingleton.provider) {
        const safe = new Eulith.Contracts.Safe(eulithSingleton.provider, address);
        const ownerAddresses = await safe.getOwners();
        const threshold = await safe.getThreshold();
        const owners = ownerAddresses.map((address) => {
          return {
            address: address,
            label: DEFAULT_OWNER_LABEL,
            email: DEFAULT_OWNER_EMAIL,
            authorized: false
          };
        });
        setLocalOwners(owners);
        form.setFieldValue('owners', ownerAddresses);
        setThreshold(threshold);
        setExistingSafeAddress(address);
      } else {
        console.warn('Eulith provider was not yet initialized');
      }
    },
    [form]
  );

  const getAuthorizedOwnerSignaturesForAgent = useCallback(
    (
      localArmorInstance: DecoratedContract,
      agent: Eulith.OnChainAgents.IArmorAgent,
      existingSafe = false
    ) => {
      if (!eulithSingleton.provider) {
        console.warn(
          'Could not get contract addresses because eulith provider has not been set yet'
        );
      } else if (!armorInstance) {
        console.warn('Could not get contract addresses because armor instance was not provided');
      } else {
        agent
          .getAuthorizingOwnerSignatures(
            eulithSingleton.provider,
            localArmorInstance.tradingKeyAddress
          )
          .then((response) => {
            const authorizedAddresses = (response || []).map((address) => {
              // @ts-ignore
              return Eulith.Web3.utils.toChecksumAddress(address.ownerAddress);
            });
            setLocalOwners((prevState) => {
              const authorizedOwnersMap: any = {};
              const unauthorizedOwnersMap: any = {};

              authorizedAddresses.forEach((authorizedOwner) => {
                authorizedOwnersMap[Eulith.Web3.utils.toChecksumAddress(authorizedOwner)] =
                  authorizedOwner;
              });

              prevState.forEach((unauthorizedOwner) => {
                unauthorizedOwnersMap[
                  Eulith.Web3.utils.toChecksumAddress(unauthorizedOwner.address)
                ] = unauthorizedOwner;
              });

              for (const address in authorizedOwnersMap) {
                if (unauthorizedOwnersMap[address]) {
                  unauthorizedOwnersMap[address].authorized = true;
                } else {
                  unauthorizedOwnersMap[address] = {
                    address,
                    label: DEFAULT_OWNER_LABEL,
                    email: DEFAULT_OWNER_EMAIL,
                    authorized: true
                  };
                }
              }
              const addresses = Object.values(unauthorizedOwnersMap) as ArmorOwner[];
              form.setFieldValue('owners', addresses);
              if (!existingSafe) {
                navigateToAddOwners(
                  Eulith.Web3.utils.toChecksumAddress(agent.contractAddress),
                  true
                );
              }
              setLoading(false);
              return [...addresses];
            });
          })
          .catch((error: any) => {
            BugsnagManager.notify(error, {
              context: 'Unable to get authorized owner signatures',
              metadata: {
                armorInstance
              }
            });
            setLoading(false);
            console.warn(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [armorInstance, form, navigateToAddOwners]
  );

  const getArmorInstanceAddresses = useCallback(
    async (armorInstance: DecoratedContract) => {
      if (!eulithSingleton.provider) {
        console.warn(
          'Could not get contract addresses because eulith provider has not been set yet'
        );
      } else if (!armorInstance) {
        console.warn('Could not get contract addresses because armor instance was not provided');
      } else {
        const agent = await Eulith.OnChainAgents.getArmorAgent({
          provider: eulithSingleton.provider,
          tradingKeyAddress: armorInstance.tradingKeyAddress
        });
        if (agent?.contractAddress) {
          try {
            await setExistingSafe(agent.safeAddress);
            navigateToAuthorizeOwners(agent.contractAddress, true);
            getAuthorizedOwnerSignaturesForAgent(armorInstance, agent, true);
            setLoading(false);
          } catch (error: any) {
            console.log(
              `User has selected a contract that did NOT use an existing safe (${error.message})`
            );
            getAuthorizedOwnerSignaturesForAgent(armorInstance, agent);
            setLoading(false);
          }
        }
      }
    },
    [navigateToAuthorizeOwners, setExistingSafe, getAuthorizedOwnerSignaturesForAgent]
  );

  async function handleSetExistingSafe(address: string) {
    if (eulithSingleton.provider) {
      try {
        setSafeLoading(true);
        await setExistingSafe(address);
        Modal.destroyAll();
        navigateToConfigure();
        setSafeLoading(false);
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to retrieve information about safe',
          metadata: {
            address
          }
        });
        console.warn(error);
        notification.open({
          message: 'An error occurred when attempting to use this safe.',
          description:
            "Returned values are not valid. Double check to make sure Eulith is connected to the same network as the Safe you are adding. If you are using Metamask to connect to Eulith, that means Metamask should be connected to the same network as the Safe you're connecting to. If you're on the right network and receiving this error, then check to see if you ran out of gas.",
          placement: 'top',
          type: 'error',
          duration: null
        });
        setSafeLoading(false);
      }
    } else {
      Modal.destroyAll();
      message.error('Eulith services are not yet initialized.');
    }
  }

  function promptForExistingSafe() {
    Modal.info({
      title: 'Please enter your Safe address.',
      icon: <SafetyCertificateOutlined />,
      closeIcon: <div />,
      footer: null,
      maskClosable: true,
      closable: true,
      content: (
        <div style={{ paddingRight: 30 }}>
          <Form
            onFinish={(data: { address: string }) => {
              handleSetExistingSafe(data.address);
            }}
          >
            <Form.Item
              name="address"
              labelCol={antdFormConfig.labelCol}
              rules={[
                {
                  required: true,
                  message: 'Please supply an address.'
                },
                addressValidationConfig
              ]}
            >
              <Input
                size="large"
                placeholder="Enter address..."
                style={{ fontFamily: 'monospace' }}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              block
              size="large"
              type="primary"
              style={{ width: '100%' }}
              loading={safeLoading}
            >
              Use Safe
            </Button>
          </Form>
        </div>
      )
    });
  }

  useEffect(() => {
    if (!prevArmorInstance && armorInstance) {
      getArmorInstanceAddresses(armorInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [armorInstance, prevArmorInstance]);

  const steps = useMemo(
    () =>
      [
        {
          title: 'Set Parameters & Deploy',
          icon: <SettingOutlined />
        },
        existingSafeAddress
          ? null
          : {
              title: 'Add Account Owners',
              icon: <SafetyCertificateOutlined />
            },
        {
          title: 'Authorize Owners',
          icon: <EditOutlined />
        },
        {
          title: 'Success',
          icon: <DeploymentUnitOutlined />
        }
      ].filter((x) => x) as StepProps[],
    [existingSafeAddress]
  );

  function renderOverview() {
    return (
      <SectionContainer>
        <EulithHelmetConsumer>DeFi Armor</EulithHelmetConsumer>
        {getContractsLoading ? null : (
          <Space size="large" direction="vertical" align="center" style={{ width: '100%' }}>
            <CircularIcon
              style={{ marginTop: -60 }}
              icon={
                <FileProtectOutlined style={{ fontSize: 50, color: 'rgba(255,255,255,0.25)' }} />
              }
            />
            <Space size="large" direction="vertical" align="center" style={{ width: '100%' }}>
              <H1 style={{ textAlign: 'center' }}>Setup On-Chain Account</H1>
              <Text style={{ textAlign: 'center', maxWidth: 500 }}>
                You are about to setup an on-chain account using Eulith&apos;s security software,
                DeFi Armor. You can read more about it in our documentation{' '}
                <a href="/resources/docs/DeFi%20Armor/intro/" target="_blank" rel="noreferrer">
                  here
                </a>
                .
              </Text>
              <img
                src="https://staging.eulithclient.com/resources/assets/images/defi_armor_architecture-9af752c016e01e1ff476d04e30d13820.svg"
                style={{
                  width: '100%',
                  borderRadius: 8,
                  maxWidth: 500,
                  overflow: 'hidden',
                  objectFit: 'contain'
                }}
              />
              {contracts?.length || getContractsLoading ? null : (
                <Button
                  type="primary"
                  target="_blank"
                  size="large"
                  href="/resources/docs/DeFi%20Armor/intro/"
                  rel="noreferrer"
                >
                  DeFi Armor Documentation
                </Button>
              )}
            </Space>
            <div style={{ width: '100%', margin: '35px 0 0 0' }}>
              <H1 style={{ textAlign: 'center' }}>Before you get started, you will need:</H1>
              <ol style={{ maxWidth: 750 }}>
                <StyledLi>
                  Understanding:
                  <span style={{ fontWeight: 'normal' }}>
                    {' '}
                    A basic understanding of DeFi Armor. You can read about it in our documentation
                    <a href="/resources/docs/DeFi%20Armor/intro/" target="_blank" rel="noreferrer">
                      {' '}
                      here
                    </a>
                    .
                  </span>
                </StyledLi>
                <StyledLi>
                  Trading Key:
                  <span style={{ fontWeight: 'normal' }}>
                    {' '}
                    The public address of the trading key you intend to trade with for the account.
                    Note: if you&apos;re using this to setup an SMA and you are the investor,
                    you&apos;ll need this from the trader.
                  </span>
                </StyledLi>
                <StyledLi>
                  Owner Keys:
                  <span style={{ fontWeight: 'normal' }}>
                    {' '}
                    The owner keys you intend to own the count. You&apos;ll need to sign with these
                    keys.
                  </span>
                </StyledLi>
                <StyledLi>
                  ETH for Gas:
                  <span style={{ fontWeight: 'normal' }}>
                    {' '}
                    An appropriate amount of ETH to pay for gas. The amount of gas depends on the
                    network. On Arbitrum, it&apos;s typically $8 end to end for a new account.
                  </span>
                </StyledLi>
              </ol>
            </div>
            {connectionStatus !== 'connected' ? (
              <Alert
                style={{ marginBottom: 20 }}
                type="warning"
                banner
                message="Please connect a wallet before proceeding."
              />
            ) : eulithClientInitialized ? (
              <div>
                {chainId ? (
                  <div style={{ marginBottom: 60, textAlign: 'center' }}>
                    Current Network
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 5,
                        marginRight: 15
                      }}
                    >
                      {logoUrl ? (
                        <Image preview={false} src={logoUrl} width={40} height={40} />
                      ) : (
                        <QuestionCircleOutlined style={{ fontSize: 35, opacity: 0.7 }} />
                      )}
                      <H3 style={{ marginLeft: 10 }}>{chainIdToNetworkLabel(chainId)}</H3>
                    </div>
                  </div>
                ) : null}
                <Row gutter={16} style={{ marginBottom: 50 }}>
                  <Col span={12}>
                    <EulithCard
                      hoverable
                      fullHeight
                      onClick={navigateToConfigure}
                      style={{ height: '100%', border: '2px solid rgba(255,255,255,0.7)' }}
                      bodyStyle={{ height: '100%' }}
                    >
                      <div style={{ textAlign: 'center' }}>
                        <AppstoreAddOutlined
                          style={{ fontSize: 35, opacity: 0.7, marginBottom: 10 }}
                        />
                        <H2>Create Fresh Account</H2>
                        <div>(I want to deploy a new Safe)</div>
                        <StyledItalic>Takes 8 minutes</StyledItalic>
                      </div>
                    </EulithCard>
                  </Col>
                  <Col span={12}>
                    <EulithCard
                      hoverable
                      fullHeight
                      onClick={promptForExistingSafe}
                      style={{ height: '100%', border: '2px solid rgba(255,255,255,0.7)' }}
                      bodyStyle={{ height: '100%' }}
                    >
                      <div style={{ textAlign: 'center' }}>
                        <SafetyCertificateOutlined
                          style={{ fontSize: 35, opacity: 0.7, marginBottom: 10 }}
                        />
                        <H2>Create Account with Existing Safe</H2>
                        <div>(Attach DeFi Armor to a Safe already deployed outside of Eulith)</div>
                        <StyledItalic>Takes 5 minutes</StyledItalic>
                      </div>
                    </EulithCard>
                  </Col>
                </Row>
              </div>
            ) : (
              <Alert
                type="warning"
                style={{ marginBottom: 20 }}
                banner
                message="Eulith services have not yet initialized."
              />
            )}
          </Space>
        )}
      </SectionContainer>
    );
  }

  function renderConfigure() {
    return (
      <SectionContainer>
        <SpaceContainer size="large" direction="vertical">
          <CircularIcon
            icon={<AppstoreAddOutlined style={{ fontSize: 50, color: 'rgba(255,255,255,0.25)' }} />}
          />
          <div>
            <H1 style={{ textAlign: 'center' }}>Set Account Parameters</H1>
            <StyledItalic style={{ textAlign: 'center' }}>Takes 2 Minutes</StyledItalic>
          </div>
          {existingSafeAddress ? (
            <Text style={{ textAlign: 'center' }}>
              Using existing Safe: <strong>{shortenString(existingSafeAddress, 30)}</strong>
            </Text>
          ) : null}
          {existingSafeAddress ? (
            <Text style={{ textAlign: 'center' }}>
              Attach an Armor contract to this Safe by supplying a desired trading key address
              below.
            </Text>
          ) : null}
          {connectionStatus !== 'connected' ? (
            <Alert type="warning" banner message="Please connect a wallet before deploying." />
          ) : (
            <Form
              form={deployForm}
              onFinish={handleDeployArmor}
              style={{ width: '100%' }}
              layout="vertical"
              requiredMark={antdFormConfig.requiredMark}
              scrollToFirstError
              size={antdFormConfig.size}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter an account name.'
                  }
                ]}
              >
                <Input placeholder="Enter account name..." />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Please enter an account description.'
                  }
                ]}
              >
                <Input.TextArea placeholder="Add description here..." />
              </Form.Item>
              {/* <EulithTable
                columns={networkColumns}
                //@ts-ignore
                data={networkData}
              /> */}
              <Form.Item
                label="Trading Key Public Address"
                name="tradingKeyAddress"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a trading key public address.'
                  },
                  addressValidationConfig
                ]}
              >
                <Input placeholder="Enter address here..." style={{ fontFamily: 'monospace' }} />
              </Form.Item>
              <Form.Item name="hasAce" valuePropName="checked">
                <Row gutter={8} align="middle">
                  <Col span={9}>
                    <span>
                      Enable ACE for this account?
                      <span style={{ marginLeft: 7 }}>
                        <Popover
                          content={
                            <div style={{ maxWidth: 300 }}>
                              ACE (asynchronous client executor) is a client-operated service that
                              allows users to execute a limited scope of transactions in the
                              background. Clients choose to run an ACE if they want the ability to
                              conveniently schedule transactions without having to write and run
                              their own code. Running an ACE is entirely optional; if you don&apos;t
                              need scheduled/algorithmic trading.
                            </div>
                          }
                        >
                          <QuestionCircleOutlined style={{ cursor: 'pointer' }} />
                        </Popover>
                      </span>
                    </span>
                  </Col>
                  <Col span={4}>
                    <Checkbox />
                  </Col>
                </Row>
              </Form.Item>
              {/* <StyledItalic>
                Note: We will use the same key across all networks.
                <br />
                If this is not your intention, you will need to go through the account creation
                process for each network separately.
                <br />
                Please let us know if you need this and we&apos;ll make it easier.
              </StyledItalic> */}
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const value = getFieldValue('tradingKeyAddress');
                  if (value) {
                    return (
                      <DeployPreview
                        style={{ margin: '20px 0' }}
                        title="Deploying for Trading Key Address"
                        subtitle={shortenString(value || '', 20)}
                        image={logoUrl}
                        icon={
                          <AppstoreAddOutlined
                            style={{ fontSize: 24, color: '#141414', padding: '0 15px' }}
                          />
                        }
                      />
                    );
                  } else {
                    return null;
                  }
                }}
              </Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                block
                style={{ width: '100%', height: 100, marginBottom: 20, fontWeight: 'bold' }}
                size="large"
                loading={armorLoading}
              >
                Save and Deploy
              </Button>
            </Form>
          )}
        </SpaceContainer>
        <Modal
          open={deployingModalVisible}
          centered
          footer={null}
          closable={false}
          maskClosable={false}
          width={250}
        >
          <Row align="middle" justify="center">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Text style={{ paddingBottom: 20, textAlign: 'center' }}>
                Awaiting signature from wallet...
              </Text>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          </Row>
        </Modal>
      </SectionContainer>
    );
  }

  function renderSuccess() {
    return (
      <SectionContainer>
        <SpaceContainer size="large" direction="vertical" style={{ margin: 'auto' }}>
          <CircularIcon
            icon={<CheckCircleFilled style={{ fontSize: 50, color: 'rgba(255,255,255,0.25)' }} />}
          />
          <H1 style={{ textAlign: 'center' }}>Armor Enabled!</H1>
          <Text style={{ textAlign: 'center' }}>
            Your account is setup to trade.
            <br />
            We suggest configuring your whitelists next.
          </Text>
          <Button
            type="primary"
            block
            style={{ width: '100%' }}
            size="large"
            onClick={navigateToWhitelist}
          >
            View Whitelists
          </Button>
        </SpaceContainer>
      </SectionContainer>
    );
  }

  function renderConfetti() {
    if (showConfetti) {
      return (
        <ConfettiContainer>
          <Confetti
            gravity={0.1}
            friction={0.99}
            recycle={false}
            onConfettiComplete={() => {
              setShowConfetti(false);
            }}
            width={window.innerWidth}
            height={window.innerHeight}
          />
        </ConfettiContainer>
      );
    } else {
      return null;
    }
  }

  function renderAuthorizeOwners() {
    const localUnauthorizedOwners = localOwners.filter((owner) => !owner.authorized);
    const localAuthorizedOwners = localOwners.filter((owner) => owner.authorized);
    return (
      <SectionContainer>
        <SpaceContainer size="large" direction="vertical">
          <CircularIcon
            icon={<EditOutlined style={{ fontSize: 50, color: 'rgba(255,255,255,0.25)' }} />}
          />
          <div>
            <H1 style={{ textAlign: 'center' }}>Authorize Owners</H1>
            <StyledItalic style={{ textAlign: 'center' }}>Takes 5 Minutes</StyledItalic>
          </div>
          <Text style={{ textAlign: 'center' }}>
            After specifying your account owners and the account&apos;s signature threshold, you
            need to submit owner signatures. You might have a variety of signature sources (KMS,
            Fireblocks, etc). You can authorize the highlighted address by connecting your custodial
            wallet to the address authorizing the corresponding address below.
          </Text>
          <Text style={{ textAlign: 'center', fontWeight: 'bold' }}>
            {threshold} {threshold === 1 ? 'Signature' : 'Signatures'} Needed
          </Text>
          <Row gutter={[16, 16]}>
            <Col span={12} style={{ textAlign: 'center' }}>
              <EulithCard title="Pending Signatures">
                {localUnauthorizedOwners.length ? (
                  localUnauthorizedOwners.map((owner) => {
                    const walletConnected =
                      owner.address.toLowerCase() === uiWalletAddress?.toLowerCase?.();
                    return (
                      <Space.Compact
                        block
                        style={{ width: '100%', justifyContent: 'center', marginBottom: 15 }}
                        key={`pending_signatures_${owner.address}`}
                      >
                        <Button
                          type="default"
                          style={{
                            width: '100%',

                            pointerEvents: 'none',
                            backgroundColor: 'transparent'
                          }}
                        >
                          <MiddleEllipses>
                            <MonoSpaceSpan>{owner.address}</MonoSpaceSpan>
                          </MiddleEllipses>
                        </Button>
                        <Popover
                          content={
                            walletConnected
                              ? 'Click to authorize this owner.'
                              : 'Please connect to this wallet address to authorize.'
                          }
                        >
                          <Button
                            type="primary"
                            loading={armorLoading}
                            icon={<EditOutlined />}
                            disabled={!walletConnected}
                            onClick={handleAuthorizeArmorForCustodialWallet}
                          />
                        </Popover>
                      </Space.Compact>
                    );
                  })
                ) : (
                  <StyledItalic>
                    {localOwners.length ? 'All Owners have Signed' : 'No Owners Found'}
                  </StyledItalic>
                )}
              </EulithCard>
            </Col>
            <Col span={12} style={{ textAlign: 'center' }}>
              <EulithCard title={`${localAuthorizedOwners.length} of ${localOwners.length} Signed`}>
                {localAuthorizedOwners?.length ? (
                  localAuthorizedOwners.map((owner) => {
                    return (
                      <Space.Compact
                        block
                        style={{ width: '100%', justifyContent: 'center', marginBottom: 15 }}
                        key={`authorized_signatures_${owner.address}`}
                      >
                        <Button
                          type="default"
                          style={{
                            width: '100%',
                            pointerEvents: 'none',
                            backgroundColor: 'transparent'
                          }}
                        >
                          <Row>
                            <CheckCircleFilled style={{ color: colors.success, marginRight: 7 }} />
                            <MiddleEllipses>
                              <MonoSpaceSpan>{owner.address}</MonoSpaceSpan>
                            </MiddleEllipses>
                          </Row>
                        </Button>
                      </Space.Compact>
                    );
                  })
                ) : (
                  <StyledItalic>No Owners have Signed</StyledItalic>
                )}
              </EulithCard>
            </Col>
          </Row>
          {connectionStatus !== 'connected' ? (
            <Alert type="warning" banner message="Please connect a wallet before authorizing." />
          ) : localAuthorizedOwners.length >= threshold ? (
            <Button
              type="primary"
              block
              style={{ width: '100%', height: 100, marginBottom: 20, fontWeight: 'bold' }}
              size="large"
              onClick={handleEnableArmor}
              loading={armorLoading}
            >
              Enable Armor
            </Button>
          ) : (
            <Popover
              content={`You must authorize at least ${threshold} ${
                threshold === 1 ? 'signature' : 'signatures'
              } to enable your Armor instance`}
            >
              <Button
                type="primary"
                block
                style={{ width: '100%', height: 100, marginBottom: 20, fontWeight: 'bold' }}
                size="large"
                disabled
                loading={armorLoading}
              >
                Enable Armor
              </Button>
            </Popover>
          )}
        </SpaceContainer>
      </SectionContainer>
    );
  }

  function renderAddOwners() {
    return (
      <SectionContainer>
        <SpaceContainer size="large" direction="vertical">
          <CircularIcon
            icon={
              <SafetyCertificateOutlined
                style={{ fontSize: 50, color: 'rgba(255,255,255,0.25)' }}
              />
            }
          />
          <div>
            <H1 style={{ textAlign: 'center' }}>Add Account Owner(s)</H1>
            <StyledItalic style={{ textAlign: 'center' }}>Takes 3 Minutes</StyledItalic>
          </div>
          <Text style={{ textAlign: 'center' }}>
            To enable the Safe you just deployed, you need to specify a list of owners and a
            threshold of signatures required to perform actions on the safe. You can add any address
            by clicking &apos;Add Owner&apos; below.
          </Text>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <H3 style={{ textAlign: 'center' }}>Owner(s)</H3>
              <StyledItalic style={{ marginTop: 0, textAlign: 'center' }}>
                We recommend at least 3 Owners for production
              </StyledItalic>
            </Col>
            <Col span={12} style={{ textAlign: 'center' }}>
              <H3 style={{ textAlign: 'center' }}>Signature Threshold</H3>
              <StyledItalic style={{ marginTop: 0, textAlign: 'center' }}>
                Number of owners required to approve account activity
              </StyledItalic>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <AddOwnersForm
                data={localOwners}
                addOwner={addOwner}
                editOwner={editOwner}
                deleteOwner={deleteOwner}
                tradingKeyAddress={armorInstance?.tradingKeyAddress?.toLowerCase?.() || ''}
              />
            </Col>
            <Col span={12}>
              <StyledInputContainer>
                <StyledInput
                  type="number"
                  value={threshold}
                  onChange={handleThresholdChange}
                  max={localOwners.length}
                  min={1}
                />
                <Text style={{ textAlign: 'center' }}>
                  {threshold === 1 ? 'Signature required from' : 'Signatures required from'}
                </Text>
              </StyledInputContainer>
              <StyledInputContainer>
                <StyledInput
                  type="number"
                  value={localOwners.length}
                  style={{
                    pointerEvents: 'none',
                    background: 'transparent',
                    border: 'none',
                    height: 25
                  }}
                  readOnly
                />
                <Text style={{ textAlign: 'center' }}>
                  {localOwners.length === 1 ? 'Owner' : 'Owners'}
                </Text>
              </StyledInputContainer>
            </Col>
          </Row>
          {connectionStatus !== 'connected' ? (
            <Alert type="warning" banner message="Please connect a wallet before authorizing." />
          ) : localOwners.length ? (
            <Button
              type="primary"
              block
              style={{ width: '100%', height: 100, marginBottom: 20, fontWeight: 'bold' }}
              size="large"
              onClick={() => {
                navigateToAuthorizeOwners(armorInstance?.contractAddress || '');
              }}
              loading={armorLoading}
            >
              Next
            </Button>
          ) : (
            <Popover content="You must add at least one owner before enabling.">
              <Button
                type="primary"
                block
                style={{ width: '100%', height: 100, marginBottom: 20, fontWeight: 'bold' }}
                size="large"
                disabled={!localOwners.length}
                loading={armorLoading}
              >
                Next
              </Button>
            </Popover>
          )}
        </SpaceContainer>
      </SectionContainer>
    );
  }

  // function renderSignInstruction() {
  //   const localOwnersIncludesConnectedWallet = !!localOwners.find(
  //     (owner) => owner.address === uiWalletAddress?.toLowerCase?.() || ''
  //   );
  //   return (
  //     <SectionContainer>
  //       <SpaceContainer size="large" direction="vertical">
  //         <CircularIcon
  //           icon={
  //             <SafetyCertificateOutlined
  //               style={{ fontSize: 50, color: 'rgba(255,255,255,0.25)' }}
  //             />
  //           }
  //         />
  //         <H1 style={{ textAlign: 'center' }}>Sign Instruction</H1>
  //         <Text style={{ textAlign: 'center' }}>
  //           You need to sign an instruction to enable the Armor module on the Safe. This instruction
  //           must be signed by a threshold of Safe owners.
  //         </Text>
  //         {connectionStatus !== 'connected' ? (
  //           <Alert type="warning" banner message="Please connect a wallet before authorizing." />
  //         ) : (
  //           <>
  //             <DeployPreview
  //               title={localOwnersIncludesConnectedWallet ? 'Authorized ✅' : 'Authorizing'}
  //               subtitle={shortenString(uiWalletAddress?.toLowerCase?.() || '', 20)}
  //               image={logoUrl}
  //               icon={
  //                 <SafetyCertificateOutlined
  //                   style={{ fontSize: 24, color: '#141414', padding: '0 15px' }}
  //                 />
  //               }
  //             />
  //             {armorInstance?.tradingKeyAddress?.toLowerCase?.() ===
  //             uiWalletAddress?.toLowerCase?.() ? (
  //               <Popconfirm
  //                 title="Authorize this address as owner?"
  //                 description={
  //                   <div style={{ maxWidth: 300 }}>
  //                     Generally it is ill-advised to have your armor agent&apos;s trading key
  //                     address equal to that of the safe owner&apos;s address. You can think of the
  //                     safe owners as the withdrawal keys. In an ideal world, these would be separate
  //                     functions. Please consider changing your connect wallet to authorize a
  //                     different user.
  //                   </div>
  //                 }
  //                 onConfirm={handleAuthorizeArmorForCustodialWallet}
  //                 okText="Continue"
  //                 cancelText="Cancel"
  //                 okButtonProps={{
  //                   loading: armorLoading
  //                 }}
  //                 cancelButtonProps={{
  //                   loading: armorLoading
  //                 }}
  //               >
  //                 <Button
  //                   type="primary"
  //                   block
  //                   style={{ width: '100%', height: 100, marginBottom: 20, fontWeight: 'bold' }}
  //                   size="large"
  //                   disabled={localOwnersIncludesConnectedWallet}
  //                   loading={armorLoading}
  //                 >
  //                   Authorize
  //                 </Button>
  //               </Popconfirm>
  //             ) : localOwnersIncludesConnectedWallet ? (
  //               <Popover
  //                 title="Current wallet already authorized"
  //                 content="To authorize another address, please connect a different wallet address."
  //               >
  //                 <Button
  //                   type="primary"
  //                   block
  //                   style={{ width: '100%', height: 100, marginBottom: 20, fontWeight: 'bold' }}
  //                   size="large"
  //                   disabled={localOwnersIncludesConnectedWallet}
  //                   loading={armorLoading}
  //                 >
  //                   Authorize
  //                 </Button>
  //               </Popover>
  //             ) : (
  //               <Button
  //                 type="primary"
  //                 block
  //                 style={{ width: '100%', height: 100, marginBottom: 20, fontWeight: 'bold' }}
  //                 size="large"
  //                 disabled={localOwnersIncludesConnectedWallet}
  //                 onClick={handleAuthorizeArmorForCustodialWallet}
  //                 loading={armorLoading}
  //               >
  //                 Authorize
  //               </Button>
  //             )}
  //           </>
  //         )}
  //       </SpaceContainer>
  //     </SectionContainer>
  //   );
  // }

  function handleThresholdChange(e: any) {
    const value = parseInt(e.target.value);
    if (value !== 0) {
      setThreshold(value);
    }
  }

  async function handleDeployArmor({
    name,
    description,
    tradingKeyAddress,
    hasAce
  }: {
    name: string;
    description: string;
    tradingKeyAddress: string;
    hasAce: boolean;
  }) {
    const existingContract = contracts?.find((contract) => {
      return (
        contract.tradingKeyAddress.toLowerCase() === tradingKeyAddress.toLowerCase() &&
        contract.chainId === chainId
      );
    });
    if (connectionStatus !== 'connected') {
      message.warning('Please connect your wallet');
    } else if (existingContract) {
      Modal.confirm({
        title: 'Duplicate Armor instance',
        content:
          'You have already deployed an armor instance to this address. You can edit this instance, or choose a different address to deploy to.',
        maskClosable: true,
        cancelText: 'Change Address',
        onCancel: () => {
          deployForm.setFieldValue('tradingKeyAddress', '');
        },
        okText: 'View',
        onOk: () => {
          navigate(
            existingContract
              ? `/armor/accounts/${Eulith.Web3.utils.toChecksumAddress(
                  existingContract.contractAddress
                )}`
              : '/armor/accounts'
          );
        }
      });
    } else {
      if (tradingKeyAddress) {
        try {
          setDeployingModalVisible(true);
          console.log('Calling create armor');
          const result: any = await createArmor({
            name,
            description,
            tradingKeyAddress,
            existingSafeAddress,
            hasAce
          });
          getContracts()
            .unwrap()
            .then(() => console.log('Re-fetched contracts'))
            .catch((error: any) => {
              BugsnagManager.notify(error, {
                context: 'Unable to re-fetch contracts'
              });
              console.warn('Unable to re-fetch contracts', error);
            });
          setArmorInstance(result as DecoratedContract);
          setDeployingModalVisible(false);
          Modal.destroyAll();
          message.success('Successfully created armor!');
          if (existingSafeAddress) {
            navigateToAuthorizeOwners(result.contractAddress);
          } else {
            console.log('navigating here', existingSafeAddress);
            navigateToAddOwners(result.contractAddress);
          }
        } catch (error: any) {
          console.warn(error);
          BugsnagManager.notify(error, {
            context: 'Unable to create armor',
            metadata: {
              name,
              description,
              tradingKeyAddress,
              existingSafeAddress,
              existingContract
            }
          });
          setDeployingModalVisible(false);
          Modal.destroyAll();
          let errorMessage = '';
          if (error instanceof Error) errorMessage = error.message;
          else errorMessage = String(error?.message || error);
          setArmorLoading(false);
          message.error(errorMessage);
        }
      } else {
        message.warning('No address provided');
      }
    }
  }

  const handleAuthorizeArmor = useCallback(
    async (owner: ArmorOwner) => {
      return new Promise(async (resolve, reject) => {
        try {
          await authorizeArmorForOwner(
            armorInstance?.tradingKeyAddress || '',
            uiWalletAddress || ''
          );
          setLocalOwners((prevValue) => {
            const owners = prevValue.map((o) => {
              if (o.address.toLowerCase() === owner.address.toLowerCase()) {
                return {
                  address: o.address?.toLowerCase() || '',
                  label: o.label,
                  email: o.email,
                  authorized: true
                };
              } else {
                return o;
              }
            });
            form.setFieldValue('owners', [...owners]);
            return [...owners];
          });
          message.success('Successfully authorized owner!');
          resolve('Successfully authorized owner');
        } catch (error: any) {
          BugsnagManager.notify(error, {
            context: 'Unable to authorize armor owner',
            metadata: {
              owner,
              tradingKeyAddress: armorInstance?.tradingKeyAddress,
              uiWalletAddress
            }
          });
          console.warn(error);
          let errorMessage = '';
          if (error instanceof Error) errorMessage = error.message;
          else errorMessage = String(error?.message || 'Unable to authorize armor owner');
          setArmorLoading(false);
          message.error(errorMessage);
          reject(error);
        }
      });
    },
    [
      armorInstance?.tradingKeyAddress,
      authorizeArmorForOwner,
      form,
      setArmorLoading,
      uiWalletAddress
    ]
  );

  const handleAuthorizeArmorForCustodialWallet = useCallback(async () => {
    try {
      await handleAuthorizeArmor({
        label: DEFAULT_OWNER_LABEL,
        email: DEFAULT_OWNER_EMAIL,
        address: uiWalletAddress || '',
        authorized: true
      });
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to authorize armor owner for custodial wallet',
        metadata: {
          label: DEFAULT_OWNER_LABEL,
          email: DEFAULT_OWNER_EMAIL,
          address: uiWalletAddress || '',
          authorized: true,
          tradingKeyAddress: armorInstance?.tradingKeyAddress
        }
      });
      console.warn(error);
    }
    if (eulithSingleton.provider) {
      try {
        const agent = await Eulith.OnChainAgents.getArmorAgent({
          provider: eulithSingleton.provider,
          tradingKeyAddress: armorInstance?.tradingKeyAddress
        });
        if (agent.isEnabled) {
          navigateToSuccess();
        }
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to check if armor is now enabled',
          metadata: {
            label: DEFAULT_OWNER_LABEL,
            email: DEFAULT_OWNER_EMAIL,
            address: uiWalletAddress || '',
            authorized: true,
            tradingKeyAddress: armorInstance?.tradingKeyAddress
          }
        });
        console.warn(error);
      }
    }
  }, [handleAuthorizeArmor, uiWalletAddress, armorInstance?.tradingKeyAddress, navigateToSuccess]);

  async function handleEnableArmor() {
    if (connectionStatus !== 'connected') {
      message.warning('Please connect your wallet');
    } else {
      try {
        const localAuthorizedOwners = localOwners.filter((owner) => owner.authorized);
        await enableArmor(
          armorInstance?.tradingKeyAddress || '',
          threshold,
          localAuthorizedOwners.map((owner: ArmorOwner) => owner.address),
          existingSafeAddress
        );
        getContracts()
          .unwrap()
          .then(() => console.log('Re-fetched contracts'))
          .catch((error: any) => {
            BugsnagManager.notify(error, {
              context: 'Unable to re-fetch contracts after enabling'
            });
            console.warn('Unable to re-fetch contracts', error);
          });
        message.success('Successfully enabled armor!');
        setExistingSafeAddress(undefined);
        navigateToSuccess();
        setShowConfetti(true);
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to enable armor',
          metadata: {
            existingSafeAddress,
            address: uiWalletAddress || '',
            threshold,
            owners: localOwners,
            tradingKeyAddress: armorInstance?.tradingKeyAddress || ''
          }
        });
        console.warn(error);
        let errorMessage = '';
        if (error instanceof Error) errorMessage = error.message;
        else errorMessage = String(error);
        setArmorLoading(false);
        message.error(errorMessage);
      }
    }
  }

  const addOwner = useCallback(
    (owner: ArmorOwner) => {
      setLocalOwners((prevValue) => {
        const owners = [
          ...prevValue,
          {
            address: owner.address?.toLowerCase() || '',
            label: owner.label,
            email: owner.email,
            authorized: false
          }
        ];
        form.setFieldValue('owners', owners);
        return owners;
      });
    },
    [form]
  );

  const editOwner = useCallback((oldOwner: ArmorOwner, newOwner: ArmorOwner) => {
    setLocalOwners((prevValues) => {
      return [...prevValues].map((o) => {
        if (o.address?.toLowerCase() === oldOwner.address?.toLowerCase()) {
          return newOwner;
        } else {
          return o;
        }
      });
    });
  }, []);

  const deleteOwner = useCallback((owner: ArmorOwner) => {
    setLocalOwners((prevValues) => {
      return [...prevValues].filter((o) => o.address.toLowerCase() !== owner.address.toLowerCase());
    });
  }, []);

  function returnActiveStep() {
    if (existingSafeAddress) {
      switch (location.pathname) {
        case TABS.OVERVIEW:
          return -1;
        case TABS.CONFIGURE:
          return 0;
        case TABS.AUTHORIZE_OWNERS:
          return 1;
        case TABS.SUCCESS:
          return 2;
        default:
          return -1;
      }
    } else {
      switch (location.pathname) {
        case TABS.OVERVIEW:
          return -1;
        case TABS.CONFIGURE:
          return 0;
        case TABS.ADD_OWNERS:
          return 1;
        case TABS.AUTHORIZE_OWNERS:
          return 2;
        case TABS.SUCCESS:
          return 3;
        default:
          return -1;
      }
    }
  }

  const activeStep = returnActiveStep();
  return (
    <PageContainer>
      {renderConfetti()}
      <EulithCard fullHeight bodyStyle={{ height: '100%' }} style={{ height: '100%' }}>
        {loading ? (
          <div style={{ height: '100%', position: 'relative' }}>
            <Row
              gutter={[16, 16]}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Text>Loading...</Text>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </Row>
          </div>
        ) : (
          <>
            <Steps
              responsive={false}
              current={activeStep}
              items={steps}
              labelPlacement="vertical"
              size="small"
              style={{ padding: '0 20px' }}
              className={'armor-steps' + (activeStep > -1 ? ' armor-steps-visible' : '')}
            />
            <SwitchTransition>
              <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
                <Routes location={location}>
                  <Route path={removeBaseRoute(TABS.OVERVIEW)} element={renderOverview()} />
                  <Route path={removeBaseRoute(TABS.CONFIGURE)} element={renderConfigure()} />
                  {existingSafeAddress ? null : (
                    <Route path={removeBaseRoute(TABS.ADD_OWNERS)} element={renderAddOwners()} />
                  )}
                  <Route
                    path={removeBaseRoute(TABS.AUTHORIZE_OWNERS)}
                    element={renderAuthorizeOwners()}
                  />
                  <Route path={removeBaseRoute(TABS.SUCCESS)} element={renderSuccess()} />
                </Routes>
              </CSSTransition>
            </SwitchTransition>
          </>
        )}
      </EulithCard>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CircularIconContainer = styled.div`
  margin: auto;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  border: 2px solid rgba(255, 255, 255, 0.25);
`;

const SectionContainer = styled.div`
  display: flex;
  margin-top: 110px;
`;

const DeployPreviewContainer = styled.div`
  padding: 10px 25px;
  border-radius: 10px;
  border: 2px solid rgba(255,255,255,0.5)
  background-color: #fff;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const ChainLogoContainer = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  border: 2px solid #141414;
  overflow: hidden;
`;

const ConfettiContainer = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
`;

const SpaceContainer = styled(Space)`
  height: 100%;
  max-width: 600px;
  width: 100%;
  margin: auto;
`;

const StyledInputContainer = styled.div`
  text-align: center;
  font-size: 24px;
  margin-bottom: 25px;
`;

const StyledInput = styled(Input)`
  width: 80px;
  height: 60px;
  font-size: 24px;
  margin-bottom: 10px;
  padding-left: 22px;
  text-align: center;
`;

export default ArmorDeployLayout;
