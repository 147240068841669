export interface AddressTotalBalanceChainList {
  id: string;
  community_id: number;
  name: string;
  logo_url: string | null;
  native_token_id: string;
  wrapped_token_id: string;
  usd_value: number;
}

export interface AddressTotalBalance {
  total_usd_value: number;
  chain_list: AddressTotalBalanceChainList[];
}

export interface AddressBookEntry {
  address: string;
  name: string;
  description?: string;
  email?: string;
  createdAt: string;
  updatedAt: string;
  chainIds: number[];
  type: string;
  balances?: AddressTotalBalance;
  ens: string;
  role: string;
  editedBy: string;
  userId: string;
  id: string;
  profile?: string;
}

export interface UpdateAddressBookEntryRequest {
  id: string;
  payload: Partial<AddressBookEntry>;
}

export const ADDRESS_BOOK_DB_NAME = 'addresses';
