import {
  BaseQueryFn,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import {
  GroupAccount,
  UpdateGroupRequest,
  GroupBalanceHistory,
  GROUPS_DB_NAME,
  ACCOUNTS_DB_NAME,
  BALANCE_HISTORY_COLLECTION_NAME
} from './groupsTypes';
import BugsnagManager from '../../BugsnagManager';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
import { db } from '../firebase';

const EMPTY_ARRAY: any[] = [];

export const getBalanceHistoryQuery: BaseQueryFn<
  string[],
  GroupBalanceHistory[],
  FetchBaseQueryError
> = async (accountAddresses: string[] = []) => {
  try {
    const results = await Promise.all(
      accountAddresses.map(async (address) => {
        const balanceHistoryCollectionRef = collection(
          db,
          `${ACCOUNTS_DB_NAME}/${address}/${BALANCE_HISTORY_COLLECTION_NAME}`
        );
        const balanceHistorySnapshot = await getDocs(balanceHistoryCollectionRef);
        const balanceHistory = balanceHistorySnapshot.docs.map((doc) => doc.data());
        return balanceHistory as GroupBalanceHistory[];
      })
    );
    const balancesMap: { [key: number]: number } = {};
    results.forEach((accountResults) => {
      accountResults.forEach((balance) => {
        if (balancesMap[balance.timestamp]) {
          balancesMap[balance.timestamp] += balance.usd_value;
        } else {
          balancesMap[balance.timestamp] = balance.usd_value;
        }
      });
    });
    const balancesArray: GroupBalanceHistory[] = [];
    for (const key in balancesMap) {
      balancesArray.push({
        timestamp: Number(key),
        usd_value: balancesMap[key]
      });
    }
    return { data: balancesArray };
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to fetch groups in query'
    });
    console.warn('Unable to fetch groups', error);
    return { error: error as FetchBaseQueryError };
  }
};

export const getGroupsQuery: BaseQueryFn<string, GroupAccount[], FetchBaseQueryError> = async (
  userId
) => {
  try {
    const results: GroupAccount[] = [];
    const q = query(collection(db, GROUPS_DB_NAME), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data() as GroupAccount;
      results.push({
        ...data,
        id: doc.id
      });
    });
    return { data: results };
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to fetch groups in query'
    });
    console.warn('Unable to fetch groups', error);
    return { error: error as FetchBaseQueryError };
  }
};

export const addGroupQuery: BaseQueryFn<GroupAccount, GroupAccount, FetchBaseQueryError> = async (
  payload
) => {
  try {
    const docRef = await addDoc(collection(db, GROUPS_DB_NAME), payload);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const newGroup: GroupAccount = { id: docRef.id, ...docSnap.data() } as GroupAccount;
      return { data: newGroup };
    } else {
      throw new Error('Group does not exist after being created');
    }
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to add groups in query'
    });
    console.warn('Unable to add groups', error);
    return { error: error as FetchBaseQueryError };
  }
};

export const editGroupQuery: BaseQueryFn<
  UpdateGroupRequest,
  GroupAccount,
  FetchBaseQueryError
> = async ({ id, payload }) => {
  try {
    if (id) {
      const docRef = doc(db, GROUPS_DB_NAME, id);
      const originalDocRef = await getDoc(docRef);
      const originalData = originalDocRef.data();
      if (originalData) {
        if (originalData?.history) {
          if (payload.risk) {
            originalData.history.unshift({
              label: 'Risk Adjusted',
              value: payload.risk,
              timestamp: Date.now()
            });
          } else if (payload.started) {
            originalData.history.unshift({
              label: 'Account Status Change',
              value: 'Started',
              timestamp: Date.now()
            });
          } else if (payload.started === false) {
            originalData.history.unshift({
              label: 'Account Status Change',
              value: 'Stopped',
              timestamp: Date.now()
            });
          } else if (payload.deposited) {
            originalData.history.unshift({
              label: 'Account Deposit',
              value: 'Initiated',
              timestamp: Date.now()
            });
          }
        } else {
          originalData.history = [];
        }
        await updateDoc(docRef, {
          ...originalData,
          ...payload,
          updatedAt: Date.now()
        });
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const newGroup: GroupAccount = { id: docRef.id, ...docSnap.data() } as GroupAccount;
          return { data: newGroup };
        } else {
          throw new Error('Group does not exist after being updated');
        }
      } else {
        throw new Error('Group does not exist before being updated');
      }
    } else {
      throw new Error('ID not supplied to edit group query');
    }
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to edit groups in query'
    });
    console.warn('Unable to edit groups', error);
    return { error: error as FetchBaseQueryError };
  }
};

export const groupsApi = createApi({
  reducerPath: 'groupsApi',
  tagTypes: ['Groups'],
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    getGroups: builder.query<GroupAccount[], string>({
      queryFn: getGroupsQuery,
      providesTags: ['Groups']
    }),
    addGroup: builder.mutation<GroupAccount, GroupAccount>({
      queryFn: addGroupQuery,
      invalidatesTags: ['Groups']
    }),
    editGroup: builder.mutation<GroupAccount, UpdateGroupRequest>({
      queryFn: editGroupQuery,
      invalidatesTags: ['Groups']
    }),
    getAccountBalances: builder.query<GroupBalanceHistory[], string[]>({
      queryFn: getBalanceHistoryQuery,
      providesTags: ['Groups']
    })
  })
});

export const {
  useGetGroupsQuery,
  useLazyGetGroupsQuery,
  useAddGroupMutation,
  useEditGroupMutation,
  useGetAccountBalancesQuery,
  useLazyGetAccountBalancesQuery
} = groupsApi;

export const selectGroupsData = createSelector(
  (state: RootState) => {
    const getGroupsResponse = groupsApi.endpoints.getGroups.select(state.auth.user?.sub || '')(
      state
    );
    return (getGroupsResponse.data || []) as GroupAccount[];
  },
  (result) => result
);

export const selectGroupAccountBalancesData = createSelector(
  (state: RootState) => {
    if (state.groups.selectedGroup) {
      const getGroupsResponse = groupsApi.endpoints.getAccountBalances.select(
        state.groups.selectedGroup.accounts
      )(state);
      return (getGroupsResponse.data || []) as GroupBalanceHistory[];
    } else {
      return EMPTY_ARRAY as GroupBalanceHistory[];
    }
  },
  (result) => result
);
