import styled from 'styled-components';
import {
  AccountTableContract,
  DecoratedContract,
  GetBalanceForContractResponse
} from '../../features/eulith/eulithTypes';
import {
  Button,
  Col,
  ConfigProvider,
  Image,
  Popover,
  Row,
  Statistic,
  message,
  Dropdown,
  Popconfirm,
  Space,
  Select,
  Tag,
  Collapse
} from 'antd';
import { H2, colors } from '../../styles/shared';
import {
  DownOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  ExportOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SafetyCertificateOutlined
} from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Eulith from 'eulith-web3js';
import eulithSingleton from '../../features/eulith/EulithSingleton';
import ArmorOwnerListItem from '../Layouts/ArmorDeployLayout/components/ArmorOwnerListItem';
import { ArmorOwner } from '../../features/order/orderTypes';
import { chainIdToCurrencySymbol, chainIdToNetworkShortName } from '../../utils/networks';
import BugsnagManager from '../../BugsnagManager';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { selectAccessToken } from '../../features/auth/authSlice';
import EulithTable from '../EulithTable';
import { ColumnsType } from 'antd/es/table';
import {
  getTokenListForContract,
  useLazyGetContractsQuery
} from '../../features/eulith/eulithService';
import { dollarFormatter } from '../../utils/data';
import EmptyState from '../EmptyState/EmptyState';
import AddressBookLineItem from '../AddressBookLineItem';
import { setSelectedWhitelistContract } from '../../features/order/orderSlice';
import {
  useGetWhitelistsQuery,
  useRemoveWhitelistFromSafeMutation
} from '../../features/whitelist/whitelistService';
import { useNavigate } from 'react-router-dom';

const ALLOW_NAVIGATION = false;

function AddressElement({
  label,
  address,
  chainId,
  allowCopy = false
}: {
  label: string;
  address: string;
  chainId: number;
  allowCopy?: boolean;
}) {
  function openAddress(address: string) {
    window.open(address, '_blank');
  }

  return (
    <>
      <Statistic
        title={
          <StyledLabel>
            {label}
            {allowCopy ? (
              <ExportOutlined
                onClick={() => {
                  openAddress(
                    `https://app.safe.global/home?safe=${chainIdToNetworkShortName[chainId]}:${address}`
                  );
                }}
                style={{ marginLeft: 10, fontSize: 14, cursor: 'pointer', color: colors.grey3 }}
              />
            ) : null}
          </StyledLabel>
        }
        valueStyle={{ display: 'none' }}
      />
      <StyledAddressContainer>
        <AddressBookLineItem address={address} allowCopy />
      </StyledAddressContainer>
    </>
  );
}

function TradingAccountRow({
  data,
  expanded,
  handleEditContract
}: {
  data: AccountTableContract;
  expanded: boolean;
  handleEditContract: (contractAddress: string) => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: whitelists } = useGetWhitelistsQuery();
  const [removeWhitelistFromSafe] = useRemoveWhitelistFromSafeMutation();
  const [getContracts] = useLazyGetContractsQuery();
  const [owners, setOwners] = useState<ArmorOwner[]>([]);
  const [tokens, setTokens] = useState<GetBalanceForContractResponse[]>([]);
  const [loadingTokens, setLoadingTokens] = useState(true);
  const [threshold, setThreshold] = useState(1);
  const [loading, setLoading] = useState(true);
  const accessToken = useAppSelector(selectAccessToken);

  const navigateToWhitelists = useCallback(
    (account: DecoratedContract) => {
      navigate(`/armor/accounts/${Eulith.Web3.utils.toChecksumAddress(account.contractAddress)}`);
    },
    [navigate]
  );

  const handleRemoveWhitelistFromSafe = useCallback(
    async (record: DecoratedContract) => {
      if (!eulithSingleton.provider) {
        message.warning(
          'Unable to remove whitelist from account because Eulith services have not been initialized.'
        );
      } else if (!record) {
        message.warning('You must select a whitelist before removing it from this account.');
      } else {
        try {
          await removeWhitelistFromSafe({
            authAddress: record.tradingKeyAddress,
            safeAddress: record.safeAddress,
            chainId: record.chainId
          }).unwrap();
          await getContracts().unwrap();
          message.success('Whitelist successfully removed from account!');
        } catch (error: any) {
          message.error(error?.message || 'An error occurred when removing this whitelist.');
        }
      }
    },
    [removeWhitelistFromSafe, getContracts]
  );

  const navigateToCreateWhitelistDraft = useCallback(() => {
    navigate('/armor/whitelists/create');
  }, [navigate]);

  const onSelectWhitelistForAssignment = useCallback(
    (whitelistId: number, contract: DecoratedContract) => {
      dispatch(setSelectedWhitelistContract(contract));
      navigate(`/armor/whitelists/${whitelistId}/edit`);
    },
    [navigate, dispatch]
  );

  const renderWhitelistOptions = useMemo(() => {
    if (whitelists) {
      return whitelists.map((whitelist) => {
        return {
          label: whitelist.displayName,
          value: whitelist.listId
        };
      });
    } else {
      return [];
    }
  }, [whitelists]);

  const fetchOwners = useCallback(async () => {
    if (accessToken && data.safeAddress) {
      try {
        setLoading(true);
        const provider = eulithSingleton.createProviderFromChainId(data.chainId, accessToken);
        const agent = await Eulith.OnChainAgents.getArmorAgent({
          provider,
          tradingKeyAddress: data.tradingKeyAddress,
          armorContractAddress: data.contractAddress
        });
        const safe = new Eulith.Contracts.Safe(provider, data.safeAddress);
        const ownerAddresses = (await safe.getOwners()) || [];
        const thresholdValue = await safe.getThreshold();
        const authorizedOwnerSignatures =
          (await agent.getAuthorizingOwnerSignatures(provider, data.tradingKeyAddress)) || [];
        const normalizedOwnerAddresses = ownerAddresses.map((owner) => {
          return Eulith.Web3.utils.toChecksumAddress(owner);
        });
        const normalizedAuthorizedOwnerSignatures: string[] = authorizedOwnerSignatures.map(
          (owner) => {
            // TODO: this type is not correct - is typed as owner_address
            //@ts-ignore
            return Eulith.Web3.utils.toChecksumAddress(owner.ownerAddress);
          }
        );
        const authorizedOwnersMap: any = {};
        const unauthorizedOwnersMap: any = {};

        normalizedAuthorizedOwnerSignatures.forEach((authorizedOwner) => {
          authorizedOwnersMap[authorizedOwner] = {
            address: authorizedOwner,
            authorized: true
          };
        });

        normalizedOwnerAddresses.forEach((unauthorizedOwner) => {
          unauthorizedOwnersMap[unauthorizedOwner] = {
            address: unauthorizedOwner,
            authorized: false
          };
        });

        for (const address in authorizedOwnersMap) {
          const ownerIsOnChain = !!unauthorizedOwnersMap[address];
          if (ownerIsOnChain) {
            unauthorizedOwnersMap[address].authorized = true;
          }
        }
        setOwners(Object.values(unauthorizedOwnersMap) as ArmorOwner[]);
        setThreshold(thresholdValue);
        setLoading(false);
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to fetch account owners'
        });
        console.warn(error);
        message.error('Unable to fetch account owners');
        setLoading(false);
      }
    }
  }, [accessToken, data.chainId, data.contractAddress, data.safeAddress, data.tradingKeyAddress]);

  const fetchTokens = useCallback(async () => {
    setLoadingTokens(true);
    try {
      getTokenListForContract(data.safeAddress, data.chainId)
        .then((response) => {
          setTokens(response as GetBalanceForContractResponse[]);
        })
        .catch((error) => {
          message.error('Unable to fetch token list for this contract.');
          console.warn(error);
        })
        .finally(() => {
          setLoadingTokens(false);
        });
    } catch (error: any) {
      setLoadingTokens(false);
      BugsnagManager.notify(error, {
        context: 'Error in fetch tokens for account',
        metadata: {
          safeAddress: data.safeAddress,
          chainId: data.chainId
        }
      });
      console.warn('Error in fetch tokens for account', error);
    }
  }, [data.safeAddress, data.chainId]);

  useEffect(() => {
    if (expanded) {
      fetchOwners();
      fetchTokens();
    }
  }, [expanded, fetchOwners, fetchTokens]);

  function renderOwners() {
    return (
      <div style={{ maxWidth: 450 }}>
        {owners.map((owner) => {
          return (
            <ArmorOwnerListItem
              key={`armor_detail_owner_list_${data.contractAddress}_${owner.address}`}
              data={owner}
            />
          );
        })}
      </div>
    );
  }

  const accountColumns: ColumnsType = [
    {
      title: 'Trading Key Address',
      dataIndex: 'tradingKeyAddress',
      render: (text: string, account: any) => {
        return (
          <StyledAccountNameContainer>
            <AddressBookLineItem width="250px" address={text} />
            {account?.isEnabled ? null : (
              <Popover
                content={
                  <div>
                    <div>This account is not yet enabled.</div>
                    <div>Click to finish deployment.</div>
                  </div>
                }
              >
                <ExclamationCircleFilled
                  style={{ fontSize: 14, marginLeft: 5, color: '#F39292' }}
                />
              </Popover>
            )}
          </StyledAccountNameContainer>
        );
      }
    },
    {
      title: 'Enabled',
      dataIndex: 'isEnabled',
      width: 100,
      render: (isEnabled: boolean) => {
        if (isEnabled) {
          return <StyledTag color="#89E592">Yes</StyledTag>;
        } else {
          return <StyledTag color="#F39292">No</StyledTag>;
        }
      }
    },
    {
      title: 'Whitelist',
      dataIndex: 'whitelistStatus',
      render: (status: string, account: any) => {
        let tag = null;
        const whitelist = whitelists?.find((x) => x.listId === account.whitelistId);
        switch (status) {
          case 'Active':
            tag = (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'view_whitelist',
                      onClick: (e) => {
                        e.domEvent.stopPropagation();
                        navigate(`/armor/whitelists/${whitelist?.listId}/edit`);
                      },
                      label: 'View'
                    },
                    {
                      key: 'remove_whitelist',
                      danger: true,
                      onClick: (e) => {
                        e.domEvent.stopPropagation();
                      },
                      label: (
                        <Popconfirm
                          title="Remove from account?"
                          //TODO: eventually we will have the ability to reassociate a whitelist with an account
                          description="You will need to create a new whitelist for this account."
                          okText="Remove"
                          okButtonProps={{
                            danger: true,
                            ghost: true
                          }}
                          onCancel={(e) => {
                            e?.stopPropagation?.();
                          }}
                          onConfirm={(e) => {
                            e?.preventDefault?.();
                            e?.stopPropagation?.();
                            handleRemoveWhitelistFromSafe(account as DecoratedContract);
                          }}
                        >
                          <a onClick={(e: any) => e?.stopPropagation?.()}>Remove</a>
                        </Popconfirm>
                      )
                    }
                  ]
                }}
              >
                <div onClick={(e) => e.preventDefault()} className="cursor-pointer">
                  <Space>
                    {whitelist?.displayName || 'Untitled Whitelist'}
                    <DownOutlined style={{ color: '#fff' }} />
                  </Space>
                </div>
              </Dropdown>
            );
            break;
          case 'In-Progress':
            tag = <StyledTag color="#D8E589">In Progress</StyledTag>;
            break;
          case 'Inactive':
            tag = whitelists?.length ? (
              <Select
                style={{ width: 150 }}
                popupMatchSelectWidth={false}
                placeholder="Attach Whitelist"
                options={renderWhitelistOptions}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={navigateToCreateWhitelistDraft}
                    >
                      Create New
                    </Button>
                  </>
                )}
                onChange={(whitelistId: number) =>
                  onSelectWhitelistForAssignment(whitelistId, account)
                }
              />
            ) : (
              'Unassigned'
            );
            break;
          default:
            tag = '-';
        }
        return (
          <Space
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: 150
            }}
          >
            {tag}
            {account?.is_enabled ? (
              <DetailButton
                shape="round"
                size="small"
                style={{ backgroundColor: '#3b3f48' }}
                onClick={() => {
                  if (ALLOW_NAVIGATION) {
                    navigateToWhitelists(account);
                  }
                }}
              >
                See Detail
              </DetailButton>
            ) : null}
          </Space>
        );
      }
    },
    {
      title: 'ACE',
      dataIndex: 'aceStatus',
      width: 100,
      render: (status: boolean | null, account: any) => {
        if (!account.hasAce) {
          return '-';
        }
        if (status) {
          return <StyledTag color="#89E592">Connected</StyledTag>;
        } else {
          return <StyledTag color="#F39292">Disconnected</StyledTag>;
        }
      }
    },
    {
      title: 'Gas Balance',
      dataIndex: 'tradingKeyAddressBalance',
      render: (text: string, record: any) => {
        return (
          <Statistic
            title={
              <StyledLabel>
                <div
                  style={{
                    pointerEvents: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <div>Gas Balance</div>
                  <div>
                    <Popover
                      placement="topRight"
                      content="This is the balance of the trading key for this account, which pays for gas."
                    >
                      <InfoCircleOutlined
                        style={{ cursor: 'help', color: colors.grey1, marginLeft: 10 }}
                      />
                    </Popover>
                  </div>
                </div>
              </StyledLabel>
            }
            value={
              text
                ? `${parseFloat(text).toFixed(5)} ${chainIdToCurrencySymbol[record.chainId]}`
                : '-'
            }
            valueStyle={{ fontSize: 14 }}
            style={{ marginBottom: 20 }}
          />
        );
      }
    },
    {
      title: 'Armor Address',
      dataIndex: 'contractAddress',
      render: (text: string) => {
        if (!text) {
          return '-';
        }
        return (
          <StyledAccountNameContainer>
            <AddressBookLineItem width="150px" address={text} />
          </StyledAccountNameContainer>
        );
      }
    },
    {
      title: 'Wallet Address',
      dataIndex: 'wallet',
      render: (text: string) => {
        if (!text) {
          return '-';
        }
        return (
          <StyledAccountNameContainer>
            <AddressBookLineItem width="150px" address={text} />
          </StyledAccountNameContainer>
        );
      }
    }
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Token',
      dataIndex: 'name',
      render: (name: string, record: GetBalanceForContractResponse) => {
        const logo = record.logo_url;
        const displayName = record.optimized_symbol || record.display_symbol || record.symbol;
        return (
          <Row align="middle" style={{ minWidth: 150 }}>
            {logo ? (
              <Image src={logo} width={30} height={30} preview={false} />
            ) : (
              <QuestionCircleOutlined style={{ fontSize: 30, color: 'rgba(255,255,255,0.3)' }} />
            )}
            <div style={{ marginLeft: 10 }}>{displayName}</div>
          </Row>
        );
      }
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (price: number) => {
        return dollarFormatter(price);
      }
    },
    {
      title: 'USD Value',
      dataIndex: 'amount',
      render: (amount: number, record: GetBalanceForContractResponse) => {
        return dollarFormatter(amount * record.price);
      }
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount: number) => {
        return amount.toFixed(5);
      }
    }
  ];

  return (
    <StyledContainer>
      <Row gutter={16}>
        <Col sm={24} md={10}>
          <div style={{ marginBottom: 20 }}>
            <H2 style={{ fontSize: 20 }}>
              <span>
                <SafetyCertificateOutlined style={{ marginRight: 10 }} />
              </span>
              DeFi Armor Safe
            </H2>
          </div>
          <Statistic
            title={
              <StyledLabel
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleEditContract(data.contractAddress);
                }}
              >
                Name <EditOutlined style={{ color: colors.grey3 }} />
              </StyledLabel>
            }
            value={data.name || 'Not Provided'}
            valueStyle={{ fontSize: 14 }}
            style={{ marginBottom: 20 }}
          />
          <Statistic
            title={
              <StyledLabel
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleEditContract(data.contractAddress);
                }}
              >
                Description <EditOutlined style={{ color: colors.grey3 }} />
              </StyledLabel>
            }
            value={data.description || 'Not Provided'}
            valueStyle={{ fontSize: 14 }}
            style={{ marginBottom: 20 }}
          />
          <AddressElement
            label="Safe Address"
            address={data.safeAddress}
            chainId={data.chainId}
            allowCopy
          />
          {loading ? null : (
            <>
              <Statistic
                title={<StyledLabel>Owners</StyledLabel>}
                value={`A threshold of ${threshold} out of ${owners.length} owner(s) is required to approve administrative functions.`}
                valueStyle={{ fontSize: 14, marginBottom: 10 }}
              />
              {renderOwners()}
            </>
          )}
        </Col>
        <Col sm={24} md={14} className="col-with-border">
          <ConfigProvider renderEmpty={() => <EmptyState description="No tokens found" />}>
            <EulithTable
              rowKey="id"
              rowClassName="antd-table-row-no-border-radius"
              loading={loadingTokens}
              dataSource={tokens}
              columns={columns}
            />
          </ConfigProvider>
        </Col>
      </Row>
      <Collapse
        style={{ marginTop: 20, marginBottom: 20 }}
        items={[
          {
            key: 'Technical Details',
            label: 'Technical Details',
            children: (
              <EulithTable
                rowKey={(record: any) => {
                  return `child-${record.tradingKeyAddress}`;
                }}
                scroll={{ x: 600 }}
                rowClassName="antd-table-row-no-border-radius"
                rowHoverable={false}
                dataSource={data?.subContracts || []}
                columns={accountColumns}
              />
            )
          }
        ]}
      />
    </StyledContainer>
  );
}

export default TradingAccountRow;

const StyledAccountNameContainer = styled.div`
  min-width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledContainer = styled.div`
  padding: 20px;
`;

const StyledLabel = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px !important;
`;

const StyledAddressContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledTag = styled(Tag)`
  color: ${colors.blue2} !important;
  font-weight: 600;
`;

const DetailButton = styled(Button)`
  margin-left: 4px;
`;
