import { Network } from 'alchemy-sdk';
import { defaultChains, allChains } from '@thirdweb-dev/chains';
import { CELO_CHAIN_ID } from '../features/wallet/constants';
import { BASE_CHAIN_ID } from '../features/wallet/constants';

interface ScannerInfo {
  url: string;
  name: string;
}

interface NetworkOptions {
  label: string;
  value: number;
}

const legacyChainIdToNetworkLabel: { [key: number]: string } = {
  1: 'Ethereum Mainnet',
  137: 'Polygon Mainnet',
  56: 'BSC Mainnet',
  42161: 'Arbitrum Mainnet',
  10: 'Optimism Mainnet',
  43114: 'Avalanche Mainnet',
  42220: 'Celo Mainnet',
  8453: 'Base Mainnet',
  5: 'Ethereum Goerli',
  97: 'BSC Test',
  80001: 'Polygon Mumbai',
  420: 'Optimism Goerli',
  43113: 'Avalanche Fuji',
  31337: 'Ethereum Dev',
  421613: 'Arbitrum Goerli'
};

export function chainIdToNetworkLabel(chainId?: number) {
  // const chain = getAllChains().find((chain) => chain.chainId === chainId);
  // if (chain) {
  //   return chain.name;
  // } else {
  //   console.warn('Could not find a chain for chain id', chainId);
  //   return '?';
  // }
  switch (chainId) {
    case 1:
      return 'Ethereum Mainnet';
    case 137:
      return 'Polygon Mainnet';
    case 56:
      return 'BSC Mainnet';
    case 42161:
      return 'Arbitrum Mainnet';
    case 10:
      return 'Optimism Mainnet';
    case 43114:
      return 'Avalanche Mainnet';
    case 42220:
      return 'Celo Mainnet';
    case 8453:
      return 'Base Mainnet';
    case 5:
      return 'Ethereum Goerli';
    case 97:
      return 'BSC Test';
    case 80001:
      return 'Polygon Mumbai';
    case 420:
      return 'Optimism Goerli';
    case 43113:
      return 'Avalanche Fuji';
    case 31337:
      return 'Ethereum Dev';
    case 421613:
      return 'Arbitrum Goerli';
    default:
      return '';
  }
}

const networkOptions: NetworkOptions[] = [];
for (const key in legacyChainIdToNetworkLabel) {
  networkOptions.push({
    label: legacyChainIdToNetworkLabel[key],
    value: parseInt(key)
  });
}

export { networkOptions };

export const chainIdToNetworkShortName: { [key: number]: string } = {
  1: 'eth',
  5: 'goe',
  10: 'opt',
  56: 'bsc',
  97: 'bsct',
  137: 'poly',
  420: 'optg',
  31337: 'ethd',
  42161: 'arb',
  42220: 'celo',
  8453: 'base',
  43113: 'avaf',
  43114: 'ava',
  80001: 'polym',
  421613: 'arbg'
};

export const chainIdToNetworkName: { [key: number]: string } = {
  1: 'eth_main',
  5: 'eth_goerli',
  10: 'opt_main',
  56: 'bsc_main',
  97: 'bsc_test',
  137: 'poly_main',
  420: 'opt_goerli',
  31337: 'eth_development',
  42161: 'arb_main',
  42220: 'celo_main',
  8453: 'base_main',
  43113: 'avax_fuji',
  43114: 'avax_main',
  80001: 'poly_mumbai',
  421613: 'arb_goerli'
};

export const chainIdToCurrencySymbol: { [chainId: number]: string } = {
  1: 'ETH',
  3: 'ETH',
  4: 'ETH',
  5: 'ETH',
  10: 'ETH',
  42: 'ETH',
  56: 'BNB',
  97: 'BNB',
  100: 'xDAI',
  137: 'MATIC',
  250: 'FTM',
  420: 'ETH',
  1284: 'GLMR',
  1285: 'MOVR',
  31337: 'ETH',
  42161: 'ETH',
  42220: 'CELO',
  8453: 'ETH',
  43113: 'AVAX',
  43114: 'AVAX',
  80001: 'MATIC'
};

export const chainIdToAlchemyNetwork: { [key: number]: Network } = {
  1: Network.ETH_MAINNET,
  3: Network.ETH_ROPSTEN,
  5: Network.ETH_GOERLI,
  42: Network.ETH_KOVAN,
  4: Network.ETH_RINKEBY,
  // Note: I'm unsure about the chain ID for ETH_SEPOLIA. Place its correct chain ID if you have it.
  // ?: Network.ETH_SEPOLIA,

  // OPTIMISM:
  // Replace with the correct chain IDs for Optimism mainnet and testnets.
  // ?: Network.OPT_MAINNET,
  // ?: Network.OPT_KOVAN,
  // ?: Network.OPT_GOERLI,

  // ARBITRUM:
  // Replace with the correct chain IDs for Arbitrum mainnet and testnets.
  42161: Network.ARB_MAINNET,
  // ?: Network.ARB_RINKEBY,
  421613: Network.ARB_GOERLI,

  137: Network.MATIC_MAINNET,
  80001: Network.MATIC_MUMBAI

  // Replace with the correct chain IDs for ASTAR_MAINNET and the Polygon ZK-EVM networks.
  // ?: Network.ASTAR_MAINNET,
  // ?: Network.POLYGONZKEVM_MAINNET,
  // ?: Network.POLYGONZKEVM_TESTNET,
};

export const chainIdToScannerUrl: { [key: number]: ScannerInfo } = {
  1: {
    url: 'https://etherscan.io',
    name: 'Etherscan'
  },
  3: {
    url: 'https://etherscan.io',
    name: 'Etherscan'
  },
  5: {
    url: 'https://etherscan.io',
    name: 'Etherscan'
  },
  42: {
    url: 'https://etherscan.io',
    name: 'Etherscan'
  },
  4: {
    url: 'https://etherscan.io',
    name: 'Etherscan'
  },

  // OPTIMISM:
  10: {
    url: 'https://optimistic.etherscan.io',
    name: 'Etherscan'
  },

  // ARBITRUM:
  // Replace with the correct chain IDs for Arbitrum mainnet and testnets.
  42161: {
    url: 'https://arbiscan.io',
    name: 'Arbiscan'
  },
  42220: {
    url: 'https://celoscan.io',
    name: 'Celoscan'
  },
  8453: {
    url: 'https://basescan.org',
    name: 'Basescan'
  },
  421613: {
    url: 'https://arbiscan.io',
    name: 'Arbiscan'
  },

  // POLYGON:
  137: {
    url: 'https://polygonscan.com',
    name: 'Polygonscan'
  },
  80001: {
    url: 'https://polygonscan.com',
    name: 'Polygonscan'
  }
};

export function getAllChains() {
  return allChains;
}

export function calculateChainIconUrl(url: string, chainId?: number) {
  if (chainId === CELO_CHAIN_ID) {
    return '/celo.png';
  }
  if (chainId === BASE_CHAIN_ID) {
    return 'https://www.base.org/_next/static/media/logo.f6fdedfc.svg';
  }
  if (!url) {
    return url;
  }
  const cid = url?.split?.('//')?.[1];
  const baseIpfsGateway = 'https://ipfs.io/ipfs/';
  return cid ? `${baseIpfsGateway}${cid}` : '';
}
