import { Button, ConfigProvider } from 'antd';
import EulithCard from '../components/EulithCard';
import EulithTable from '../components/EulithTable';
import { selectGroupsData, useLazyGetGroupsQuery } from '../features/groups/groupsService';
import { useAppSelector } from '../hooks/redux';
import EmptyState from '../components/EmptyState/EmptyState';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectUser } from '../features/auth/authSlice';
import CreatePortfolioForm from '../components/CreatePortfolioForm';
import { selectContractsData } from '../features/eulith/eulithService';
import { GroupAccount } from '../features/groups/groupsTypes';
import AddressBookLineItem from '../components/AddressBookLineItem';

function TradingGroupsPage() {
  const groups = useAppSelector(selectGroupsData);
  const user = useAppSelector(selectUser);
  const [getGroups, { isLoading }] = useLazyGetGroupsQuery();
  const [modalOpen, setModalOpen] = useState(false);
  const [editingPortfolio, setEditingPortfolio] = useState<GroupAccount | null>(null);
  const contracts = useAppSelector(selectContractsData);

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Accounts',
        dataIndex: 'accounts',
        render: (list: string[], record: any) => {
          return list.map((l) => {
            const contract = contracts.find((c) => {
              return c.safeAddress.toLowerCase() === l.toLowerCase();
            });
            if (contract?.safeAddress) {
              return (
                <AddressBookLineItem
                  key={`record-${record.name}-${l}`}
                  address={contract.safeAddress}
                />
              );
            } else {
              return '?';
            }
          });
        }
      },
      {
        title: 'Denomination',
        dataIndex: 'denomination'
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: (text: number) =>
          `${new Date(text).toDateString()}, ${new Date(text).toLocaleTimeString()}`
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (text: any, record: any) => {
          return (
            <Button
              type="text"
              onClick={() => {
                setModalOpen(true);
                setEditingPortfolio(record);
              }}
            >
              Edit
            </Button>
          );
        }
      }
    ],
    [contracts, setEditingPortfolio]
  );

  useEffect(() => {
    getGroups(user?.sub || '')
      .unwrap()
      .catch(console.warn);
  }, [getGroups, user?.sub]);

  const openModal = useCallback(() => {
    setEditingPortfolio(null);
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setEditingPortfolio(null);
    setModalOpen(false);
  }, []);

  return (
    <EulithCard
      title="Portfolios"
      extra={
        <Button onClick={openModal} type="primary">
          Create Portfolio
        </Button>
      }
    >
      <ConfigProvider renderEmpty={() => <EmptyState description="No portfolios found" />}>
        <EulithTable
          rowKey="id"
          rowClassName="antd-table-row-no-border-radius"
          dataSource={groups}
          loading={isLoading}
          columns={columns}
        />
      </ConfigProvider>
      <CreatePortfolioForm open={modalOpen} close={closeModal} initialValues={editingPortfolio} />
    </EulithCard>
  );
}

export default TradingGroupsPage;
