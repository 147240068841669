import {
  Tooltip,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
  Line
} from 'recharts';
import { useAppSelector } from '../../hooks/redux';
import { selectSelectedGroup } from '../../features/groups/groupsSlice';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { useLazyGetAccountBalancesQuery } from '../../features/groups/groupsService';
import { Segmented, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { GroupBalanceHistory } from '../../features/groups/groupsTypes';

const segmentedOptions = [
  {
    label: '1D',
    value: 1
  },
  {
    label: '7D',
    value: 7
  },
  {
    label: '1M',
    value: 31
  },
  {
    label: '1Y',
    value: 365
  },
  {
    label: 'ALL',
    value: -1
  }
];

function getBalancesForTimeframe(balances: GroupBalanceHistory[], days: number) {
  const now = new Date();
  now.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
  const currentTime = Math.floor(now.getTime() / 1000);
  if (days === -1) {
    return balances;
  }
  const timeframeStart = currentTime - days * 24 * 60 * 60;
  const filteredBalances = balances.filter((balance) => balance.timestamp >= timeframeStart);

  // Fill out the rest of the timeframe with values of 0
  const filledBalances: GroupBalanceHistory[] = [];
  const dayInSeconds = 24 * 60 * 60;

  // Create a Set of existing timestamps for quick lookup
  const existingTimestamps = new Set(filteredBalances.map((balance) => balance.timestamp));

  for (let time = timeframeStart; time <= currentTime; time += dayInSeconds) {
    if (existingTimestamps.has(time)) {
      const balance = filteredBalances.find((balance) => balance.timestamp === time);
      if (balance) {
        filledBalances.push(balance);
      }
    } else {
      filledBalances.push({ timestamp: time, usd_value: 0 });
    }
  }

  return filledBalances;
}

const priceFormatter = (value: number): string => {
  if (value >= 1000) {
    return (value / 1000).toFixed(1) + 'k';
  }
  return value.toString();
};

function calculateTicks(data: GroupBalanceHistory[], tickCount: number) {
  const step = Math.floor(data.length / (tickCount - 1));
  return data.filter((_, index) => index % step === 0).map((item) => item.timestamp);
}

function EulithChart({
  height = 400,
  data: dataOverride
}: {
  height?: number;
  data?: GroupBalanceHistory[];
}) {
  const [data, setData] = useState<GroupBalanceHistory[]>([]);
  const selectedGroup = useAppSelector(selectSelectedGroup);
  const [getAccountBalances] = useLazyGetAccountBalancesQuery();
  const [timeRange, setTimeRange] = useState(1);
  useEffect(() => {
    if (selectedGroup?.accounts?.length && !dataOverride) {
      setData([]);
      getAccountBalances(selectedGroup.accounts)
        .unwrap()
        .then(setData)
        .catch((error) => {
          console.warn(error);
          message.error('Could not load balance history for this account. Please try again later.');
        });
    }
  }, [selectedGroup?.accounts, getAccountBalances, dataOverride]);
  const chartData = useMemo(() => dataOverride || data, [data, dataOverride]);
  const dataSubsetForTimeRange = useMemo(
    () => getBalancesForTimeframe(chartData, timeRange),
    [chartData, timeRange]
  );
  const ticks = useMemo(() => calculateTicks(dataSubsetForTimeRange, 7), [dataSubsetForTimeRange]);
  const timeFormatter = useCallback(
    (timestamp: number) => {
      const date = new Date(timestamp * 1000);
      if (timeRange === 1) {
        const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
        return date.toLocaleTimeString('en-US', options);
      } else {
        const month = date.getMonth() + 1; // getMonth() returns 0-based month index
        const day = date.getDate();
        return `${month}/${day}`;
      }
    },
    [timeRange]
  );
  const minValue = useMemo(
    () => Math.min(...dataSubsetForTimeRange.map((balance) => balance.usd_value)),
    [dataSubsetForTimeRange]
  );
  const yAxisDomain = useMemo(() => [Math.floor(minValue / 10) * 10, 'auto'], [minValue]);
  if (!dataSubsetForTimeRange?.length) {
    return (
      <div
        style={{
          width: '100%',
          height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  } else {
    return (
      <div>
        <Segmented
          options={segmentedOptions}
          onChange={setTimeRange}
          style={{ marginBottom: 20 }}
        />
        <ResponsiveContainer width="100%" height={height}>
          <AreaChart data={dataSubsetForTimeRange}>
            <defs>
              <linearGradient id="colorPositive" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="green" stopOpacity={0.5} />
                <stop offset="100%" stopColor="green" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorNegative" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="red" stopOpacity={0.5} />
                <stop offset="100%" stopColor="red" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="timestamp" tickFormatter={timeFormatter} ticks={ticks} />
            <YAxis
              orientation="left"
              tickFormatter={priceFormatter}
              domain={[yAxisDomain[0], 'auto']}
            />
            <CartesianGrid
              vertical={false}
              horizontal={true}
              strokeDasharray="3 0"
              strokeOpacity={0.5}
            />
            <Tooltip content={<div />} />
            <Area
              type="monotone"
              dataKey="usd_value"
              stroke="url(#colorPositive)"
              strokeWidth={3}
              fill="url(#colorPositive)"
              fillOpacity={1}
              isAnimationActive={true}
              animationDuration={500}
              label={({ index }: { index: number }) => {
                const currentValue = dataSubsetForTimeRange[index].usd_value;
                const nextValue = dataSubsetForTimeRange[index + 1]
                  ? dataSubsetForTimeRange[index + 1].usd_value
                  : currentValue;
                return currentValue < nextValue ? 'url(#colorPositive)' : 'url(#colorNegative)';
              }}
            />
            <Line
              type="monotone"
              dataKey="y"
              stroke="#000"
              dot={false}
              isAnimationActive={true}
              animationDuration={500}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default EulithChart;
