import { initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { isStagingEnv } from '../api/common';
import axios from 'axios';

const USE_LOCAL_EMULATOR = false;

const firebaseConfig = isStagingEnv
  ? {
      apiKey: 'AIzaSyBukc-xsCreUqDAKSIZFppWrgaf8YGu59Y',
      authDomain: 'eulith-prototype-staging.firebaseapp.com',
      projectId: 'eulith-prototype-staging',
      storageBucket: 'eulith-prototype-staging.appspot.com',
      messagingSenderId: '85790843182',
      appId: '1:85790843182:web:62d9ed21a9b0ef87729abf',
      measurementId: 'G-C87YL1BEW3'
    }
  : {
      apiKey: 'AIzaSyBUvxVsD1NmeeKITT647yix_X7Y3eWxGlo',
      authDomain: 'eulith-prototyping.firebaseapp.com',
      projectId: 'eulith-prototyping',
      storageBucket: 'eulith-prototyping.appspot.com',
      messagingSenderId: '413768714833',
      appId: '1:413768714833:web:27bfc74bf8d2f682fbc670',
      measurementId: 'G-QZWE8CPNWL'
    };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

if (isStagingEnv && USE_LOCAL_EMULATOR) {
  console.log('Connecting to Firebase Emulators');
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
}

function triggerGetBalanceHistoryJob() {
  if (USE_LOCAL_EMULATOR) {
    axios
      .get('http://127.0.0.1:5001/eulith-prototype-staging/us-central1/runRecordSafeBalancesJob')
      .then(() => {
        console.log('GET balance history trigger invoked');
      })
      .catch((error) => {
        console.warn(error);
      });
  } else if (isStagingEnv) {
    axios
      .get(
        'https://us-central1-eulith-prototype-staging.cloudfunctions.net/runRecordSafeBalancesJob '
      )
      .then(() => {
        console.log('GET balance history trigger invoked');
      })
      .catch((error) => {
        console.warn(error);
      });
  } else {
    axios
      .get('https://us-central1-eulith-prototype.cloudfunctions.net/runRecordSafeBalancesJob')
      .then(() => {
        console.log('GET balance history trigger invoked');
      })
      .catch((error) => {
        console.warn(error);
      });
  }
}

export { db, triggerGetBalanceHistoryJob };
